// ./components/SideBar/SideBar.jsx

import React, { useState, useContext, useRef, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaQuestionCircle } from 'react-icons/fa';
import BarChartIcon from '@mui/icons-material/BarChart';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import LayersIcon from '@mui/icons-material/Layers';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import GrassIcon from '@mui/icons-material/Grass';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import HistoryIcon from '@mui/icons-material/History';
import { useAuth } from '../../context/AuthContext';
import { Box, List, ListItem, ListItemText } from '@mui/material';

import {
    SidebarWrapper,
    SidebarContainer,
    MenuItemsContainer,
    MenuItemContainer,
    MenuIconContainer,
    TooltipContainer,
    HelpButton,
    SubMenuContainer,
    FullscreenButton
} from './SideBarStyle';

import Tutorial from '../Tutorial/Tutorial';
import { SidebarContext } from '../../context/SidebarContext';
import HistorialContent from '../../components/HistorialModal/HistorialModal';
import { API_BASE_PYTHON_SERVICE } from '../../utils/config';
import axios from 'axios';

const Sidebar = ({ onSelectedFilesChange, selectedFilters }) => {
    const location = useLocation();
    const { logout } = useAuth();
    const { setSelectedSidebarOption } = useContext(SidebarContext);
    const [activeItem, setActiveItem] = useState(location.pathname);
    const [tooltip, setTooltip] = useState({ visible: false, content: '', position: { top: 0, left: 0 } });
    const [openSubMenus, setOpenSubMenus] = useState([]);
    const [isTutorialActive, setIsTutorialActive] = useState(false);
    const menuItemRefs = useRef({});
    const sidebarRef = useRef(null);
    const navigate = useNavigate();

    const [userData, setUserData] = useState(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [analyses, setAnalyses] = useState(null);
    const [loadingAnalyses, setLoadingAnalyses] = useState(true);

    const [selectedFiles, setSelectedFiles] = useState([]);

    // Nuevo estado para controlar la opción activa (toggle) dentro de SIG Agrícola
    const [activeSigOption, setActiveSigOption] = useState(null);

    useEffect(() => {
        const stored = localStorage.getItem('selectedFiles');
        if (stored) {
            setSelectedFiles(JSON.parse(stored));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('selectedFiles', JSON.stringify(selectedFiles));
        if (typeof onSelectedFilesChange === 'function') {
            onSelectedFilesChange(selectedFiles);
        }
    }, [selectedFiles, onSelectedFilesChange]);

    const toggleFullscreen = useCallback(() => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch((err) => {
                console.error(`Error al intentar activar pantalla completa: ${err.message} (${err.name})`);
            });
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    }, []);

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        const storedUserData = localStorage.getItem("userData");
        if (storedUserData) {
            try {
                const parsedData = JSON.parse(storedUserData);
                setUserData(parsedData);
            } catch (error) {
                console.error('Error al parsear userData desde localStorage:', error);
                setUserData(null);
            }
        }
    }, []);

    const fetchAnalyses = useCallback(async () => {
        if (userData && userData.userId) {
            try {
                const params = {};
                if (selectedFilters.zafra) params.zafra = selectedFilters.zafra;
                if (selectedFilters.tercio) params.tercio = selectedFilters.tercio;
                if (selectedFilters.finca) params.finca = selectedFilters.finca;
                if (selectedFilters.operador) params.operador = selectedFilters.operador;

                const response = await axios.get(`${API_BASE_PYTHON_SERVICE}mapping/analisis/${userData.userId}`, { params });
                const data = response.data;

                if (data.status === 'success' && data.data) {
                    const sections = {
                        'Aplicaciones aéreas': [],
                        'Cosecha mecánica': [],
                        'Conteo de palmas': [],
                    };
                    data.data.forEach(analisisItem => {
                        const { archivos } = analisisItem;
                        if (archivos.aplicaciones_aereas && archivos.aplicaciones_aereas.length > 0) {
                            sections['Aplicaciones aéreas'].push(...archivos.aplicaciones_aereas);
                        }
                        if (archivos.cosecha_mecanica && archivos.cosecha_mecanica.length > 0) {
                            sections['Cosecha mecánica'].push(...archivos.cosecha_mecanica);
                        }
                        if (archivos.conteo_palma && archivos.conteo_palma.length > 0) {
                            sections['Conteo de palmas'].push(...archivos.conteo_palma);
                        }
                    });
                    setAnalyses(sections);
                } else {
                    setAnalyses({
                        'Aplicaciones aéreas': [],
                        'Cosecha mecánica': [],
                        'Conteo de palmas': [],
                    });
                }
            } catch (error) {
                console.error('Error al obtener análisis:', error);
                setAnalyses({
                    'Aplicaciones aéreas': [],
                    'Cosecha mecánica': [],
                    'Conteo de palmas': [],
                });
            } finally {
                setLoadingAnalyses(false);
            }
        } else {
            setLoadingAnalyses(false);
        }
    }, [userData, selectedFilters]);

    useEffect(() => {
        fetchAnalyses();
    }, [fetchAnalyses]);

    // Manejo de clicks en cada menú
    const handleMenuItemClick = useCallback((item, event, level = 0) => {
        event.stopPropagation();
        if (item.name === 'Historial') {
            if (userData && userData.userId) {
                const parentRect = menuItemRefs.current[item.name]?.getBoundingClientRect();
                setOpenSubMenus((prev) => {
                    const newOpenSubMenus = prev.slice(0, level);
                    newOpenSubMenus[level] = { isHistorial: true, parentRect };
                    return newOpenSubMenus;
                });
            } else {
                console.error('Usuario no definido o sin id.');
            }
            return;
        }

        if (item.subcategories) {
            const parentRect = menuItemRefs.current[item.name]?.getBoundingClientRect();
            setOpenSubMenus((prev) => {
                const newOpenSubMenus = prev.slice(0, level);
                newOpenSubMenus[level] = { items: item.subcategories, parentRect };
                return newOpenSubMenus;
            });
        } else {
            if (item.toggleable) {
                if (activeSigOption === item.name) {
                    setActiveSigOption(null);
                    setSelectedSidebarOption(null);
                } else {
                    setActiveSigOption(item.name);
                    setSelectedSidebarOption(item.name);
                    if (item.path) {
                        setActiveItem(item.path);
                        navigate(item.path);
                    }
                }
            } else {
                setActiveSigOption(null);
                if (item.name === 'Cerrar sesión') {
                    logout();
                    navigate('/login');
                    return;
                }
                if (!item.path) {
                    // Sección en construcción
                } else {
                    setSelectedSidebarOption(item.name);
                    setActiveItem(item.path);
                    navigate(item.path);
                }
            }
            setOpenSubMenus([]);
        }
    }, [logout, navigate, setSelectedSidebarOption, userData, activeSigOption]);

    // Menú principal
    const menuItems = useMemo(() => [
        { name: 'Dashboard', icon: <BarChartIcon />, path: '/dashboard', level: 0 },
        { name: 'Mapeo de maquinaria', icon: <AgricultureIcon/>, path: '/mapeo?tipo=mapeo_maquinaria', level: 0 },
        { name: 'Conteo de plantas', icon: <GrassIcon/>, path: '/mapeo?tipo=conteo_plantas', level: 0 },
        {
            name: 'SIG Agrícola', icon: <LayersIcon />, subcategories: [
                {
                    name: 'Cosecha', subcategories: [
                        { name: 'Planificación de cosecha', path: null, level: 2, toggleable: true },
                        { name: 'Avance de cosecha', path: '/cosecha/avance', level: 2, toggleable: true },
                        { name: 'Tipo de cosecha (mecánica / manual)', path: '/cosecha/tipo', level: 2, toggleable: true },
                        { name: 'Traciegos', path: '/cosecha/traciegos', level: 2, toggleable: true },
                        { name: 'Quemas criminales', path: '/cosecha/quemas', level: 2, toggleable: true },
                        { name: 'Rutas cañeras', path: '/cosecha/rutas', level: 2, toggleable: true },
                    ],
                    level: 1
                },
                {
                    name: 'Madurantes', subcategories: [
                        { name: 'Planificacion de madurante', path: '/madurantes/planificacion', level: 2, toggleable: true },
                        { name: 'Avance de madurante', path: '/madurantes/avance', level: 2, toggleable: true },
                        { name: 'Dias programados para corte', path: null, level: 2, toggleable: true },
                        { name: 'Aplicacion con dron', path: '/madurantes/aplicacion-dron', level: 2, toggleable: true },
                    ],
                    level: 1
                },
                {
                    name: 'Variedades', subcategories: [
                        { name: 'Tipos de variedades', path: '/variedades/tipos', level: 2, toggleable: true },
                        { name: 'Variedades de más a menos productividad', path: null, level: 2, toggleable: true },
                        { name: 'Semilleros', path: '/variedades/semilleros', level: 2, toggleable: true },
                        { name: 'Ensayos de variedades', path: '/variedades/ensayos', level: 2, toggleable: true },
                    ],
                    level: 1
                },
                {
                    name: 'Plagas', subcategories: [
                        { name: 'Barrenador', path: '/plagas/barrenador', level: 2, toggleable: true },
                        { name: 'Chinche salivosa', path: '/plagas/chinche', level: 2, toggleable: true },
                        { name: 'Rata', path: null, level: 2, toggleable: true },
                        { name: 'Plagas del suelo', path: '/plagas/suelo', level: 2, toggleable: true },
                        { name: 'Programación de muestreo semanal', path: '/plagas/programacion', level: 2, toggleable: true },
                    ],
                    level: 1
                },
                {
                    name: 'Riego', subcategories: [
                        { name: 'Áreas con y sin riego', path: '/riego/areas', level: 2, toggleable: true },
                        { name: 'Tipos de riego', path: '/riego/tipos', level: 2, toggleable: true },
                        { name: 'Balance hídrico', path: '/riego/balance', level: 2, toggleable: true },
                        { name: 'Evapotranspiración', path: '/riego/evapotranspiracion', level: 2, toggleable: true },
                        { name: 'Calidad del riego', path: '/riego/calidad', level: 2, toggleable: true },
                        { name: 'Productividad con y sin riego', path: null, level: 2, toggleable: true },
                    ],
                    level: 1
                }
            ],
            level: 0
        },
        { name: 'Historial', icon: <HistoryIcon />, path: null, level: 0 },
        { name: 'Configuración', icon: <SettingsIcon />, path: '/configuracion', level: 0 },
        { name: 'Cerrar sesión', icon: <ExitToAppIcon />, path: '/logout', level: 0 }
    ], []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const openParam = params.get('open');

        // Si detecta ?open=SIGAgricola, abrimos SIG Agrícola
        if (openParam === 'SIGAgricola') {
            const item = menuItems.find(i => i.name === 'SIG Agrícola');
            if (item) {
                const fakeEvent = { stopPropagation: () => {} };
                handleMenuItemClick(item, fakeEvent, 0);
            }
        }
        // Si detecta ?open=historial, abrimos Historial
        if (openParam === 'historial') {
            const item = menuItems.find(i => i.name === 'Historial');
            if (item) {
                const fakeEvent = { stopPropagation: () => {} };
                handleMenuItemClick(item, fakeEvent, 0);
            }
        }
    }, [location.search, menuItems, handleMenuItemClick]);

    const securityItems = useMemo(() => [
        { name: 'Usuario', icon: <SettingsIcon />, path: '/security-module/agregar-usuario', level: 0 },
        { name: 'Empresa', icon: <SettingsIcon />, path: '/security-module/agregar-empresa', level: 0 },
        { name: 'Cerrar sesión', icon: <ExitToAppIcon />, path: '/security-login', level: 0 }
    ], []);

    const activeItems = useMemo(() => {
        return location.pathname.includes('/security-module') ? securityItems : menuItems;
    }, [location.pathname, securityItems, menuItems]);

    const showTooltip = useCallback((content, event) => {
        const position = {
            top: event.currentTarget.getBoundingClientRect().top + window.scrollY,
            left: event.currentTarget.getBoundingClientRect().right + 10
        };
        setTooltip({ visible: true, content, position });
    }, []);

    const hideTooltip = useCallback(() => {
        setTooltip({ visible: false, content: '', position: { top: 0, left: 0 } });
    }, []);

    const handleClickOutside = useCallback((event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setOpenSubMenus([]);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    const handleCheckboxChange = useCallback((file) => {
        setSelectedFiles(prevSelected => {
            const index = prevSelected.findIndex(selected => selected.file_name === file.file_name);
            if (index > -1) {
                const updated = [...prevSelected];
                updated.splice(index, 1);
                return updated;
            } else {
                return [...prevSelected, file];
            }
        });
    }, []);

    return (
        <>
            <SidebarWrapper ref={sidebarRef}>
                <SidebarContainer>
                    <MenuItemsContainer>
                        {activeItems.map((item, index) => (
                            <Box key={index} style={{ width: '100%' }}>
                                <MenuItemContainer
                                    ref={(el) => (menuItemRefs.current[item.name] = el)}
                                    active={activeItem === item.path}
                                    onClick={(e) => handleMenuItemClick(item, e, 0)}
                                    onMouseEnter={(e) => showTooltip(item.name, e)}
                                    onMouseLeave={hideTooltip}
                                >
                                    <MenuIconContainer>{item.icon}</MenuIconContainer>
                                </MenuItemContainer>
                            </Box>
                        ))}
                    </MenuItemsContainer>

                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '20px' }}>
                        <HelpButton onClick={() => setIsTutorialActive(true)}>
                            <FaQuestionCircle />
                        </HelpButton>
                        <FullscreenButton onClick={toggleFullscreen}>
                            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                        </FullscreenButton>
                    </Box>
                </SidebarContainer>

                {openSubMenus.map((submenu, level) => (
                    <SubMenuContainer
                        key={level}
                        style={{
                            top: submenu.parentRect.top,
                            left: submenu.parentRect.right,
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {submenu.isHistorial ? (
                            <HistorialContent
                                analyses={analyses || {
                                    'Aplicaciones aéreas': [],
                                    'Cosecha mecánica': [],
                                    'Conteo de palmas': [],
                                }}
                                loading={loadingAnalyses}
                                onClose={() => {
                                    setOpenSubMenus(prev => prev.slice(0, level));
                                }}
                                selectedFiles={selectedFiles}
                                onCheckboxChange={handleCheckboxChange}
                            />
                        ) : (
                            <List>
                                {submenu.items.map((item, index) => (
                                    <ListItem
                                        button
                                        key={index}
                                        selected={item.toggleable ? activeSigOption === item.name : false}
                                        ref={(el) => {
                                            if (el) {
                                                menuItemRefs.current[item.name] = el;
                                            }
                                        }}
                                        onClick={(e) => handleMenuItemClick(item, e, level + 1)}
                                        onMouseEnter={(e) => showTooltip(item.name, e)}
                                        onMouseLeave={hideTooltip}
                                    >
                                        <ListItemText primary={item.name} />
                                        {item.subcategories && (
                                            <ExpandMoreIcon />
                                        )}
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </SubMenuContainer>
                ))}
            </SidebarWrapper>

            {tooltip.visible && (
                <TooltipContainer top={tooltip.position.top} left={tooltip.position.left}>
                    {tooltip.content}
                </TooltipContainer>
            )}

            <Tutorial isActive={isTutorialActive} onClose={() => setIsTutorialActive(false)} />
        </>
    );
};

export default Sidebar;
