import React, { useState, useRef, useEffect, useCallback } from "react";
import CommonMap from "../../components/CommonMap/CommonMap";
import { useSocket } from "../../context/SocketContext";
import MapDialog from "../../components/MapDialog/MapDialog";
import axios from "axios";
import { API_BASE_PYTHON_SERVICE } from "../../utils/config";
import BarIndicator from "../../components/BarIndicator/BarIndicator";

const Fertilization = ({
                           tipoAnalisis,
                           isFilterDialogOpen,
                           closeFilterDialog,
                           harvestGroupings,
                           selectedHarvestGrouping,
                           setSelectedHarvestGrouping,
                           setTipoMapeo,
                           polygonsData,
                           onAreasCalculated,
                           idAnalisis,
                           setNorthWestCoords,
                           setSouthEastCoords,
                           setCoordinateSystem,
                           mapRef
                       }) => {
    const workerRef = useRef(null);
    const [zoom] = useState(3);
    const [mapCenter] = useState([0, 0]);

    // Polígonos base y con color aplicado (según filtros)
    const [polygons, setPolygons] = useState([]);
    const [coloredPolygons, setColoredPolygons] = useState([]);

    // Líneas, intersecciones, buffers, etc. (en este caso se usan vacíos o según se requiera)
    const [lines, setLines] = useState([]);
    const [nonIntersectedAreas, setNonIntersectedAreas] = useState([]);
    const [bufferedLines, setBufferedLines] = useState([]);
    const [bufferedIntersections, setBufferedIntersections] = useState([]);

    // Socket
    const socketContext = useSocket();
    const { socket, socketSessionID } = socketContext || {};

    // Filtros disponibles: se activa a true sólo si encuentra datos para ello
    const [availableFilters, setAvailableFilters] = useState({
        autoPilot: false,
        autoTracket: false,
        modeCutterBase: false,
        speed: false,
        gpsQuality: false,
        fuel: false,
        rpm: false,
        cutterBase: false,
        altitude: false,
        realDose: false,
        hdop: false,
        p1dr: false,
        p1dt: false,
    });

    // Estados para mostrar/ocultar cada filtro
    const [filterVelocidad, setFilterVelocidad] = useState(false);
    const [filterAltura, setFilterAltura] = useState(false);
    const [filterHDOP, setFilterHDOP] = useState(false);
    const [filterP1DR, setFilterP1DR] = useState(false);
    const [filterP1DT, setFilterP1DT] = useState(false);

    // Límites para filtros
    const [lowVelocidad, setLowVelocidad] = useState(0);
    const [medVelocidad, setMedVelocidad] = useState(0);
    const [highVelocidad, setHighVelocidad] = useState(0);

    const [lowAltura, setLowAltura] = useState(0);
    const [medAltura, setMedAltura] = useState(0);
    const [highAltura, setHighAltura] = useState(0);

    const [lowHDOP, setLowHDOP] = useState(0);
    const [medHDOP, setMedHDOP] = useState(0);
    const [highHDOP, setHighHDOP] = useState(0);

    const [lowP1DR, setLowP1DR] = useState(0);
    const [medP1DR, setMedP1DR] = useState(0);
    const [highP1DR, setHighP1DR] = useState(0);

    const [lowP1DT, setLowP1DT] = useState(0);
    const [medP1DT, setMedP1DT] = useState(0);
    const [highP1DT, setHighP1DT] = useState(0);

    const [activeFilter, setActiveFilter] = useState(null);

    const [labelsAndColors, setLabelsAndColors] = useState({});
    const handleLabelClick = useCallback(
        (text, color) => {
            setLabelsAndColors({ text, color });
        },
        [setLabelsAndColors]
    );

    const handleLineClick = () => {};
    const polygonsProperties = {};

    const userData = { userId: null };
    const showUnfilteredLines = false;
    const lineasNoFiltradas = () => {};
    const highlightedLote = null;
    const activeLotes = () => {};
    const setImgLaflet = () => {};
    const polygonsLayerRef = useRef(null);
    const popupInfo = null;
    const setPopupInfo = () => {};
    const isEditing = false;

    useEffect(() => {
        workerRef.current = new Worker("Workers/dataWorker.js");

        workerRef.current.onmessage = (e) => {
            if (e.data.action === "geoJsonDataProcessed") {

                const data = e.data.data || {};

                const polygons = data.polygons || [];
                const lines = data.lines || [];

                setPolygons(polygons);
                setLines(lines);
            }
        };


        if (socket && socketSessionID) {
            socket.on(`${socketSessionID}:updateGeoJSONLayer`, (geojsonData) => {
                if (workerRef.current) {
                    try {
                        let dataToSend;
                        if (tipoAnalisis === "FERTILIZACION") {
                            dataToSend = {
                                geojsonDataNoFiltradas: geojsonData.geojsonDataNoFiltradas,
                            };
                        } else {
                            dataToSend = geojsonData;
                        }
                        workerRef.current.postMessage({
                            action: "processGeoJsonData",
                            geojsonData: dataToSend,
                            type: tipoAnalisis,
                        });
                    } catch (error) {
                        console.error("Error al serializar geojsonData:", error);
                    }
                }
            });
        }

        return () => {
            if (workerRef.current) {
                workerRef.current.terminate();
            }
            if (socket && socketSessionID) {
                socket.off(`${socketSessionID}:updateGeoJSONLayer`);
            }
        };
    }, [socket, socketSessionID, tipoAnalisis]);

    useEffect(() => {
        if (!Array.isArray(polygons) || polygons.length === 0) return;

        let hasVelocidad = false;
        let hasAltura = false;
        let hasHDOP = false;
        let hasP1DR = false;
        let hasP1DT = false;

        for (let i = 0; i < polygons.length; i++) {
            const props = polygons[i]?.properties || {};
            if (typeof props.Velocidad_ === "number") hasVelocidad = true;
            if (typeof props.Altura_m_ === "number") hasAltura = true;
            if (typeof props.HDOP === "number") hasHDOP = true;
            if (typeof props.P1_DR_Kg_H === "number") hasP1DR = true;
            if (typeof props.P1_DT_Kg_H === "number") hasP1DT = true;

            if (hasVelocidad && hasAltura && hasHDOP && hasP1DR && hasP1DT) {
                break;
            }
        }

        setAvailableFilters((prev) => ({
            ...prev,
            speed: hasVelocidad,
            altitude: hasAltura,
            hdop: hasHDOP,
            p1dr: hasP1DR,
            p1dt: hasP1DT,
        }));
    }, [polygons]);

    useEffect(() => {
        if (!Array.isArray(polygons)) return;

        const updatedPolygons = polygons.map((poly) => {
            const newPoly = { ...poly, properties: { ...poly.properties } };
            const props = newPoly.properties;

            let color = "#198C19";

            if (filterVelocidad && typeof props.Velocidad_ === "number") {
                const val = props.Velocidad_;
                if (val < lowVelocidad) {
                    color = "green";
                } else if (val < medVelocidad) {
                    color = "yellow";
                } else if (val < highVelocidad) {
                    color = "orange";
                } else {
                    color = "red";
                }
            }

            if (filterAltura && typeof props.Altura_m_ === "number") {
                const val = props.Altura_m_;
                if (val < lowAltura) {
                    color = "green";
                } else if (val < medAltura) {
                    color = "yellow";
                } else if (val < highAltura) {
                    color = "orange";
                } else {
                    color = "red";
                }
            }

            if (filterHDOP && typeof props.HDOP === "number") {
                const val = props.HDOP;
                if (val < lowHDOP) {
                    color = "green";
                } else if (val < medHDOP) {
                    color = "yellow";
                } else if (val < highHDOP) {
                    color = "orange";
                } else {
                    color = "red";
                }
            }

            if (filterP1DR && typeof props.P1_DR_Kg_H === "number") {
                const val = props.P1_DR_Kg_H;
                if (val < lowP1DR) {
                    color = "green";
                } else if (val < medP1DR) {
                    color = "yellow";
                } else if (val < highP1DR) {
                    color = "orange";
                } else {
                    color = "red";
                }
            }

            if (filterP1DT && typeof props.P1_DT_Kg_H === "number") {
                const val = props.P1_DT_Kg_H;
                if (val < lowP1DT) {
                    color = "green";
                } else if (val < medP1DT) {
                    color = "yellow";
                } else if (val < highP1DT) {
                    color = "orange";
                } else {
                    color = "red";
                }
            }

            newPoly.properties.color = color;
            return newPoly;
        });

        setColoredPolygons(updatedPolygons);
    }, [
        polygons,
        filterVelocidad,
        filterAltura,
        filterHDOP,
        filterP1DR,
        filterP1DT,
        lowVelocidad,
        medVelocidad,
        highVelocidad,
        lowAltura,
        medAltura,
        highAltura,
        lowHDOP,
        medHDOP,
        highHDOP,
        lowP1DR,
        medP1DR,
        highP1DR,
        lowP1DT,
        medP1DT,
        highP1DT,
    ]);

    useEffect(() => {

        if (
            !Array.isArray(polygons) ||
            polygons.length === 0 ||
            !Array.isArray(polygonsData) ||
            polygonsData.length === 0
        ) {
            return;
        }

        const calculateAreas = async () => {
            console.log("POLIGONOS: ", polygons);
            try {
                const polygonsForRequest = polygons
                    .filter((p) => p?.properties?.INTERSECCION !== "YES")
                    .map((p) => {
                        const ring = p.geometry?.coordinates?.[0] || [];
                        // Convertimos lat, lon a lon, lat
                        const coordsLonLat = ring.map(([lat, lon]) => [lon, lat]);
                        return {
                            geometry: {
                                type: "Polygon",
                                coordinates: [coordsLonLat],
                            },
                        };
                    });

                const analisisId = idAnalisis?.data?.ID_ANALISIS;

                const requestData = {
                    id_analisis: analisisId,
                    bufferedLines: [],
                    polygons: polygonsForRequest,
                    polygonsData: polygonsData.map((pd) => ({
                        type: pd.type,
                        geometry: pd.geometry,
                        properties: pd.properties,
                    })),
                    tipo_analisis: "fertilization",
                };

                const response = await axios.post(
                    `${API_BASE_PYTHON_SERVICE}mapping/calculate_areas`,
                    requestData
                );

                if (response.status === 200 && response.data && response.data.data) {
                    const {
                        areaSobreAplicada,
                        areaAplicada,
                        areaNoAplicada,
                        porcentajeDeVariacion,
                        areaAplicadaEnCalles,
                        areaAplicadaFueraDelLote,
                        dosisTotalDiferencia,
                        dosisTotalRealAplicada,
                        areaTotalLote
                    } = response.data.data;

                    if (typeof onAreasCalculated === "function") {
                        onAreasCalculated({
                            areaSobreAplicada,
                            areaAplicada,
                            areaNoAplicada,
                            porcentajeDeVariacion,
                            areaAplicadaEnCalles,
                            areaAplicadaFueraDelLote,
                            dosisTotalRealAplicada,
                            dosisTotalDiferencia,
                            areaTotalLote
                        });
                    }
                }
            } catch (error) {
                console.error("Error al calcular áreas:", error);
            }
        };

        const timeoutRef = setTimeout(() => {
            calculateAreas();
        }, 500);

        return () => {
            clearTimeout(timeoutRef);
        };
    }, [polygons, polygonsData, lines, onAreasCalculated, idAnalisis]);

    const handleToggleFilter = useCallback(
        (filterName) => {
            switch (filterName) {
                case "VELOCIDAD":
                case "VELOCIDAD_Km_H":
                    setFilterVelocidad((prev) => {
                        const newVal = !prev;
                        if (newVal) {
                            setActiveFilter("speed");
                        } else {
                            setActiveFilter(null);
                        }
                        return newVal;
                    });
                    break;

                case "ALTURA":
                    setFilterAltura((prev) => {
                        const newVal = !prev;
                        if (newVal) {
                            setActiveFilter("altura");
                        } else {
                            setActiveFilter(null);
                        }
                        return newVal;
                    });
                    break;

                case "HDOP":
                    setFilterHDOP((prev) => {
                        const newVal = !prev;
                        if (newVal) {
                            setActiveFilter("hdop");
                        } else {
                            setActiveFilter(null);
                        }
                        return newVal;
                    });
                    break;

                case "P1_DR_Kg_H":
                    setFilterP1DR((prev) => {
                        const newVal = !prev;
                        if (newVal) {
                            setActiveFilter("p1dr");
                        } else {
                            setActiveFilter(null);
                        }
                        return newVal;
                    });
                    break;

                case "P1_DT_Kg_H":
                    setFilterP1DT((prev) => {
                        const newVal = !prev;
                        if (newVal) {
                            setActiveFilter("p1dt");
                        } else {
                            setActiveFilter(null);
                        }
                        return newVal;
                    });
                    break;

                default:
                    break;
            }
        },
        [
            setFilterVelocidad,
            setFilterAltura,
            setFilterHDOP,
            setFilterP1DR,
            setFilterP1DT,
            setActiveFilter
        ]
    );

    return (
        <>
            <CommonMap
                center={mapCenter}
                zoom={zoom}
                polygons={coloredPolygons}
                setPolygons={setPolygons}
                lines={lines}
                points={null}
                hullPolygon={null}
                nonIntersectedAreas={nonIntersectedAreas}
                bufferedLines={bufferedLines}
                bufferedIntersections={bufferedIntersections}
                onLineHover={() => {}}
                onLineMouseOut={() => {}}
                onLineClick={handleLineClick}
                polygonProperties={polygonsProperties}
                showIntersections={true}
                mapRef={mapRef}
                userId={userData.userId || null}
                stretchPoints={[]}
                lineasNoFiltradas={showUnfilteredLines ? lineasNoFiltradas : []}
                highlightedLote={highlightedLote}
                polygonsData={polygonsData}
                activeLotes={activeLotes}
                onLeaveLote={() => {}}
                onSelectLote={() => {}}
                onHoverLote={() => {}}
                areasSuperpuestas={[]}
                setImgLaflet={setImgLaflet}
                polygonsLayerRef={polygonsLayerRef}
                setLines={setLines}
                popupInfo={popupInfo}
                setPopupInfo={setPopupInfo}
                isEditing={isEditing}
                setNorthWestCoords={setNorthWestCoords}
                setSouthEastCoords={setNorthWestCoords}
                setCoordinateSystem={setNorthWestCoords}
            />

            {/*
              Si hay polígonos o líneas, mostramos el BarIndicator.
              El `filterType` depende de `activeFilter`; si está nulo, usamos "traslape" como fallback.
            */}
            {((Array.isArray(coloredPolygons) && coloredPolygons.length > 0) ||
                (Array.isArray(lines) && lines.length > 0)) && (
                <BarIndicator
                    filterType={activeFilter ? activeFilter : "traslape"}
                    isHistory={false}
                    onLabelClick={handleLabelClick}
                    setLabelsAndColors={setLabelsAndColors}
                />
            )}

            <MapDialog
                isOpen={!!isFilterDialogOpen}
                onClose={() => {
                    if (typeof closeFilterDialog === "function") {
                        closeFilterDialog();
                    }
                }}
                availableFilters={availableFilters}
                lowSpeed={lowVelocidad}
                medSpeed={medVelocidad}
                highSpeed={highVelocidad}
                setLowSpeed={setLowVelocidad}
                setMedSpeed={setMedVelocidad}
                setHighSpeed={setHighVelocidad}
                lowAltitude={lowAltura}
                medAltitude={medAltura}
                highAltitude={highAltura}
                setLowAltitude={setLowAltura}
                setMedAltitude={setMedAltura}
                setHighAltitude={setHighAltura}
                lowHDOP={lowHDOP}
                medHDOP={medHDOP}
                highHDOP={highHDOP}
                setLowHDOP={setLowHDOP}
                setMedHDOP={setMedHDOP}
                setHighHDOP={setHighHDOP}
                lowP1DR={lowP1DR}
                medP1DR={medP1DR}
                highP1DR={highP1DR}
                setLowP1DR={setLowP1DR}
                setMedP1DR={setMedP1DR}
                setHighP1DR={setHighP1DR}
                lowP1DT={lowP1DT}
                medP1DT={medP1DT}
                highP1DT={highP1DT}
                setLowP1DT={setLowP1DT}
                setMedP1DT={setMedP1DT}
                setHighP1DT={setHighP1DT}
                handleToggleFilter={handleToggleFilter}
                setTipoMapeo={setTipoMapeo}
                harvestGroupings={harvestGroupings}
                selectedHarvestGrouping={selectedHarvestGrouping}
                onSelectHarvestGrouping={setSelectedHarvestGrouping}
            />
        </>
    );
};

export default Fertilization;
