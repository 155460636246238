import axios from "axios";
import { API_BASE_URL } from "./config";

// Función genérica para hacer peticiones GET y manejar errores
const fetchData = async (url) => {
    try {
        const response = await axios.get(url);
        return { success: true, data: response.data };
    } catch (error) {
        console.error(`Error en la petición a ${url}:`, error);
        return { success: false, error };
    }
};

/*======================================================
*  PETICIONES DE APS
* ======================================================*/
export const obtenerDatosCompletosAps = async (idAnalisisAps) => {
    const response = await fetchData(`${API_BASE_URL}dashboard/aps/completo/${idAnalisisAps}`);
    return response.success ? response.data : {};
};

/*======================================================
*  PETICIONES DE COSECHA_MECANICA
* ======================================================*/
export const obtenerDatosCompletosCm = async (idAnalisisCosechaMecanica) => {
    const result = await fetchData(`${API_BASE_URL}dashboard/cosechaMecanica/completo/${idAnalisisCosechaMecanica}`);
    return result.success ? result.data : {};
};
/*======================================================
*  PETICIONES DE FERTILIZACIÓN
* ======================================================*/
export const obtenerDatosCompletosFt = (idAnalisisFertilizacion) => {
    return fetchData(`${API_BASE_URL}dashboard/fertilizacion/completo/${idAnalisisFertilizacion}`);
};


/*======================================================
*  PETICIONES DE HERBICIDAS
* ======================================================*/
export const obtenerResponsableHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/responsableHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerFechaHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/fechaHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerNombreFincaHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/nombreFincaHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerParcelaHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/parcelaHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerOperadorHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/operadorHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerEquipoHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/equipoHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerActividadHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/actividadHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerAreaNetaHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/areaNetaHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerAreaBrutaHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/areaBrutaHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerDiferenciaDeAreaHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/diferenciaDeAreaHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerHoraInicioHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/horaInicioHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerHoraFinalHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/horaFinalHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerTiempoTotalHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/tiempoTotalHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerEficienciaHerbicidas = (idAnalisisHerbicidas) => {
    return fetchData(`${API_BASE_URL}/dashboard/eficienciaHerbicidas/${idAnalisisHerbicidas}`);
};

export const obtenerPromedioVelocidadHerbicidas = async (idAnalisisHerbicidas) => {
    const result = await fetchData(`${API_BASE_URL}/dashboard/promedioVelocidadHerbicidas/${idAnalisisHerbicidas}`);
    return result.success ? `${parseFloat(result.data).toFixed(3)} Km/H` : null;
};

// ID DE LOS ANALISIS

export const APLICACIONES_AEREAS = 1;
export const COSECHA_MECANICA = 2;
export const HERBICIDAS = 3;
export const FERTILIZACION = 4;
export const CONTEO_PALMA = 5;
export const PLANIFICACION_COSECHA = 6;

export const CONTEO_AGAVE = 9;
