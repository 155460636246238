import {
    obtenerDatosCompletosFt
} from "../../utils/Constants";
import { ultimoAnalisis } from "../../utils/mapUtils";


export const processFertilizacionData = (datosAnalisis, indicadores) => ({
    analisis: "FERTILIZACION",
    ...datosAnalisis
});

export const fetchDataFertilizacion = async (idAnalisisFertilizacion, setDatosAnalisis) => {
    try {
        const response = await obtenerDatosCompletosFt(idAnalisisFertilizacion);
        const datosResponse = response.data;

        const datos = {
            nombreResponsable: {
                titulo: "Responsable",
                valor: datosResponse.responsable || ""
            },
            nombreFinca: {
                titulo: "Nombre Finca",
                valor: datosResponse.nombreFinca || ""
            },
            codigoFinca: {
                titulo: "Código Finca",
                valor: datosResponse.codigoFinca || ""
            },
            lote: {
                titulo: "Lote",
                valor: datosResponse.lote || ""
            },
            nombreOperador: {
                titulo: "Nombre Operador",
                valor: datosResponse.nombreOperador || ""
            },
            zafra: {
                titulo: "Zafra",
                valor: datosResponse.zafra || ""
            },
            tercio: {
                titulo: "Tercio",
                valor: datosResponse.tercio || ""
            },
            fechaInicio: {
                titulo: "Fecha Inicio",
                valor: datosResponse.fechaInicio || ""
            },
            fechaFin: {
                titulo: "Fecha Final",
                valor: datosResponse.fechaFinal || ""
            },
            horaInicio: {
                titulo: "Hora Inicio",
                valor: datosResponse.horaInicio || ""
            },
            horaFin: {
                titulo: "Hora Fin",
                valor: datosResponse.horaFin || ""
            },
            codigoDeMaquina: {
                titulo: "Código de Máquina",
                valor: datosResponse.codigoDeMaquina || ""
            },
            eficiencia: {
                titulo: "Eficiencia",
                valor: typeof datosResponse.eficiencia === "number"
                    ? datosResponse.eficiencia.toFixed(3)
                    : ""
            },
            dosisTeorica: {
                titulo: "Dosis Teórica",
                valor: typeof datosResponse.dosisTeorica === "number"
                    ? datosResponse.dosisTeorica.toFixed(3)
                    : ""
            },
            anchoDeFaja: {
                titulo: "Ancho de Faja",
                valor: typeof datosResponse.anchoDeFaja === "number"
                    ? `${datosResponse.anchoDeFaja.toFixed(3)} m`
                    : ""
            },
            tchEstimado: {
                titulo: "TCH Estimado",
                valor: typeof datosResponse.tchEstimado === "number"
                    ? datosResponse.tchEstimado.toFixed(3)
                    : ""
            },
            equipo: {
                titulo: "Equipo",
                valor: datosResponse.equipo || ""
            },
            producto: {
                titulo: "Producto",
                valor: datosResponse.producto || ""
            },
            edadDias: {
                titulo: "Edad (días)",
                valor: typeof datosResponse.edadDias === "number"
                    ? datosResponse.edadDias.toFixed(0)
                    : ""
            },
            tiempoTotal: {
                titulo: "Tiempo Total",
                valor: datosResponse.tiempoTotal || ""
            },
            altura: {
                titulo: "Altura",
                valor: typeof datosResponse.altura === "number"
                    ? `${datosResponse.altura.toFixed(3)} m`
                    : ""
            },
            velocidad: {
                titulo: "Velocidad",
                valor: typeof datosResponse.velocidad === "number"
                    ? `${datosResponse.velocidad.toFixed(3)} km/h`
                    : ""
            },
            dosisReal: {
                titulo: "Dosis Real",
                valor: typeof datosResponse.dosisReal === "number" ?  `${datosResponse.dosisReal.toFixed(3)} km/h` : ""
            },
            diferenciaDosis: {
                titulo: "Diferencia Dosis",
                valor: typeof datosResponse.diferenciaDosis === "number" ? `${datosResponse.diferenciaDosis.toFixed(3)} %` : ""
            }
        };


        setDatosAnalisis(datos);
    } catch (error) {
        console.error("Error al cargar datos de Fertilización:", error);
    }
};


export const shouldEnableExecBashFertilizacion = (selectedFile) => {
    return selectedFile !== null;
};

export const cargaDatosFertilizacion = async (userData, selectedAnalysisTypeRef) => {
    if (selectedAnalysisTypeRef.current && userData.userId) {
        try {
            const response = await ultimoAnalisis(selectedAnalysisTypeRef, userData.userId);
            if (response && response.data && response.data.ID_ANALISIS) {
                return response;
            } else {
                console.error("Respuesta del último análisis no contiene datos esperados");
            }
        } catch (error) {
            console.error("Error al obtener último análisis:", error);
        }
    }
};
