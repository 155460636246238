import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormGroup,
    FormControlLabel,
    Switch,
    TextField,
    Button,
    Typography
} from '@mui/material';
import Draggable from 'react-draggable';

const PaperComponent = (props) => {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <div {...props} />
        </Draggable>
    );
};

const normalizeHarvestGroupingLabel = (groupKey) => {
    const withoutPrefix = groupKey.replace(/^agrupacion_/, '');
    const words = withoutPrefix.split('_');
    const capitalized = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalized.join(' ');
};

const MapDialog = ({
                       isOpen = false,
                       onClose = () => {},
                       availableFilters = {},

                       // Valores de velocidad
                       lowSpeed = 0,
                       medSpeed = 0,
                       highSpeed = 0,
                       setLowSpeed = () => {},
                       setMedSpeed = () => {},
                       setHighSpeed = () => {},

                       // Valores de gpsQuality
                       lowGpsQuality = 0,
                       medGpsQuality = 0,
                       highGpsQuality = 0,
                       setLowGpsQuality = () => {},
                       setMedGpsQuality = () => {},
                       setHighGpsQuality = () => {},

                       // Valores de fuel
                       lowFuel = 0,
                       medFuel = 0,
                       highFuel = 0,
                       setLowFuel = () => {},
                       setMedFuel = () => {},
                       setHighFuel = () => {},

                       // Valores de Rpm
                       lowRpm = 0,
                       medRpm = 0,
                       highRpm = 0,
                       setLowRpm = () => {},
                       setMedRpm = () => {},
                       setHighRpm = () => {},

                       // Valores de CutterBase
                       lowCutterBase = 0,
                       medCutterBase = 0,
                       highCutterBase = 0,
                       setLowCutterBase = () => {},
                       setMedCutterBase = () => {},
                       setHighCutterBase = () => {},

                       // Valores de Altitude
                       lowAltitude = 0,
                       medAltitude = 0,
                       highAltitude = 0,
                       setLowAltitude = () => {},
                       setMedAltitude = () => {},
                       setHighAltitude = () => {},

                       // Valores de DosisReal
                       lowRealDose = 0,
                       medRealDose = 0,
                       highRealDose = 0,
                       setLowRealDose = () => {},
                       setMedRealDose = () => {},
                       setHighRealDose = () => {},

                       // NUEVAS PROPS para HDOP
                       lowHDOP = 0,
                       medHDOP = 0,
                       highHDOP = 0,
                       setLowHDOP = () => {},
                       setMedHDOP = () => {},
                       setHighHDOP = () => {},

                       // NUEVAS PROPS para P1_DR_Kg_H
                       lowP1DR = 0,
                       medP1DR = 0,
                       highP1DR = 0,
                       setLowP1DR = () => {},
                       setMedP1DR = () => {},
                       setHighP1DR = () => {},

                       // NUEVAS PROPS para P1_DT_Kg_H
                       lowP1DT = 0,
                       medP1DT = 0,
                       highP1DT = 0,
                       setLowP1DT = () => {},
                       setMedP1DT = () => {},
                       setHighP1DT = () => {},

                       handleToggleFilter = () => {},
                       usarVelocidadKmH = false,
                       setTipoMapeo = () => {},
                       handleToggleMostrarLineas = () => {},
                       mostrarLineas = false,
                       setHarvestLinesBuffer = () => {},
                       harvestGroupings = {},
                       selectedHarvestGrouping = null,
                       onSelectHarvestGrouping = () => {},
                       HarvestLines = false
                   }) => {
    const [activeToggle, setActiveToggle] = useState('');

    // Para label de velocidad
    const velocidadNombre = usarVelocidadKmH ? 'VELOCIDAD_Km_H' : 'VELOCIDAD';

    const normalizeLabel = (groupKey) => {
        const withoutPrefix = groupKey.replace(/^agrupacion_/, '');
        const words = withoutPrefix.split('_');
        const capitalized = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        return capitalized.join(' ');
    };

    const getFilterLabel = (filterName) => {
        switch (filterName) {
            case 'PILOTO_AUTOMATICO':
                return 'Piloto Automático';
            case 'AUTO_TRACKET':
                return 'Auto Tracket';
            case 'MODO_CORTE_BASE':
                return 'Modo corte base';
            case 'VELOCIDAD':
                return 'Velocidad (Km/H)';
            case 'VELOCIDAD_Km_H':
                return 'Velocidad (Km/H)';
            case 'CALIDAD_DE_SENAL':
                return 'Calidad Gps';
            case 'CONSUMOS_DE_COMBUSTIBLE':
                return 'Combustible';
            case 'RPM':
                return 'RPM';
            case 'PRESION_DE_CORTADOR_BASE':
                return 'Presión de cortador base';
            case 'ALTURA':
                return 'Altura (msnm)';
            case 'DOSISREAL':
                return 'Dosis real';
            case 'HDOP':
                return 'HDOP';
            case 'P1_DR_Kg_H':
                return 'Dosis real (Kg/h)';
            case 'P1_DT_Kg_H':
                return 'Dosis teórica (Kg/h)';
            default:
                return normalizeLabel(filterName);
        }
    };

    // Lógica para activar/desactivar un filtro a la vez (o varios, según requieras)
    const handleSingleToggle = (filterName) => {
        if (activeToggle === filterName) {
            // Lo apagas
            setActiveToggle('');
            handleToggleFilter(filterName);
            setTipoMapeo('');
        } else {
            // Quitas el anterior, pones el nuevo
            if (activeToggle) {
                handleToggleFilter(activeToggle);
            }
            setActiveToggle(filterName);
            handleToggleFilter(filterName);
            const label = getFilterLabel(filterName);
            setTipoMapeo(label);
        }
    };

    const handleHarvestGroupingToggle = (groupKey) => {
        if (selectedHarvestGrouping === groupKey) {
            onSelectHarvestGrouping(null);
        } else {
            onSelectHarvestGrouping(groupKey);
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="draggable-dialog-title"
            sx={{
                '& .MuiDialog-paper': {
                    width: '30%',
                    maxWidth: 'none',
                    overflow: 'hidden',
                    backgroundColor: 'white',
                    resize: 'both',
                }
            }}
            PaperComponent={PaperComponent}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Generar Mapas
            </DialogTitle>
            <DialogContent>
                <FormGroup>
                    {HarvestLines && (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={mostrarLineas}
                                        onChange={handleToggleMostrarLineas}
                                        name="mostrarLineas"
                                        color="primary"
                                    />
                                }
                                label="Lineas de cosecha"
                            />
                            <TextField
                                label="Buffer de lineas de cosecha"
                                variant="outlined"
                                type="number"
                                name="harvestLinesBuffer"
                                onChange={e => {
                                    const value = e.target.value === '' ? '' : Number(e.target.value);
                                    setHarvestLinesBuffer(value);
                                }}
                                onBlur={e => {
                                    const value = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setHarvestLinesBuffer(value);
                                }}
                                margin="normal"
                            />
                        </>
                    )}

                    {/** Filtros "autopilot", "autoTracket", "modeCutterBase", etc. */}
                    {availableFilters.autoPilot && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={activeToggle === 'PILOTO_AUTOMATICO'}
                                    onChange={() => handleSingleToggle('PILOTO_AUTOMATICO')}
                                />
                            }
                            label="Piloto Automático"
                        />
                    )}
                    {availableFilters.autoTracket && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={activeToggle === 'AUTO_TRACKET'}
                                    onChange={() => handleSingleToggle('AUTO_TRACKET')}
                                />
                            }
                            label="Auto Tracket"
                        />
                    )}
                    {availableFilters.modeCutterBase && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={activeToggle === 'MODO_CORTE_BASE'}
                                    onChange={() => handleSingleToggle('MODO_CORTE_BASE')}
                                />
                            }
                            label="Modo corte base"
                        />
                    )}

                    {/** Filtrado por Velocidad */}
                    {availableFilters.speed && (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={activeToggle === velocidadNombre}
                                        onChange={() => handleSingleToggle(velocidadNombre)}
                                    />
                                }
                                label={usarVelocidadKmH ? "Velocidad (Km/H)" : "Velocidad (Km/H)"}
                            />
                            <TextField
                                label="Bajo"
                                variant="outlined"
                                type="number"
                                name="low"
                                value={lowSpeed}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setLowSpeed(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setLowSpeed(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Medio"
                                variant="outlined"
                                type="number"
                                name="medium"
                                value={medSpeed}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setMedSpeed(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setMedSpeed(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Alto"
                                variant="outlined"
                                type="number"
                                name="high"
                                value={highSpeed}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setHighSpeed(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setHighSpeed(val);
                                }}
                                margin="normal"
                            />
                        </>
                    )}

                    {/** Filtrado por Calidad Gps */}
                    {availableFilters.gpsQuality && (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={activeToggle === 'CALIDAD_DE_SENAL'}
                                        onChange={() => handleSingleToggle('CALIDAD_DE_SENAL')}
                                    />
                                }
                                label="Calidad Gps"
                            />
                            <TextField
                                label="Bajo"
                                variant="outlined"
                                type="number"
                                name="lowGps"
                                value={lowGpsQuality}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setLowGpsQuality(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setLowGpsQuality(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Medio"
                                variant="outlined"
                                type="number"
                                name="mediumGps"
                                value={medGpsQuality}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setMedGpsQuality(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setMedGpsQuality(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Alto"
                                variant="outlined"
                                type="number"
                                name="highGps"
                                value={highGpsQuality}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setHighGpsQuality(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setHighGpsQuality(val);
                                }}
                                margin="normal"
                            />
                        </>
                    )}

                    {/** Filtrado por Combustible */}
                    {availableFilters.fuel && (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={activeToggle === 'CONSUMOS_DE_COMBUSTIBLE'}
                                        onChange={() => handleSingleToggle('CONSUMOS_DE_COMBUSTIBLE')}
                                    />
                                }
                                label="Combustible"
                            />
                            <TextField
                                label="Bajo"
                                variant="outlined"
                                type="number"
                                name="lowFuel"
                                value={lowFuel}
                                onChange={e => setLowFuel(e.target.value === '' ? '' : Number(e.target.value))}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setLowFuel(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Medio"
                                variant="outlined"
                                type="number"
                                name="mediumFuel"
                                value={medFuel}
                                onChange={e => setMedFuel(e.target.value === '' ? '' : Number(e.target.value))}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setMedFuel(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Alto"
                                variant="outlined"
                                type="number"
                                name="highFuel"
                                value={highFuel}
                                onChange={e => setHighFuel(e.target.value === '' ? '' : Number(e.target.value))}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setHighFuel(val);
                                }}
                                margin="normal"
                            />
                        </>
                    )}

                    {/** Filtrado por RPM */}
                    {availableFilters.rpm && (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={activeToggle === 'RPM'}
                                        onChange={() => handleSingleToggle('RPM')}
                                    />
                                }
                                label="RPM"
                            />
                            <TextField
                                label="Bajo"
                                variant="outlined"
                                type="number"
                                name="lowRPM"
                                value={lowRpm}
                                onChange={e => setLowRpm(e.target.value === '' ? '' : Number(e.target.value))}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setLowRpm(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Medio"
                                variant="outlined"
                                type="number"
                                name="mediumRPM"
                                value={medRpm}
                                onChange={e => setMedRpm(e.target.value === '' ? '' : Number(e.target.value))}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setMedRpm(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Alto"
                                variant="outlined"
                                type="number"
                                name="highRPM"
                                value={highRpm}
                                onChange={e => setHighRpm(e.target.value === '' ? '' : Number(e.target.value))}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setHighRpm(val);
                                }}
                                margin="normal"
                            />
                        </>
                    )}

                    {/** Filtrado por Presión de cortador base */}
                    {availableFilters.cutterBase && (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={activeToggle === 'PRESION_DE_CORTADOR_BASE'}
                                        onChange={() => handleSingleToggle('PRESION_DE_CORTADOR_BASE')}
                                    />
                                }
                                label="Presión de cortador base (Bar)"
                            />
                            <TextField
                                label="Bajo"
                                variant="outlined"
                                type="number"
                                name="lowCutterBase"
                                value={lowCutterBase}
                                onChange={e => setLowCutterBase(e.target.value === '' ? '' : Number(e.target.value))}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setLowCutterBase(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Medio"
                                variant="outlined"
                                type="number"
                                name="mediumCutterBase"
                                value={medCutterBase}
                                onChange={e => setMedCutterBase(e.target.value === '' ? '' : Number(e.target.value))}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setMedCutterBase(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Alto"
                                variant="outlined"
                                type="number"
                                name="highCutterBase"
                                value={highCutterBase}
                                onChange={e => setHighCutterBase(e.target.value === '' ? '' : Number(e.target.value))}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setHighCutterBase(val);
                                }}
                                margin="normal"
                            />
                        </>
                    )}

                    {/** Filtrado por Altura */}
                    {availableFilters.altitude && (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={activeToggle === 'ALTURA'}
                                        onChange={() => handleSingleToggle('ALTURA')}
                                    />
                                }
                                label="Altura (msnm)"
                            />
                            <TextField
                                label="Bajo"
                                variant="outlined"
                                type="number"
                                name="low"
                                value={lowAltitude}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setLowAltitude(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setLowAltitude(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Medio"
                                variant="outlined"
                                type="number"
                                name="medium"
                                value={medAltitude}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setMedAltitude(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setMedAltitude(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Alto"
                                variant="outlined"
                                type="number"
                                name="high"
                                value={highAltitude}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setHighAltitude(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setHighAltitude(val);
                                }}
                                margin="normal"
                            />
                        </>
                    )}

                    {/** Filtrado por Dosis real */}
                    {availableFilters.realDose && (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={activeToggle === 'DOSISREAL'}
                                        onChange={() => handleSingleToggle('DOSISREAL')}
                                    />
                                }
                                label="Dosis real (mg)"
                            />
                            <TextField
                                label="Bajo"
                                variant="outlined"
                                type="number"
                                name="low"
                                value={lowRealDose}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setLowRealDose(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setLowRealDose(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Medio"
                                variant="outlined"
                                type="number"
                                name="medium"
                                value={medRealDose}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setMedRealDose(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setMedRealDose(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Alto"
                                variant="outlined"
                                type="number"
                                name="high"
                                value={highRealDose}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setHighRealDose(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setHighRealDose(val);
                                }}
                                margin="normal"
                            />
                        </>
                    )}

                    {availableFilters.hdop && (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={activeToggle === 'HDOP'}
                                        onChange={() => handleSingleToggle('HDOP')}
                                    />
                                }
                                label="HDOP"
                            />
                            <TextField
                                label="Bajo"
                                variant="outlined"
                                type="number"
                                value={lowHDOP}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setLowHDOP(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setLowHDOP(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Medio"
                                variant="outlined"
                                type="number"
                                value={medHDOP}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setMedHDOP(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setMedHDOP(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Alto"
                                variant="outlined"
                                type="number"
                                value={highHDOP}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setHighHDOP(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setHighHDOP(val);
                                }}
                                margin="normal"
                            />
                        </>
                    )}

                    {/** NUEVO: Filtro P1_DR_Kg_H */}
                    {availableFilters.p1dr && (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={activeToggle === 'P1_DR_Kg_H'}
                                        onChange={() => handleSingleToggle('P1_DR_Kg_H')}
                                    />
                                }
                                label="Dosis real (Kg/h)"
                            />
                            <TextField
                                label="Bajo"
                                variant="outlined"
                                type="number"
                                value={lowP1DR}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setLowP1DR(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setLowP1DR(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Medio"
                                variant="outlined"
                                type="number"
                                value={medP1DR}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setMedP1DR(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setMedP1DR(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Alto"
                                variant="outlined"
                                type="number"
                                value={highP1DR}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setHighP1DR(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setHighP1DR(val);
                                }}
                                margin="normal"
                            />
                        </>
                    )}

                    {/** NUEVO: Filtro P1_DT_Kg_H */}
                    {availableFilters.p1dt && (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={activeToggle === 'P1_DT_Kg_H'}
                                        onChange={() => handleSingleToggle('P1_DT_Kg_H')}
                                    />
                                }
                                label="Dosis teorica (Kg/h)"
                            />
                            <TextField
                                label="Bajo"
                                variant="outlined"
                                type="number"
                                value={lowP1DT}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setLowP1DT(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setLowP1DT(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Medio"
                                variant="outlined"
                                type="number"
                                value={medP1DT}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setMedP1DT(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setMedP1DT(val);
                                }}
                                margin="normal"
                            />
                            <TextField
                                label="Alto"
                                variant="outlined"
                                type="number"
                                value={highP1DT}
                                onChange={e => {
                                    const val = e.target.value === '' ? '' : Number(e.target.value);
                                    setHighP1DT(val);
                                }}
                                onBlur={e => {
                                    const val = e.target.value === '' ? 0 : Math.max(0, Number(e.target.value));
                                    setHighP1DT(val);
                                }}
                                margin="normal"
                            />
                        </>
                    )}

                    {harvestGroupings && Object.keys(harvestGroupings).length > 0 && (
                        <>
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>
                                Agrupaciones de Harvest
                            </Typography>
                            {Object.keys(harvestGroupings).map((groupKey) => (
                                <FormControlLabel
                                    key={groupKey}
                                    control={
                                        <Switch
                                            checked={selectedHarvestGrouping === groupKey}
                                            onChange={() => {
                                                handleSingleToggle(groupKey);
                                                handleHarvestGroupingToggle(groupKey);
                                            }}
                                        />
                                    }
                                    label={normalizeHarvestGroupingLabel(groupKey)}
                                />
                            ))}
                        </>
                    )}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MapDialog;
