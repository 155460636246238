/**** fertilizacionDataCards.jsx ****/

import React from "react";
import { styled } from "@mui/system";
import DataCard from "../../components/CardData/DataCard";
import CircularProgress from "@mui/material/CircularProgress";
import { displayValue } from "../../utils/generalUtils";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";

const CategoryContainer = styled("div")({
    marginTop: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
    width: "100%",
    position: "relative",
});

const CategoryTitle = styled("h3")({
    fontSize: "1.2em",
    fontWeight: "600",
    color: "#fff",
    backgroundColor: "#1565C0",
    padding: "10px",
    borderRadius: "4px",
    marginBottom: "20px",
    textAlign: "center",
    textTransform: "uppercase",
});

const ChartsRow = styled("div")({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "20px",
});

const ChartContainer = styled("div")({
    width: "100%",
    height: "420px",
    backgroundColor: "transparent",
    borderRadius: "8px",
    padding: 0,
    overflow: "visible", // Importante para que no se recorten labels
});

const VariationContainer = styled("div")({
    position: "absolute",
    top: "2px",
    right: "5px",
    borderRadius: "8px",
    padding: "10px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
    textAlign: "center",
    fontWeight: "bold",
    minWidth: "120px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
});

const AreaTotalContainer = styled("div")({
    position: "absolute",
    top: "2px",
    left: "5px",
    borderRadius: "8px",
    padding: "10px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
    textAlign: "center",
    fontWeight: "bold",
    minWidth: "120px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
});

const VariationLabel = styled("div")({
    fontSize: "12px",
    color: "#0d47a1",
});

const VariationValue = styled("div")({
    fontSize: "24px",
    color: "#0d47a1",
    marginTop: "4px",
});

const CardsGrid = styled("div")({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(220px, 1fr))",
    gap: "10px",
});

function renderCategory(categoryTitle, content, refProp) {
    return (
        <CategoryContainer ref={refProp}>
            <CategoryTitle>{categoryTitle}</CategoryTitle>
            {content}
        </CategoryContainer>
    );
}

// Funciones auxiliares para formatear valores
const formatNumber = (value, decimals = 3, suffix = "") => {
    if (value !== undefined && value !== null && !isNaN(parseFloat(value))) {
        return parseFloat(value).toFixed(decimals) + suffix;
    }
    return "No hay datos";
};

const formatString = (value) => {
    return value && value.toString().trim() !== "" ? displayValue(value) : "No hay datos";
};

export const renderFertilizacionDataCards = (
    validations,
    datosAnalisis,
    indicadores,
    onAreasCalculatedLoader,
    chartsRef
) => {
    const variosCards = [
        {
            title: "Altura",
            value: datosAnalisis.altura?.valor
                ? formatString(datosAnalisis.altura.valor) + "smn"
                : "No hay datos",
        },
        {
            title: "Velocidad",
            value: datosAnalisis.velocidad?.valor
                ? formatString(datosAnalisis.velocidad.valor)
                : "No hay datos",
        },
        {
            title: "Código Finca",
            value: datosAnalisis.codigoFinca?.valor
                ? formatString(datosAnalisis.codigoFinca.valor)
                : "No hay datos",
        },
        {
            title: "Lote",
            value: datosAnalisis.lote?.valor
                ? formatString(datosAnalisis.lote.valor)
                : "No hay datos",
        },
        {
            title: "Nombre Operador",
            value: datosAnalisis.nombreOperador?.valor
                ? formatString(datosAnalisis.nombreOperador.valor)
                : "No hay datos",
        },
        {
            title: "Zafra",
            value: datosAnalisis.zafra?.valor
                ? formatString(datosAnalisis.zafra.valor)
                : "No hay datos",
        },
        {
            title: "Tercio",
            value: datosAnalisis.tercio?.valor
                ? formatString(datosAnalisis.tercio.valor)
                : "No hay datos",
        },
        {
            title: "Código de Máquina",
            value: datosAnalisis.codigoDeMaquina?.valor
                ? formatString(datosAnalisis.codigoDeMaquina.valor)
                : "No hay datos",
        },
        {
            title: "Eficiencia",
            value: formatNumber(datosAnalisis.eficiencia?.valor, 3),
        },
        {
            title: "Dosis Teórica",
            value: formatNumber(datosAnalisis.dosisTeorica?.valor, 3),
        },
        {
          title: "Dosis Real",
          value: formatNumber(datosAnalisis.dosisReal?.valor, 3),
        },
        {
            title: "Diferencia de Dosis",
            value: formatNumber(datosAnalisis.diferenciaDosis?.valor, 3, " %"),
        },
        {
            title: "Ancho de Faja",
            value: formatNumber(datosAnalisis.anchoDeFaja?.valor, 3, " m"),
        },
        {
            title: "TCH Estimado",
            value: formatNumber(datosAnalisis.tchEstimado?.valor, 3),
        },
        {
            title: "Equipo",
            value: datosAnalisis.equipo?.valor
                ? formatString(datosAnalisis.equipo.valor)
                : "No hay datos",
        },
        {
            title: "Producto",
            value: datosAnalisis.producto?.valor
                ? formatString(datosAnalisis.producto.valor)
                : "No hay datos",
        },
        {
            title: "Edad (días)",
            value: formatNumber(datosAnalisis.edadDias?.valor, 0),
        },
        {
            title: "Tiempo Total",
            value: datosAnalisis.tiempoTotal?.valor
                ? formatString(datosAnalisis.tiempoTotal.valor)  + "H"
                : "No hay datos",
        }
    ];

    const totalArea =
        (indicadores.areaAplicada || 0) +
        (indicadores.areaNoAplicada || 0) +
        (indicadores.areaSobreAplicada || 0);

    const pieData = [
        {
            id: "aplicada",
            label: "aplicada",
            value: indicadores.areaAplicada || 0,
            color: "#4CAF50",
            percentage:
                totalArea > 0 ? ((indicadores.areaAplicada || 0) / totalArea) * 100 : 0,
        },
        {
            id: "sobre aplicada",
            label: "sobre aplicada",
            value: indicadores.areaSobreAplicada || 0,
            color: "#F44336",
            percentage:
                totalArea > 0
                    ? ((indicadores.areaSobreAplicada || 0) / totalArea) * 100
                    : 0,
        },
        {
            id: "no aplicada",
            label: "no aplicada",
            value: indicadores.areaNoAplicada || 0,
            color: "#FFEB3B",
            percentage:
                totalArea > 0 ? ((indicadores.areaNoAplicada || 0) / totalArea) * 100 : 0,
        },
    ];

    const barData = [
        {
            categoria: "Área aplicada en calles",
            valor: indicadores.areaSobreCalles || 0,
            color: "#1565C0",
        },
        {
            categoria: "Área aplicada fuera del lote",
            valor: indicadores.areaFueraDeLote || 0,
            color: "#F44336",
        },
    ];

    const charts = (
        <div style={{ position: "relative" }}>
            {/* Indicador de Área Total del Lote */}
            <AreaTotalContainer>
                {onAreasCalculatedLoader === false ? (
                    <CircularProgress size={24} />
                ) : indicadores.areaTotalLote ? (
                    <>
                        <VariationLabel>Área Total Lote</VariationLabel>
                        <VariationValue>
                            {displayValue(indicadores.areaTotalLote)}
                        </VariationValue>
                    </>
                ) : (
                    "No hay datos"
                )}
            </AreaTotalContainer>

            {/* Indicador de Porcentaje de Variación */}
            <VariationContainer>
                {onAreasCalculatedLoader === false ? (
                    <CircularProgress size={24} />
                ) : indicadores.porcentajeVariacion ? (
                    <>
                        <VariationLabel>Variación</VariationLabel>
                        <VariationValue>
                            {displayValue(indicadores.porcentajeVariacion)}
                        </VariationValue>
                    </>
                ) : (
                    "No hay datos"
                )}
            </VariationContainer>

            <ChartsRow>
                {/* Gráfica de Dona (Pie) */}
                <ChartContainer className="fertPieChart">
                    <h4
                        style={{
                            textAlign: "center",
                            marginBottom: "10px",
                            fontSize: "1.5em",
                            fontWeight: "bold",
                        }}
                    >
                        Distribución de Áreas
                    </h4>
                    <div style={{ width: "100%", height: "100%" }}>
                        <ResponsivePie
                            data={pieData}
                            margin={{ top: 90, right: 40, bottom: 40, left: 40 }}
                            innerRadius={0.6}
                            padAngle={1}
                            cornerRadius={3}
                            colors={{ datum: "data.color" }}
                            borderWidth={1}
                            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                            enableArcLinkLabels={false}
                            arcLabel={(d) =>
                                `${d.data.value} ha (${d.data.percentage.toFixed(1)}%)`
                            }
                            arcLabelsSkipAngle={0}
                            arcLabelsTextColor="#333333"
                            arcLabelsRadiusOffset={0.55}
                            theme={{
                                tooltip: {
                                    container: {
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                    },
                                },
                                labels: {
                                    text: {
                                        fontSize: 12,
                                        fontWeight: "bold",
                                    },
                                },
                                legends: {
                                    text: {
                                        fontSize: 13,
                                        fontWeight: "bold",
                                    },
                                },
                            }}
                            legends={[
                                {
                                    anchor: "top",
                                    direction: "row",
                                    justify: false,
                                    translateX: 0,
                                    translateY: -65,
                                    itemsSpacing: 14,
                                    itemWidth: 140,
                                    itemHeight: 18,
                                    itemDirection: "left-to-right",
                                    itemTextColor: "#999",
                                    itemOpacity: 1,
                                    symbolSize: 16,
                                    symbolShape: "circle",
                                    effects: [
                                        {
                                            on: "hover",
                                            style: {
                                                itemTextColor: "#000",
                                            },
                                        },
                                    ],
                                },
                            ]}
                            tooltip={(data) => {
                                const d = data.datum;
                                return (
                                    <div
                                        style={{
                                            background: "white",
                                            padding: "5px 10px",
                                            border: "1px solid #ccc",
                                        }}
                                    >
                                        <strong>{d.data.label}</strong>
                                        <br />
                                        {d.data.value} ha
                                        <br />
                                        {d.data.percentage.toFixed(2)}%
                                    </div>
                                );
                            }}
                        />
                    </div>
                </ChartContainer>

                {/* Gráfica de Barras (Bar) */}
                <ChartContainer className="fertBarChart">
                    <h4
                        style={{
                            textAlign: "center",
                            marginBottom: "10px",
                            fontSize: "1.5em",
                            fontWeight: "bold",
                        }}
                    >
                        Área Aplicada No Productiva
                    </h4>
                    <div style={{ width: "100%", height: "100%" }}>
                        <ResponsiveBar
                            data={barData}
                            keys={["valor"]}
                            indexBy="categoria"
                            margin={{ top: 90, right: 50, bottom: 50, left: 20 }}
                            padding={0.3}
                            layout="horizontal"
                            colors={({ data }) => data.color}
                            borderColor={{
                                from: "color",
                                modifiers: [["darker", 1.6]],
                            }}
                            axisLeft={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Hectáreas",
                                legendPosition: "middle",
                                legendOffset: 40,
                            }}
                            /* Forzamos que se muestren etiquetas aun cuando son pequeñas */
                            labelSkipWidth={0}
                            labelSkipHeight={0}
                            /* Etiquetas en color negro */
                            labelTextColor="#000000"
                            theme={{
                                tooltip: {
                                    container: {
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                    },
                                },
                                axis: {
                                    legend: {
                                        text: {
                                            fontSize: 16,
                                            fontWeight: "bold",
                                        },
                                    },
                                    ticks: {
                                        text: {
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        },
                                    },
                                },
                                legends: {
                                    text: {
                                        fontSize: 13,
                                        fontWeight: "bold",
                                    },
                                },
                                labels: {
                                    text: {
                                        fontSize: 14,
                                        fontWeight: "bold",
                                    },
                                },
                            }}
                            legends={[
                                {
                                    dataFrom: "indexes",
                                    anchor: "top",
                                    direction: "row",
                                    justify: false,
                                    translateX: 0,
                                    translateY: -65,
                                    itemsSpacing: 25,
                                    itemWidth: 170,
                                    itemHeight: 18,
                                    itemDirection: "left-to-right",
                                    itemTextColor: "#999",
                                    itemOpacity: 1,
                                    symbolSize: 16,
                                    symbolShape: "circle",
                                    effects: [
                                        {
                                            on: "hover",
                                            style: {
                                                itemTextColor: "#000",
                                            },
                                        },
                                    ],
                                },
                            ]}
                            tooltip={(d) => (
                                <div
                                    style={{
                                        background: "white",
                                        padding: "5px 10px",
                                        border: "1px solid #ccc",
                                    }}
                                >
                                    <strong>{d.indexValue}</strong>
                                    <br />
                                    {d.value} ha
                                </div>
                            )}
                        />
                    </div>
                </ChartContainer>
            </ChartsRow>
        </div>
    );

    return (
        <>
            {renderCategory("Indicadores de Área", charts, chartsRef)}
            {renderCategory(
                "Indicadores Varios",
                <CardsGrid>
                    {variosCards.map((card, index) => (
                        <DataCard key={index} title={card.title}>
                            {card.value}
                        </DataCard>
                    ))}
                </CardsGrid>
            )}
        </>
    );
};
