import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useMemo
} from 'react';
import ReactDOM from 'react-dom';
import {
    Box,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    IconButton,
    Grid,
    Typography,
    Card,
    CardHeader,
    CardContent,
    CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ResponsiveBar } from '@nivo/bar';
import html2canvas from 'html2canvas';


const allNumeric = (arr) => arr.every((item) => !isNaN(parseFloat(item)));

const sortValues = (arr) => {
    if (!arr || arr.length === 0) return arr;
    if (allNumeric(arr)) {
        return arr
            .map((v) => parseFloat(v))
            .sort((a, b) => a - b)
            .map((v) => String(v));
    } else {
        return arr.sort((a, b) => String(a).localeCompare(String(b)));
    }
};

const transformAndSortChartData = (chartData) => {
    if (!chartData || chartData.length === 0) return [];
    const transformed = chartData.map((item) => ({
        label:
            item.x !== undefined && item.x !== null ? String(item.x) : 'Sin valor',
        value:
            typeof item.value === 'number'
                ? parseFloat(item.value.toFixed(3))
                : item.value
    }));
    const labels = transformed.map((obj) => obj.label);
    const numericLabels = allNumeric(labels);

    if (numericLabels) {
        return transformed.sort((a, b) => parseFloat(a.label) - parseFloat(b.label));
    } else {
        return transformed.sort((a, b) => a.label.localeCompare(b.label));
    }
};


const customTheme = {
    fontSize: 16,
    axis: {
        domain: {
            line: {
                stroke: '#000',
                strokeWidth: 1
            }
        },
        ticks: {
            line: {
                stroke: '#000',
                strokeWidth: 1
            },
            text: {
                fontSize: 16,
                fill: '#000'
            }
        },
        legend: {
            text: {
                fontSize: 18,
                fill: '#000'
            }
        }
    },
    labels: {
        text: {
            fontSize: 16,
            fill: '#000'
        }
    },
    legends: {
        text: {
            fontSize: 16,
            fill: '#000'
        }
    },
    tooltip: {
        container: {
            fontSize: '16px'
        }
    }
};

const BarLabelWithRotation = ({ bars }) => {
    return (
        <>
            {bars.map((bar) => {
                const label = bar.data.value;
                const x = bar.x + bar.width / 2;
                const y = bar.y - 6;

                return (
                    <text
                        key={bar.key}
                        x={x}
                        y={y}
                        textAnchor="middle"
                        dominantBaseline="central"
                        // Rotamos -40° alrededor del punto (x,y)
                        transform={`rotate(-40, ${x}, ${y})`}
                        style={{
                            fill: '#000',
                            fontSize: 12,
                            pointerEvents: 'none'
                        }}
                    >
                        {label}
                    </text>
                );
            })}
        </>
    );
};

const ChartTemplates = forwardRef(({
                                       availableProps,
                                       chartsConfig,
                                       analysisData,
                                       onChangeChartsConfig,
                                       onRemoveChart,
                                       onAnalyzeOneChart,
                                       distinctValuesMap,
                                       chartLoadingStates,
                                       harvestGeojson
                                   }, ref) => {

    const chartsDataRef = useRef([]);

    const randomColorsRef = useRef({});

    const getRandomColor = (label) => {
        if (!randomColorsRef.current[label]) {
            const color = '#' + Math.floor(Math.random() * 16777215).toString(16);
            randomColorsRef.current[label] = color;
        }
        return randomColorsRef.current[label];
    };

    useImperativeHandle(ref, () => ({
        exportAllChartsAsImages: async () => {
            if (!chartsDataRef.current || chartsDataRef.current.length === 0) {
                console.error("No hay gráficas disponibles para exportar.");
                return [];
            }

            const images = [];

            for (let i = 0; i < chartsDataRef.current.length; i++) {
                const { data, title, xAxis } = chartsDataRef.current[i];

                try {
                    const ephemeralContainer = document.createElement('div');
                    ephemeralContainer.style.width = '800px';
                    ephemeralContainer.style.height = '600px';
                    ephemeralContainer.style.position = 'absolute';
                    ephemeralContainer.style.top = '-9999px';
                    ephemeralContainer.style.left = '-9999px';
                    document.body.appendChild(ephemeralContainer);

                    const { aggregatorColorMap, getBarColor } =
                        buildColorLogic(harvestGeojson, xAxis, getRandomColor);

                    ReactDOM.render(
                        <div style={{ width: '100%', height: '100%' }}>
                            <Typography
                                variant="subtitle1"
                                align="center"
                                style={{ marginBottom: 8 }}
                            >
                                {title}
                            </Typography>

                            <ResponsiveBar
                                data={data}
                                keys={['value']}
                                indexBy="label"
                                margin={{ top: 50, right: 50, bottom: 80, left: 60 }}
                                padding={0.4}
                                valueScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                                indexScale={{ type: 'band', round: true }}
                                colors={bar => getBarColor(bar.data.label)}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: -40,
                                    legend: 'Eje X',
                                    legendPosition: 'middle',
                                    legendOffset: 60
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Eje Y',
                                    legendPosition: 'middle',
                                    legendOffset: -50
                                }}
                                theme={customTheme}
                                enableLabel={false}
                                layers={[
                                    'grid',
                                    'axes',
                                    'bars',
                                    BarLabelWithRotation,
                                    'markers',
                                    'legends',
                                    'annotations'
                                ]}
                                animate={true}
                            />
                        </div>,
                        ephemeralContainer
                    );

                    await new Promise((resolve) => setTimeout(resolve, 500));

                    const canvas = await html2canvas(ephemeralContainer, {
                        scale: window.devicePixelRatio * 3,
                        useCORS: true
                    });

                    images.push(canvas.toDataURL('image/png'));

                    ReactDOM.unmountComponentAtNode(ephemeralContainer);
                    document.body.removeChild(ephemeralContainer);
                } catch (error) {
                    console.error(`Error al capturar la gráfica ${i}:`, error);
                }
            }

            return images;
        }
    }));

    const handleConfigChange = (index, field, value) => {
        onChangeChartsConfig((prev) => {
            const updated = [...prev];
            updated[index] = { ...updated[index], [field]: value };
            return updated;
        });
    };

    const toggleEditMode = (index, forceValue) => {
        onChangeChartsConfig((prev) => {
            const updated = [...prev];
            updated[index].editMode =
                typeof forceValue === 'boolean'
                    ? forceValue
                    : !updated[index].editMode;
            return updated;
        });
    };

    const handleDynamicFilterChange = (index, newFilterValue) => {
        onChangeChartsConfig((prev) => {
            const updated = [...prev];
            updated[index].filterValue = newFilterValue;
            return updated;
        });
        onAnalyzeOneChart(index);
    };

    const buildColorLogic = (geojson, xAxisProp, fallbackRandomColorFn) => {
        const normalized = xAxisProp
            ? xAxisProp.toLowerCase().replace(/\s+/g, '_')
            : '';

        const aggregatorKey = 'agrupacion_' + normalized;
        const colorKey = 'color_' + normalized;

        const aggregatorColorMap = {};
        if (geojson?.features && normalized) {
            geojson.features.forEach((feature) => {
                const props = feature.properties || {};
                const agValue = props[aggregatorKey];
                const colValue = props[colorKey];
                if (agValue && colValue) {
                    if (!aggregatorColorMap[agValue]) {
                        aggregatorColorMap[agValue] = colValue;
                    }
                }
            });
        }

        const getBarColor = (label) => {
            if (aggregatorColorMap[label]) {
                return aggregatorColorMap[label];
            }
            return fallbackRandomColorFn(label);
        };

        return { aggregatorColorMap, getBarColor };
    };

    const renderBarChart = (data, title, chartIndex, currentChartConfig) => {
        const computedWidth = Math.max(600, data.length * 100);

        chartsDataRef.current[chartIndex] = {
            data,
            title,
            xAxis: currentChartConfig?.xAxis || ''
        };

        const { aggregatorColorMap, getBarColor } =
            buildColorLogic(harvestGeojson, currentChartConfig?.xAxis, getRandomColor);

        return (
            <Box
                sx={{
                    backgroundColor: '#f9f9f9',
                    borderRadius: 2,
                    p: 2,
                    mb: 2
                }}
            >
                <Typography variant="subtitle1" align="center" sx={{ mb: 1 }}>
                    {title}
                </Typography>
                <Box sx={{ overflowX: 'auto' }}>
                    <Box
                        sx={{
                            width: computedWidth,
                            height: 400
                        }}
                    >
                        <ResponsiveBar
                            data={data}
                            keys={['value']}
                            indexBy="label"
                            margin={{ top: 30, right: 50, bottom: 80, left: 60 }}
                            padding={0.4}
                            valueScale={{ type: 'linear', min: 'auto', max: 'auto' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={(bar) => getBarColor(bar.data.label)}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: -40,
                                legend: 'Eje X',
                                legendPosition: 'middle',
                                legendOffset: 60
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Eje Y',
                                legendPosition: 'middle',
                                legendOffset: -50
                            }}
                            theme={customTheme}
                            enableLabel={false}
                            layers={[
                                'grid',
                                'axes',
                                'bars',
                                BarLabelWithRotation,
                                'markers',
                                'legends',
                                'annotations'
                            ]}
                            animate={true}
                        />
                    </Box>
                </Box>
            </Box>
        );
    };

    const handleAddChart = () => {
        onChangeChartsConfig((prev) => [
            ...prev,
            {
                xAxis: '',
                yAxis: '',
                filterProp: '',
                filterValue: '',
                calcType: 'average',
                editMode: true
            }
        ]);
    };

    return (
        <Box
            sx={{
                p: 2,
                borderRadius: 2,
                width: '100%',
                backgroundColor: '#fafafa',
                mb: 3
            }}
        >
            <Typography variant="h5" gutterBottom>
                Plantillas de gráficas
            </Typography>

            <Grid container spacing={2}>
                {chartsConfig.map((chart, idx) => {
                    const currentAnalysis = analysisData[idx] || null;
                    const opLabel = chart.calcType === 'sum' ? 'Suma' : 'Promedio';
                    const filterLabel = chart.filterProp
                        ? `Filtrado por ${chart.filterProp} = ${chart.filterValue || '(cualquiera)'}`
                        : '';
                    const chartTitle =
                        chart.xAxis && chart.yAxis
                            ? `${chart.xAxis} vs. ${chart.yAxis} (${opLabel}) ${filterLabel}`
                            : 'Gráfica sin configurar';

                    let possibleFilterValues = chart.filterProp
                        ? distinctValuesMap[chart.filterProp] || []
                        : [];
                    possibleFilterValues = sortValues(possibleFilterValues);

                    const isLoading = !!chartLoadingStates[idx];

                    return (
                        <Grid item xs={12} sm={6} md={4} key={idx}>
                            <Card sx={{ position: 'relative' }}>
                                <CardHeader
                                    title={`Gráfica #${idx + 1}`}
                                    action={
                                        <IconButton color="error" onClick={() => onRemoveChart(idx)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                />
                                <CardContent>
                                    {chart.editMode ? (
                                        <Box sx={{ mb: 2 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel>Eje X</InputLabel>
                                                        <Select
                                                            label="Eje X"
                                                            value={chart.xAxis}
                                                            onChange={(e) =>
                                                                handleConfigChange(idx, 'xAxis', e.target.value)
                                                            }
                                                        >
                                                            <MenuItem value="">(Seleccione)</MenuItem>
                                                            {availableProps.map((prop) => (
                                                                <MenuItem key={prop} value={prop}>
                                                                    {prop}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel>Eje Y</InputLabel>
                                                        <Select
                                                            label="Eje Y"
                                                            value={chart.yAxis}
                                                            onChange={(e) =>
                                                                handleConfigChange(idx, 'yAxis', e.target.value)
                                                            }
                                                        >
                                                            <MenuItem value="">(Seleccione)</MenuItem>
                                                            {availableProps.map((prop) => (
                                                                <MenuItem key={prop} value={prop}>
                                                                    {prop}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel>Filtrar por (propiedad)</InputLabel>
                                                        <Select
                                                            label="Filtrar por (propiedad)"
                                                            value={chart.filterProp}
                                                            onChange={(e) => {
                                                                handleConfigChange(idx, 'filterProp', e.target.value);
                                                                handleConfigChange(idx, 'filterValue', '');
                                                            }}
                                                        >
                                                            <MenuItem value="">(Sin filtro)</MenuItem>
                                                            {availableProps.map((prop) => (
                                                                <MenuItem key={prop} value={prop}>
                                                                    {prop}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel>Operación</InputLabel>
                                                        <Select
                                                            label="Operación"
                                                            value={chart.calcType || 'average'}
                                                            onChange={(e) =>
                                                                handleConfigChange(idx, 'calcType', e.target.value)
                                                            }
                                                        >
                                                            <MenuItem value="average">Promedio</MenuItem>
                                                            <MenuItem value="sum">Suma</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            <Box sx={{ textAlign: 'right', mt: 2 }}>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => {
                                                        toggleEditMode(idx, false);
                                                        onAnalyzeOneChart(idx);
                                                    }}
                                                >
                                                    Guardar
                                                </Button>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <>
                                            {chart.filterProp && chart.filterProp !== '' && (
                                                <Box sx={{ mb: 2 }}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel>{`Filtrar por ${chart.filterProp}`}</InputLabel>
                                                        <Select
                                                            label={`Filtrar por ${chart.filterProp}`}
                                                            value={chart.filterValue || ''}
                                                            onChange={(e) =>
                                                                handleDynamicFilterChange(idx, e.target.value)
                                                            }
                                                        >
                                                            <MenuItem value="">
                                                                (Cualquiera)
                                                            </MenuItem>
                                                            {possibleFilterValues.map((val) => (
                                                                <MenuItem key={val} value={val}>
                                                                    {val}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            )}

                                            {isLoading ? (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        height: 300
                                                    }}
                                                >
                                                    <CircularProgress />
                                                </Box>
                                            ) : currentAnalysis && currentAnalysis.chartData ? (
                                                renderBarChart(
                                                    transformAndSortChartData(currentAnalysis.chartData),
                                                    chartTitle,
                                                    idx,
                                                    chart
                                                )
                                            ) : (
                                                <Typography variant="body2">
                                                    No hay datos para esta gráfica
                                                </Typography>
                                            )}

                                            <Box sx={{ textAlign: 'right' }}>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => toggleEditMode(idx, true)}
                                                >
                                                    Editar
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>

            <Box sx={{ textAlign: 'right', mt: 2 }}>
                <Button variant="contained" onClick={handleAddChart}>
                    AGREGAR OTRA GRÁFICA
                </Button>
            </Box>
        </Box>
    );
});

export default ChartTemplates;
