// analysis/aps/apsDataCards.js
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import DataCard from "../../components/CardData/DataCard";
import { displayValue } from "../../utils/generalUtils";
import { styled } from '@mui/system';

const CategoryContainer = styled('div')({
    marginTop: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    width: '100%',
});

const CategoryTitle = styled('h3')({
    fontSize: '1.2em',
    fontWeight: '600',
    color: '#fff',
    backgroundColor: '#1565C0',
    padding: '10px',
    borderRadius: '4px',
    marginBottom: '20px',
    textAlign: 'center',
    textTransform: 'uppercase'
});

const CardsRow = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px,1fr))',
    gap: '20px',
    width: '100%',
});

function renderCategory(categoryTitle, cards, onAreasCalculatedLoader) {
    return (
        <CategoryContainer>
            <CategoryTitle>{categoryTitle}</CategoryTitle>
            <CardsRow>
                {cards.map((card, index) => (
                    <DataCard key={index} title={card.title}>
                        {onAreasCalculatedLoader === false &&
                        (card.title.includes("Área") ||
                            card.title.includes("Porcentaje") ||
                            card.title.includes("Dosis Real Mezcla") ||
                            card.title.includes("Diferencia dosis teórica y real"))
                            ? <CircularProgress />
                            : card.value
                        }
                    </DataCard>
                ))}
            </CardsRow>
        </CategoryContainer>
    );
}


export const renderApsDataCards = (validations, datosAnalisis, indicadores, onAreasCalculatedLoader) => {

    const areaCards = [
        { title: "Área aplicada", value: indicadores.areaAplicada ? `${displayValue(indicadores.areaAplicada)} ha` : "No hay datos" },
        { title: "Área no aplicada", value: indicadores.areaNoAplicada ? `${displayValue(indicadores.areaNoAplicada)} ha` : "No hay datos" },
        { title: "Área sobre aplicada", value: indicadores.areaSobreAplicada ? `${displayValue(indicadores.areaSobreAplicada)} ha` : "No hay datos" },
        { title: "Área aplicada en calles", value: indicadores.areaSobreCalles ? `${displayValue(indicadores.areaSobreCalles)} ha` : "No hay datos" },
        { title: "Área aplicada fuera del lote", value: indicadores.areaFueraDeLote ? `${displayValue(indicadores.areaFueraDeLote)} ha` : "No hay datos" },
        { title: "Porcentaje de Variación", value: indicadores.porcentajeVariacion ? `${displayValue(indicadores.porcentajeVariacion)}` : "No hay datos" },
    ];

    const dosisCards = [
        { title: "Dosis Teórica", value: datosAnalisis.dosisTeorica?.valor ? `${displayValue(datosAnalisis.dosisTeorica?.valor)} L/ha` : "No hay datos" },
        { title: "Volumen Total Mezcla", value: datosAnalisis.dosisReal?.valor ? `${displayValue(datosAnalisis.dosisReal?.valor)}` : "No hay datos" },
        { title: "Volumen Promedio Mezcla", value: datosAnalisis.volumenPromedio?.valor ? `${displayValue(datosAnalisis.volumenPromedio?.valor)}` : "No hay datos" },
        { title: "Dosis Real Mezcla", value: indicadores.dosisTotalRealAplicada ? `${displayValue(indicadores.dosisTotalRealAplicada)} L/ha` : "No hay datos" },
        { title: "Diferencia dosis teórica y real", value:  `- L/ha`},
    ];

    const variosCards = [
        { title: "Altura", value: datosAnalisis.alturaAps?.valor ? `${displayValue(datosAnalisis.alturaAps?.valor)}` : "No hay datos" },
        {
            title: "Velocidad",
            value: parseFloat(indicadores.promedioVelocidad) === 0
                ? (
                    datosAnalisis.velocidadAps?.valor
                        ? `${displayValue(datosAnalisis.velocidadAps?.valor)}`
                        : "No hay datos"
                )
                : (
                    (indicadores.promedioVelocidad || datosAnalisis.velocidadAps?.valor)
                        ? `${displayValue(indicadores.promedioVelocidad || datosAnalisis.velocidadAps?.valor)}`
                        : "No hay datos"
                )
        },        { title: "humedad del cultivo", value: datosAnalisis.humedadDelCultivo?.valor ? `${displayValue(datosAnalisis.humedadDelCultivo?.valor)} %` : "No hay datos" },
        { title: "TCH", value: datosAnalisis.tchEstimado?.valor ? `${displayValue(datosAnalisis.tchEstimado?.valor)}/ha` : "No hay datos" },
        { title: "Tiempo total", value: datosAnalisis.tiempoTotal?.valor ? `${displayValue(datosAnalisis.tiempoTotal?.valor)} h` : "No hay datos" },
    ];


    return (
        <>
            {renderCategory("Indicadores de Área", areaCards, onAreasCalculatedLoader)}
            {renderCategory("Indicadores de Dosis", dosisCards, onAreasCalculatedLoader)}
            {renderCategory("Indicadores Varios", variosCards, onAreasCalculatedLoader)}
        </>
    );
};
