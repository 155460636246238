import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useRef
} from 'react';
import {
    DataSectionContainer,
    TableContainer,
    TableHeader,
    StyledTable,
    TableCell,
    TableHeaderCell,
} from './DataSectionStyle';

import { Box, Typography } from '@mui/material';
import axios from 'axios';

import { processApsData } from "../../../analysis/aps/apsFetchData";
import { validateApsData } from "../../../analysis/aps/apsValidations";
import { renderApsDataCards } from "../../../analysis/aps/apsDataCards";
import { processCosechaMecanicaData } from "../../../analysis/cosechaMecanica/cosechaMecanicaFetchData";
import { validateCosechaMecanicaData } from "../../../analysis/cosechaMecanica/cosechaMecanicaValidations";
import { validateFertilizationData } from "../../../analysis/fertilizacion/fertilizacionValidations";
import { renderCosechaMecanicaDataCards } from "../../../analysis/cosechaMecanica/cosechaMecanicaDataCards";
import { validateConteoPalmasData } from "../../../analysis/conteoPalmas/conteoPalmasValidation";
import { renderConteoPalmasDataCards } from "../../../analysis/conteoPalmas/conteoPalmasDataCards";
import { validateConteoAgavesData } from "../../../analysis/conteoAgave/conteoAgaveValidation";
import { renderConteoAgaveDataCards } from "../../../analysis/conteoAgave/conteoAgaveDataCards";
import { validateConteoAgavesData as validateConteoAgavesData2 } from "../../../analysis/conteoAgave/conteoAgaveValidation";
import { processConteoAgavesData } from "../../../analysis/conteoAgave/conteoAgaveFetch";
import { processConteoPalmasData } from "../../../analysis/conteoPalmas/conteoPalmasFetch";

import { displayValue } from "../../../utils/generalUtils";
import { API_BASE_PYTHON_SERVICE } from "../../../utils/config";

import ChartTemplates from "../../../components/ChartTemplates";
import { processFertilizacionData } from "../../../analysis/fertilizacion/fertilizacionFetchData";
import { renderFertilizacionDataCards } from "../../../analysis/fertilizacion/fertilizacionDataCards";

// Importar html2canvas para capturar imágenes de elementos individuales
import html2canvas from "html2canvas";

const DataSection = forwardRef(({
                                    selectedAnalysisType,
                                    promedioAltura,
                                    areaSobreAplicada,
                                    areaAplicada,
                                    areaFueraDeLote,
                                    areaSobreCalles,
                                    porcentajeVariacion,
                                    promedioVelocidad,
                                    areaBrutaCm,
                                    eficienciaCm,
                                    promedioVelocidadCm,
                                    porcentajeAreaPilotoCm,
                                    porcentajeAreaAutoTrackerCm,
                                    porcentajeModoCortadorBaseCm,
                                    esValorValido,
                                    setIndicadores,
                                    datosAnalisis,
                                    conteoObjetos,
                                    areaNoAplicada,
                                    onAreasCalculatedLoader,
                                    dosisTotalRealAplicada,
                                    diferenciaDeAreaApsKml,
                                    selectedSidebarOption,
                                    polygonsHarvestPlanning,
                                    areaTotalLote
                                }, ref) => {

    const [harvestGeojson, setHarvestGeojson] = useState(null);
    const [loadingGeojson, setLoadingGeojson] = useState(false);

    const [chartLoading, setChartLoading] = useState({});
    const [chartsConfig, setChartsConfig] = useState([]);
    const [analysisData, setAnalysisData] = useState([]);
    const [availableProps, setAvailableProps] = useState([]);
    const [distinctValuesMap, setDistinctValuesMap] = useState({});

    // Ref para las gráficas de Planificación de cosecha (si corresponde)
    const chartTemplatesRef = useRef(null);
    // Nuevo ref para las gráficas de fertilización
    const fertilizacionChartsRef = useRef(null);

    useImperativeHandle(ref, () => ({
        exportAllChartsAsImages: async () => {
            const images = [];
            // Exportar imágenes desde ChartTemplates (para planificación de cosecha u otros)
            if (chartTemplatesRef.current && typeof chartTemplatesRef.current.exportAllChartsAsImages === "function") {
                const templateImages = await chartTemplatesRef.current.exportAllChartsAsImages();
                images.push(...templateImages);
            }
            // Exportar cada gráfico individual de fertilización
            if (fertilizacionChartsRef.current) {
                // Se buscan los elementos que tengan las clases asignadas en fertilizacionDataCards
                const chartElements = fertilizacionChartsRef.current.querySelectorAll('.fertPieChart, .fertBarChart');
                for (const element of chartElements) {
                    const canvas = await html2canvas(element);
                    const image = canvas.toDataURL("image/png");
                    images.push(image);
                }
            }
            return images;
        }
    }));

    // Manejo de la Planificación de cosecha
    useEffect(() => {
        if (selectedSidebarOption !== "Planificación de cosecha") return;
        if (!polygonsHarvestPlanning) return;

        if (typeof polygonsHarvestPlanning === "string") {
            setLoadingGeojson(true);
            fetch(polygonsHarvestPlanning)
                .then(res => res.json())
                .then(data => {
                    setHarvestGeojson(data);
                })
                .catch(err => {
                    console.error("Error al obtener el GeoJSON de planif. cosecha:", err);
                })
                .finally(() => setLoadingGeojson(false));
        } else if (polygonsHarvestPlanning.type === "FeatureCollection") {
            setHarvestGeojson(polygonsHarvestPlanning);
        }
        setIndicadores({
            analisis: selectedSidebarOption,
        });
    }, [selectedSidebarOption, polygonsHarvestPlanning, setIndicadores]);

    useEffect(() => {
        if (!harvestGeojson?.features?.length) return;
        const props = Object.keys(harvestGeojson.features[0].properties);
        setAvailableProps(props);
    }, [harvestGeojson]);

    useEffect(() => {
        if (!harvestGeojson?.features?.length) return;

        const tempMap = {};
        const features = harvestGeojson.features;

        for (const feature of features) {
            const fProps = feature.properties;
            for (const key of Object.keys(fProps)) {
                if (!tempMap[key]) {
                    tempMap[key] = new Set();
                }
                tempMap[key].add(fProps[key]);
            }
        }

        // Convertir Sets a Arrays
        const finalMap = {};
        for (const k of Object.keys(tempMap)) {
            finalMap[k] = Array.from(tempMap[k]);
        }
        setDistinctValuesMap(finalMap);
    }, [harvestGeojson]);

    // Analizar una sola gráfica (Planificación de cosecha)
    const handleAnalyzeOneChart = async (index) => {
        if (!harvestGeojson) {
            console.warn("No se tiene el GeoJSON");
            return;
        }
        try {
            setChartLoading(prev => ({ ...prev, [index]: true }));

            const singleChart = chartsConfig[index];
            const payload = {
                geojson: harvestGeojson,
                charts: [singleChart],
            };

            const resp = await axios.post(
                `${API_BASE_PYTHON_SERVICE}mapping/chart_analysis`,
                payload
            );
            if (resp.data.status === "success") {
                const newResult = resp.data.data[0];
                setAnalysisData(prev => {
                    const updated = [...prev];
                    updated[index] = newResult;
                    return updated;
                });
                setChartsConfig(prev => {
                    const updated = [...prev];
                    updated[index].editMode = false;
                    return updated;
                });
            } else {
                console.error("Error en chart_analysis individual:", resp.data.message);
            }
        } catch (error) {
            console.error("Error /chart_analysis individual:", error);
        } finally {
            setChartLoading(prev => ({ ...prev, [index]: false }));
        }
    };

    const handleRemoveChart = (index) => {
        setChartsConfig(prev => prev.filter((_, i) => i !== index));
        setAnalysisData(prev => prev.filter((_, i) => i !== index));

        setChartLoading(prev => {
            const updated = { ...prev };
            delete updated[index];
            return updated;
        });
    };

    // Se procesan los datos del análisis seleccionado
    useEffect(() => {
        if (datosAnalisis && Object.keys(datosAnalisis).length > 0) {
            let datos = {};
            switch (selectedAnalysisType) {
                case 'APLICACIONES_AEREAS':
                    validateApsData(datosAnalisis);
                    datos = processApsData(datosAnalisis, {
                        promedioAltura,
                        areaSobreAplicada,
                        areaAplicada,
                        areaNoAplicada,
                        areaFueraDeLote,
                        areaSobreCalles,
                        porcentajeVariacion,
                        promedioVelocidad,
                        diferenciaDeAreaApsKml,
                        dosisTotalRealAplicada
                    });
                    break;
                case 'COSECHA_MECANICA':
                    validateCosechaMecanicaData(datosAnalisis);
                    datos = processCosechaMecanicaData(datosAnalisis, {
                        areaBrutaCm,
                        eficienciaCm,
                        promedioVelocidadCm,
                        porcentajeAreaPilotoCm,
                        porcentajeAreaAutoTrackerCm,
                        porcentajeModoCortadorBaseCm,
                    });
                    break;
                case 'CONTEO_PALMA':
                    validateConteoPalmasData(datosAnalisis);
                    datos = processConteoPalmasData(datosAnalisis, {});
                    break;
                case 'CONTEO_AGAVE':
                    validateConteoAgavesData(datosAnalisis);
                    datos = processConteoAgavesData(datosAnalisis, {});
                    break;
                case 'FERTILIZACION':
                    validateFertilizationData(datosAnalisis);
                    datos = processFertilizacionData(datosAnalisis, {
                        promedioAltura,
                        areaSobreAplicada,
                        areaAplicada,
                        areaTotalLote,
                        areaNoAplicada,
                        areaFueraDeLote,
                        areaSobreCalles,
                        porcentajeVariacion,
                        promedioVelocidad,
                        diferenciaDeAreaApsKml,
                        dosisTotalRealAplicada
                    });
                    break;
                default:
                    datos = {};
                    break;
            }
            setIndicadores(datos);
        }
    }, [
        selectedAnalysisType,
        datosAnalisis,
        promedioAltura,
        areaSobreAplicada,
        areaAplicada,
        areaTotalLote,
        areaNoAplicada,
        areaFueraDeLote,
        areaSobreCalles,
        porcentajeVariacion,
        promedioVelocidad,
        areaBrutaCm,
        eficienciaCm,
        promedioVelocidadCm,
        porcentajeAreaPilotoCm,
        porcentajeAreaAutoTrackerCm,
        porcentajeModoCortadorBaseCm,
        diferenciaDeAreaApsKml,
        dosisTotalRealAplicada,
        setIndicadores
    ]);

    // Render para la vista de Planificación de cosecha
    if (selectedSidebarOption === "Planificación de cosecha") {
        return (
            <DataSectionContainer>
                <Typography variant="h4" gutterBottom>
                    Planificación de cosecha
                </Typography>

                {loadingGeojson && <p>Cargando GeoJSON...</p>}

                {harvestGeojson && (
                    <ChartTemplates
                        ref={chartTemplatesRef}
                        availableProps={availableProps}
                        chartsConfig={chartsConfig}
                        analysisData={analysisData}
                        onChangeChartsConfig={setChartsConfig}
                        onRemoveChart={handleRemoveChart}
                        onAnalyzeOneChart={handleAnalyzeOneChart}
                        distinctValuesMap={distinctValuesMap}
                        chartLoadingStates={chartLoading}
                        harvestGeojson={harvestGeojson}
                    />
                )}

                {!harvestGeojson && !loadingGeojson && (
                    <p>No hay datos de GeoJSON disponibles.</p>
                )}
            </DataSectionContainer>
        );
    }

    // Para cualquier otro análisis que no sea Planificación de cosecha
    const tableDetails = {
        responsable: displayValue(datosAnalisis?.nombreResponsable?.valor),
        fechaInicio: displayValue(datosAnalisis?.fechaInicio?.valor || datosAnalisis?.fecha?.valor),
        fechaFin: displayValue(datosAnalisis?.fechaFin?.valor),
        nombreFinca: displayValue(datosAnalisis?.nombreFinca?.valor),
        operador: displayValue(datosAnalisis?.nombreOperador?.valor || datosAnalisis?.operador?.valor),
        actividad: displayValue(datosAnalisis?.actividad?.valor),
    };

    return (
        <DataSectionContainer>
            {(selectedAnalysisType !== 'CONTEO_PALMA' &&
                selectedAnalysisType !== 'CONTEO_AGAVE' &&
                selectedSidebarOption !== "Planificación de cosecha") && (
                <TableContainer>
                    <TableHeader>Detalles</TableHeader>
                    <StyledTable>
                        <thead>
                        <tr>
                            <TableHeaderCell>Responsable</TableHeaderCell>
                            <TableHeaderCell>Fecha Inicio</TableHeaderCell>
                            <TableHeaderCell>Fecha Fin</TableHeaderCell>
                            <TableHeaderCell>Nombre Finca</TableHeaderCell>
                            <TableHeaderCell>Operador</TableHeaderCell>
                            <TableHeaderCell>Actividad</TableHeaderCell>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <TableCell>{tableDetails.responsable}</TableCell>
                            <TableCell>{tableDetails.fechaInicio}</TableCell>
                            <TableCell>{tableDetails.fechaFin}</TableCell>
                            <TableCell>{tableDetails.nombreFinca}</TableCell>
                            <TableCell>{tableDetails.operador}</TableCell>
                            <TableCell>{tableDetails.actividad}</TableCell>
                        </tr>
                        </tbody>
                    </StyledTable>
                </TableContainer>
            )}

            {/* Render de data-cards según el análisis */}
            {selectedAnalysisType === 'APLICACIONES_AEREAS' &&
                renderApsDataCards(
                    validateApsData(datosAnalisis),
                    datosAnalisis,
                    {
                        promedioAltura,
                        areaSobreAplicada,
                        areaAplicada,
                        areaNoAplicada,
                        areaFueraDeLote,
                        areaSobreCalles,
                        porcentajeVariacion,
                        promedioVelocidad,
                        diferenciaDeAreaApsKml,
                        dosisTotalRealAplicada
                    },
                    onAreasCalculatedLoader
                )}

            {selectedAnalysisType === 'COSECHA_MECANICA' &&
                renderCosechaMecanicaDataCards(
                    validateCosechaMecanicaData(datosAnalisis),
                    datosAnalisis,
                    {
                        areaBrutaCm,
                        eficienciaCm,
                        promedioVelocidadCm,
                        porcentajeAreaPilotoCm,
                        porcentajeAreaAutoTrackerCm,
                        porcentajeModoCortadorBaseCm,
                    }
                )}

            {selectedAnalysisType === 'FERTILIZACION' &&
                renderFertilizacionDataCards(
                    validateFertilizationData(datosAnalisis),
                    datosAnalisis,
                    {
                        promedioAltura,
                        areaSobreAplicada,
                        areaAplicada,
                        areaTotalLote,
                        areaNoAplicada,
                        areaFueraDeLote,
                        areaSobreCalles,
                        porcentajeVariacion,
                        promedioVelocidad,
                        diferenciaDeAreaApsKml,
                        dosisTotalRealAplicada
                    },
                    onAreasCalculatedLoader,
                    fertilizacionChartsRef
                )}

            {selectedAnalysisType === 'CONTEO_PALMA' &&
                renderConteoPalmasDataCards(
                    validateConteoPalmasData(datosAnalisis),
                    datosAnalisis,
                    { conteoObjetos }
                )}

            {selectedAnalysisType === 'CONTEO_AGAVE' &&
                renderConteoAgaveDataCards(
                    validateConteoAgavesData2(datosAnalisis),
                    datosAnalisis,
                    { conteoObjetos }
                )}
        </DataSectionContainer>
    );
});

export default DataSection;
