// PalmsCount.jsx

import React, { useEffect, useState } from 'react';
import CommonMap from "../components/CommonMap/CommonMap";

const PalmsCount = ({
                        imageUrl,
                        activeLotes,
                        polygonsData,
                        northWestCoords,
                        southEastCoords,
                        setImgLaflet,
                        mapRef,
                        polygonsLayerRef,
                        setLabelsAndColors,
                        onSelectLote,
                        onHoverLote,
                        setNorthWestCoords,
                        setSouthEastCoords,
                        setCoordinateSystem
                    }) => {
    const [imageData, setImageData] = useState(null);
    const [mapCenter, setMapCenter] = useState([0, 0]);
    const [zoom, setZoom] = useState(3);

    useEffect(() => {
        if (typeof imageUrl === 'string' && imageUrl.trim() !== '') {
            setImageData(imageUrl);
        }
    }, [imageUrl]);

    return (
        <CommonMap
            mapCenter={mapCenter}
            zoom={zoom}
            imageUrl={imageData}
            mapRef={mapRef}
            activeLotes={activeLotes}
            polygonsData={polygonsData}
            southEastCoords={southEastCoords}
            northWestCoords={northWestCoords}
            setImgLaflet={setImgLaflet}
            polygonsLayerRef={polygonsLayerRef}
            onSelectLote={onSelectLote}
            onHoverLote={onHoverLote}
            setNorthWestCoords={setNorthWestCoords}
            setSouthEastCoords={setSouthEastCoords}
            setCoordinateSystem={setCoordinateSystem}
        />
    );
};

export default PalmsCount;
