// src/utils/fileHandler.js
import axios from 'axios';
import pako from 'pako';
import Papa from 'papaparse';
import { API_BASE_URL } from "./config";
import { insertarUltimoAnalisis } from "./mapUtils";
import JSZip from 'jszip';

export const manejarSubidaArchivo = async (
    event,
    setTitleLoader,
    setOpenSnackbar,
    setUploadedCsvFileName,
    selectedAnalysisTypeRef,
    idAnalisisBash,
    nombreAnalisis,
    userId,
    setIdMax,
    setSelectedFile,
    setDatosMapeo,
    setProgress,
    setShowProgressBar,
    setErrors,
    setOpenErrorDialog
) => {
    if (!event.target.files || !event.target.files.length) {
        console.error("No se seleccionó ningún archivo");
        return;
    }

    const archivo = event.target.files[0];

    setTitleLoader("Subiendo Datos");
    setOpenSnackbar(true);
    setUploadedCsvFileName(archivo.name);
    setShowProgressBar(true);

    let archivoConvertido = archivo;

    try {
        const isExcel = archivo.name.endsWith('.xlsx') || archivo.name.endsWith('.xls');

        if (isExcel) {
            archivoConvertido = await convertirExcelACsv(archivo, setErrors, setOpenErrorDialog);
        } else if (!archivo.name.endsWith('.csv')) {
            console.error("Formato de archivo no permitido:", archivo.type);
            throw new Error(`El archivo no está entre los formatos permitidos: ${archivo.type}`);
        }
        const result = await insertarUltimoAnalisis(
            selectedAnalysisTypeRef,
            idAnalisisBash,
            nombreAnalisis(idAnalisisBash),
            userId
        );
        await procesarArchivoCsv(
            archivoConvertido,
            setIdMax,
            nombreAnalisis,
            result.data,
            setSelectedFile,
            setDatosMapeo,
            setProgress,
            setShowProgressBar,
            idAnalisisBash,
            setErrors,
            setOpenErrorDialog
        );

    } catch (error) {
        console.error("Error en manejarSubidaArchivo:", error);
        manejarErrorSubida(error, setShowProgressBar, setErrors, setOpenErrorDialog);
    }
};

const convertirExcelACsv = async (archivo, setErrors, setOpenErrorDialog) => {

    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
        fileReader.onload = async (event) => {

            const arrayBuffer = event.target.result;

            const compressed = pako.gzip(new Uint8Array(arrayBuffer));

            const formData = new FormData();
            const blob = new Blob([compressed], { type: 'application/octet-stream' });
            formData.append('excel', blob, 'archivo_comprimido.gz');

            try {
                const response = await axios.post(`${API_BASE_URL}dashboard/convertirExcelACsv/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    responseType: 'blob'
                });

                const archivoConvertido = new File([response.data], 'convertido.csv', { type: 'text/csv' });

                resolve(archivoConvertido);
            } catch (error) {
                console.error("Error al convertir Excel a CSV:", error);
                manejarErrorSubida(error, null, setErrors, setOpenErrorDialog);
                reject(error);
            }
        };

        fileReader.onerror = (error) => {
            console.error("Error al leer el archivo:", error);
            manejarErrorSubida(error, null, setErrors, setOpenErrorDialog);
            reject(error);
        };

        fileReader.readAsArrayBuffer(archivo);
    });
};

const procesarArchivoCsv = async (
    archivoConvertido,
    setIdMax,
    nombreAnalisis,
    ultimoAnalisis,
    setSelectedFile,
    setDatosMapeo,
    setProgress,
    setShowProgressBar,
    idAnalisisBash,
    setErrors,
    setOpenErrorDialog
) => {
    const CancelToken = axios.CancelToken;
    let cancel;

    const worker = new Worker('Workers/FileWorkerChunking.js');

    const chunkSize = 1024 * 1024;
    const totalChunks = Math.ceil(archivoConvertido.size / chunkSize);
    let accumulatedData = [];
    let processedChunks = 0;
    let allErrors = [];

    setIdMax(ultimoAnalisis.idAnalisis);
    const tipoAnalisis = nombreAnalisis(idAnalisisBash);

    if (
        tipoAnalisis !== 'COSECHA_MECANICA' &&
        tipoAnalisis !== 'APLICACIONES_AEREAS' &&
        tipoAnalisis !== 'FERTILIZACION'
    ) {
        setSelectedFile(archivoConvertido);
        setDatosMapeo(null);
        setProgress(100);
        setShowProgressBar(false);
        return;
    }

    const uploadChunk = async (chunk, chunkIndex, startingLineNumber, isLastChunk) => {
        const formData = new FormData();
        const blob = new Blob([chunk], { type: 'text/csv' });
        formData.append('csv', blob, `chunk-${chunkIndex}.csv`);
        formData.append('idTipoAnalisis', ultimoAnalisis.idAnalisis);
        formData.append('tipoAnalisis', tipoAnalisis);
        formData.append('indexChunk', chunkIndex);
        formData.append('startingLineNumber', startingLineNumber);
        formData.append('isLastChunk', isLastChunk);

        try {
            const response = await axios.post(`${API_BASE_URL}dashboard/procesarCsv/`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                }),
            });

            if (response.data.data && response.data.data.length > 0) {
                accumulatedData = accumulatedData.concat(response.data.data);
            }

            if (isLastChunk && response.data.errores && response.data.errores.length > 0) {
                allErrors = allErrors.concat(response.data.errores);
            }

            processedChunks++;

            const progressPercentage = Math.min(70, Math.floor((processedChunks / totalChunks) * 70));
            setProgress(progressPercentage);

            return response;
        } catch (error) {
            manejarErrorSubida(error, setShowProgressBar, setErrors, setOpenErrorDialog);
            throw error;
        }
    };

    const processChunksSequentially = async () => {
        for (let i = 0; i < totalChunks; i++) {
            const chunkData = await new Promise((resolve, reject) => {
                worker.onmessage = (e) => {
                    if (e.data.error) {
                        reject(e.data.error);
                    } else {
                        resolve(e.data);
                    }
                };
                worker.onerror = (e) => {
                    reject(e.message);
                };
                worker.postMessage({ file: archivoConvertido, chunkSize, chunkIndex: i, totalChunks });
            });

            const { chunk, chunkIndex, startingLineNumber } = chunkData;
            const isLastChunk = (chunkIndex === totalChunks - 1);

            try {
                await uploadChunk(chunk, chunkIndex, startingLineNumber, isLastChunk);
            } catch (error) {
                break;
            }

            const progressPercentage = Math.min(70, Math.floor((processedChunks / totalChunks) * 70));
            setProgress(progressPercentage);
        }
    };

    try {
        await processChunksSequentially();
        const csvBlob = new Blob([Papa.unparse(accumulatedData)], { type: 'text/csv' });
        const csvFile = new File([csvBlob], 'procesado.csv');

        setSelectedFile(csvFile);
        setDatosMapeo(accumulatedData);
        setProgress(100);
        setShowProgressBar(false);

        if (allErrors.length > 0) {
            manejarErrorSubida(
                { response: { data: { errores: allErrors } } },
                setShowProgressBar,
                setErrors,
                setOpenErrorDialog
            );
        }
    } catch (error) {
        console.error('Error en el procesamiento de CSV:', error);
    }
};



export const manejarErrorSubida = (
    error,
    setShowProgressBar,
    setErrors,
    setOpenErrorDialog
) => {
    console.error('Se produjo un error al intentar subir el archivo:', error);

    if (setShowProgressBar) {
        setShowProgressBar(false);
    }

    if (error.response) {
        const errores = error.response.data.errores;

        if (errores && errores.length > 0) {
            setErrors(errores);
            setOpenErrorDialog(true);
        } else {
            const mensaje = error.response.data.mensaje || 'Error en el servidor';
            alert(mensaje);
        }
    } else if (error.request) {
        console.error('No se recibió respuesta del servidor:', error.request);
        alert('Se produjo un error al enviar el archivo. No se recibió respuesta del servidor.');
    } else {
        console.error('Error al configurar la solicitud:', error.message);
        alert('Se produjo un error al procesar el archivo.');
    }

    console.error('Configuración de la solicitud:', error.config);
};

export const manejarSubidaZip = async (
    event,
    setSelectedZipFile,
    setUploadedZipFileName,
    setOpenSnackbar,
    setIsKMLFile
) => {
    const file = event.target.files[0];
    if (!file) return;

    // Guardamos la referencia del archivo en el estado
    setSelectedZipFile(file);

    // Guardamos el nombre del archivo y mostramos el snackbar
    setUploadedZipFileName(file.name);
    setOpenSnackbar(true);

    try {
        // Leer el archivo usando FileReader
        const fileReader = new FileReader();

        fileReader.onload = async (e) => {
            try {
                // Cargar con JSZip
                const zipInstance = new JSZip();
                const zipContent = await zipInstance.loadAsync(e.target.result);

                // Verificar si existe un .kml
                let foundKML = false;
                zipContent.forEach((relativePath, zipEntry) => {
                    if (zipEntry.name.toLowerCase().endsWith('.kml')) {
                        foundKML = true;
                    }
                });

                setIsKMLFile(foundKML);
            } catch (error) {
                console.error("Error al procesar el archivo ZIP con JSZip:", error);
                setIsKMLFile(false);
            }
        };

        fileReader.onerror = () => {
            console.error("Error al leer el archivo con FileReader.");
            setIsKMLFile(false);
        };

        // Iniciamos la lectura del archivo como ArrayBuffer
        fileReader.readAsArrayBuffer(file);

    } catch (error) {
        console.error("Error al procesar el archivo ZIP:", error);
        setIsKMLFile(false);
    }
};
