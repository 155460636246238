// src/analysis/aps/apsValidations.jsx
export const validateApsData = (datosAnalisis) => ({
    codigoEquipo: datosAnalisis.codigoEquipo?.valor && datosAnalisis.codigoEquipo.valor !== '',
    eficiencia: datosAnalisis.eficiencia?.valor && datosAnalisis.eficiencia.valor !== 0,
    codigoLote: datosAnalisis.codigoLote?.valor && datosAnalisis.codigoLote.valor !== '',
    dosisTeorica: datosAnalisis.dosisTeorica?.valor && datosAnalisis.dosisTeorica.valor !== 0,
    productoAps: datosAnalisis.productoAps?.valor && datosAnalisis.productoAps.valor !== '',
    humedadDelCultivo: datosAnalisis.humedadDelCultivo?.valor && datosAnalisis.humedadDelCultivo.valor !== 0,
    tchEstimado: datosAnalisis.tchEstimado?.valor && datosAnalisis.tchEstimado.valor !== 0,
    horaInicio: datosAnalisis.horaInicio?.valor && datosAnalisis.horaInicio.valor !== '',
    horaFin: datosAnalisis.horaFin?.valor && datosAnalisis.horaFin.valor !== '',
    tiempoTotal: datosAnalisis.tiempoTotal?.valor && datosAnalisis.tiempoTotal.valor !== 0,
    velocidadAps: datosAnalisis.velocidadAps?.valor && datosAnalisis.velocidadAps?.valor !== '',
    alturaAps: datosAnalisis.alturaAps?.valor && datosAnalisis.alturaAps?.valor !== '',
    volumenPromedio: datosAnalisis.volumenPromedio?.valor && datosAnalisis.volumenPromedio?.valor !== 0,
});
