// DataCardStyle.js

import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const DataCardContainer = styled(Box)(
    ({ smallVersion }) => ({
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        margin: '10px',
        padding: smallVersion ? '8px' : '20px',
        width: smallVersion ? '140px' : '220px',
        height: smallVersion ? '100px' : '150px',

        textAlign: 'center',
        transition: 'transform 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
        },
    })
);

export const DataCardTitle = styled('h3')(({ smallVersion }) => ({
    margin: 0,
    color: '#888',
    fontWeight: 500,
    fontSize: smallVersion ? '0.85em' : '1em',
}));

export const DataCardValue = styled(Box)(({ smallVersion, color }) => ({
    color: color || '#000',
    fontSize: smallVersion ? '1em' : '1.5em',
    marginTop: '10px',
}));

export const MinMaxContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

export const MinMaxValue = styled('div')(({ color }) => ({
    fontSize: '12px',
    color: color,
}));
