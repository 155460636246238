import React, { useState, useEffect } from 'react';
import Mapping from "../../Mapping/Mapping";
import AerialApplications from "../../Aplicaciones Areas/AerialApplications";
import CommonMap from "../../../components/CommonMap/CommonMap";
import PalmsCount from "../../../Mappings/PalmsCount";
import Fertilization from "../../Fertilization/fertilization";

const MapSection = ({
                        selectedFile,
                        selectedAnalysisType,
                        datosMapeo,
                        selectedZipFile,
                        processingFinished,
                        ultimoAnalisis,
                        nombreAnalisis,
                        idAnalisisBash,
                        activarEdicionInteractiva,
                        setAreaNetaCm,
                        setAreaBrutaCm,
                        setDiferenciaDeAreaCm,
                        setPorcentajeAreaPilotoCm,
                        setPorcentajeAreaAutoTrackerCm,
                        setPorcentajeModoCortadorBaseCm,
                        setEficienciaCm,
                        setAreaSobreAplicada,
                        setAreaAplicada,
                        setPorcentajeVariacion,
                        setAreaNoAplicada,
                        setAreaSobreCalles,
                        setAreaFueraDeLote,
                        setPromedioVelocidad,
                        setPromedioAltura,
                        activeLotes = [],
                        highlightedLote,
                        polygonsData = [],
                        onLeaveLote,
                        onSelectLote,
                        onHoverLote,
                        closeFilterDialog,
                        isFilterDialogOpen,
                        setImgLaflet,
                        imageUrl = '',
                        northWestCoords,
                        southEastCoords,
                        isAnalysisPerformed,
                        mapRef,
                        polygonsLayerRef,
                        setLabelsAndColors,
                        setOnAreasCalculatedLoader,
                        setDosisTotalRealAplicada,
                        setDiferenciaDeAreaApsKml,
                        selectedFiles,
                        setTipoMapeo,
                        activeTool,
                        isBufferActive,
                        bufferValue,
                        showUnfilteredLines,
                        setActiveTool,
                        handleToggleMostrarLineas,
                        mostrarLineas,
                        tramplingLines,
                        setTramplingLines,
                        setHarvestLinesBuffer,
                        polygonsHarvestPlanning,
                        setNorthWestCoords,
                        setSouthEastCoords,
                        setCoordinateSystem,
                        setAreaTotalLote
                    }) => {
    const [nombreAnalisisMapeo, setNombreAnalisisMapeo] = useState('');

    useEffect(() => {
        try {
            setNombreAnalisisMapeo(nombreAnalisis(idAnalisisBash));
        } catch (error) {
            console.error("Error en useEffect nombreAnalisisMapeo:", error);
        }
    }, [idAnalisisBash, nombreAnalisis]);

    const handleAreaCalculation = (
        areaDentroDePoligono,
        areaFueraDePoligono,
        diferenciaDeArea,
        areaSobreCalles,
        areaFueraDeLote,
        dosisTotalRealAplicada
    ) => {
        try {
            setAreaNetaCm(`${areaFueraDePoligono.toFixed(2)} H`);
            setAreaBrutaCm(`${areaDentroDePoligono.toFixed(2)} H`);
            setDiferenciaDeAreaCm(`${diferenciaDeArea.toFixed(2)} H`);
            setAreaSobreCalles(`${areaSobreCalles.toFixed(2)} H`);
            setAreaFueraDeLote(`${areaFueraDeLote.toFixed(2)} H`);
            setDosisTotalRealAplicada(`${dosisTotalRealAplicada.toFixed(2)} L/Ha`);
        } catch (error) {
            console.error("Error en handleAreaCalculation:", error);
        }
    };

    const handlePercentageCalculation = (autoTracket, autoPilot, modoCorteBase, totalEfficiency) => {
        try {
            setPorcentajeAreaPilotoCm(`${autoPilot.toFixed(2)}%`);
            setPorcentajeAreaAutoTrackerCm(`${autoTracket.toFixed(2)}%`);
            setPorcentajeModoCortadorBaseCm(`${modoCorteBase.toFixed(2)}%`);
            setEficienciaCm(`${totalEfficiency.toFixed(2)} Ha/Hora`);
        } catch (error) {
            console.error("Error en handlePercentageCalculation:", error);
        }
    };

    const handleAreasCalculated = (areas) => {
        try {
            setAreaSobreAplicada(areas.areaSobreAplicada || 0);
            setAreaAplicada(areas.areaAplicada || 0);
            setPorcentajeVariacion(`${areas.porcentajeDeVariacion || 0}%`);
            setAreaNoAplicada(areas.areaNoAplicada || 0);
            setAreaSobreCalles(areas.areaAplicadaEnCalles);
            setAreaFueraDeLote(areas.areaAplicadaFueraDelLote);
            setAreaTotalLote(areas.areaTotalLote);
            setDiferenciaDeAreaApsKml(areas.dosisTotalDiferencia || 0);
            setDosisTotalRealAplicada(areas.dosisTotalRealAplicada || 0);
        } catch (error) {
            console.error("Error en handleAreasCalculated:", error);
        }
    };

    const handlePromediosCalculados = (promedios) => {
        try {
            setPromedioVelocidad(promedios.promedioVelocidad || 0);
            setPromedioAltura(promedios.promedioAltura || 0);
        } catch (error) {
            console.error("Error en handlePromediosCalculados:", error);
        }
    };

    return (
        <section className="map-section">
            {isAnalysisPerformed && selectedFile && selectedAnalysisType === 'COSECHA_MECANICA' ? (
                <Mapping
                    csvData={datosMapeo}
                    zipFile={selectedZipFile}
                    progressFinish={processingFinished}
                    idAnalisis={ultimoAnalisis}
                    tipoAnalisis={nombreAnalisisMapeo}
                    onAreaCalculated={handleAreaCalculation}
                    percentageAutoPilot={handlePercentageCalculation}
                    activeLotes={activeLotes}
                    highlightedLote={highlightedLote}
                    polygonsData={polygonsData}
                    onLeaveLote={onLeaveLote}
                    onSelectLote={onSelectLote}
                    onHoverLote={onHoverLote}
                    closeFilterDialog={closeFilterDialog}
                    isFilterDialogOpen={isFilterDialogOpen}
                    setImgLaflet={setImgLaflet}
                    mapRef={mapRef}
                    polygonsLayerRef={polygonsLayerRef}
                    setLabelsAndColors={setLabelsAndColors}
                    setTipoMapeo={setTipoMapeo}
                    handleToggleMostrarLineas={handleToggleMostrarLineas}
                    mostrarLineas={mostrarLineas}
                    tramplingLines={tramplingLines}
                    setTramplingLines={setTramplingLines}
                    setHarvestLinesBuffer={setHarvestLinesBuffer}
                    activeTool={activeTool}
                    HarvestLines={true}
                    setNorthWestCoords={setNorthWestCoords}
                    setSouthEastCoords={setSouthEastCoords}
                    setCoordinateSystem={setCoordinateSystem}
                />
            ) : isAnalysisPerformed && selectedZipFile && selectedFile && selectedAnalysisType === 'APLICACIONES_AEREAS' ? (
                <AerialApplications
                    idAnalisis={ultimoAnalisis}
                    tipoAnalisis={nombreAnalisis(idAnalisisBash)}
                    onAreasCalculated={handleAreasCalculated}
                    onPromediosCalculated={handlePromediosCalculados}
                    activarEdicionInteractiva={activarEdicionInteractiva}
                    activeLotes={activeLotes}
                    highlightedLote={highlightedLote}
                    polygonsData={polygonsData}
                    onLeaveLote={onLeaveLote}
                    onSelectLote={onSelectLote}
                    onHoverLote={onHoverLote}
                    closeFilterDialog={closeFilterDialog}
                    isFilterDialogOpen={isFilterDialogOpen}
                    setImgLaflet={setImgLaflet}
                    mapRef={mapRef}
                    polygonsLayerRef={polygonsLayerRef}
                    setLabelsAndColors={setLabelsAndColors}
                    setOnAreasCalculatedLoader={setOnAreasCalculatedLoader}
                    setTipoMapeo={setTipoMapeo}
                    activeTool={activeTool}
                    isBufferActive={isBufferActive}
                    bufferValue={bufferValue}
                    showUnfilteredLines={showUnfilteredLines}
                    setActiveTool={setActiveTool}
                    setNorthWestCoords={setNorthWestCoords}
                    setSouthEastCoords={setSouthEastCoords}
                    setCoordinateSystem={setCoordinateSystem}
                />
            ) : isAnalysisPerformed && selectedFile && selectedZipFile && selectedAnalysisType === 'FERTILIZACION' ? (
                <Fertilization
                    csvData={datosMapeo}
                    zipFile={selectedZipFile}
                    onAreasCalculated={handleAreasCalculated}
                    progressFinish={processingFinished}
                    idAnalisis={ultimoAnalisis}
                    tipoAnalisis={nombreAnalisisMapeo}
                    activeLotes={activeLotes}
                    highlightedLote={highlightedLote}
                    polygonsData={polygonsData}
                    onLeaveLote={onLeaveLote}
                    onSelectLote={onSelectLote}
                    onHoverLote={onHoverLote}
                    closeFilterDialog={closeFilterDialog}
                    isFilterDialogOpen={isFilterDialogOpen}
                    setImgLaflet={setImgLaflet}
                    mapRef={mapRef}
                    polygonsLayerRef={polygonsLayerRef}
                    setLabelsAndColors={setLabelsAndColors}
                    setTipoMapeo={setTipoMapeo}
                    setNorthWestCoords={setNorthWestCoords}
                    setSouthEastCoords={setSouthEastCoords}
                    setCoordinateSystem={setCoordinateSystem}

                />
            ) : isAnalysisPerformed && selectedZipFile && (selectedAnalysisType === 'CONTEO_PALMA' || selectedAnalysisType === 'CONTEO_AGAVE') ? (
                <PalmsCount
                    imageUrl={imageUrl}
                    activeLotes={activeLotes}
                    polygonsData={polygonsData}
                    northWestCoords={northWestCoords}
                    southEastCoords={southEastCoords}
                    setImgLaflet={setImgLaflet}
                    onSelectLote={onSelectLote}
                    onHoverLote={onHoverLote}
                    mapRef={mapRef}
                    polygonsLayerRef={polygonsLayerRef}
                    setLabelsAndColors={setLabelsAndColors}
                    setNorthWestCoords={setNorthWestCoords}
                    setSouthEastCoords={setSouthEastCoords}
                    setCoordinateSystem={setCoordinateSystem}
                />
            ) : (
                <CommonMap
                    mapRef={mapRef}
                    activeLotes={activeLotes}
                    highlightedLote={highlightedLote}
                    polygonsData={polygonsData}
                    onLeaveLote={onLeaveLote}
                    onSelectLote={onSelectLote}
                    onHoverLote={onHoverLote}
                    setImgLaflet={setImgLaflet}
                    polygonsLayerRef={polygonsLayerRef}
                    selectedFiles={selectedFiles}
                    polygonsHarvestPlanning={polygonsHarvestPlanning}
                    closeFilterDialog={closeFilterDialog}
                    isFilterDialogOpen={isFilterDialogOpen}
                    setLabelsAndColors={setLabelsAndColors}
                    setTipoMapeo={setTipoMapeo}
                    setNorthWestCoords={setNorthWestCoords}
                    setSouthEastCoords={setSouthEastCoords}
                    setCoordinateSystem={setCoordinateSystem}
                />
            )}
        </section>
    );
};

export default MapSection;
