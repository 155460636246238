// DashboardIndicators.jsx
import React, {
    useContext,
    useEffect,
    useState,
    useCallback,
    useRef,
} from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import PersonIcon from '@mui/icons-material/Person';
import BuildIcon from '@mui/icons-material/Build';
import FlightIcon from '@mui/icons-material/Flight';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from 'axios';
import { toast } from 'react-toastify';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

import {
    DashboardContainer,
    ContentContainer,
    RightPanel,
    CardsContainer,
    ChartCardContainer,
    InProgressMessage,
    TooltipContainer,
    ModalContent,
    SectionContainer,
    SectionTitle,
    TotalAnalisisTab
} from './DashboardIndicatorsStyle';

import FilterToolbar from '../../components/FilterToolbar/FilterToolbar';
import ActivitiesComponent from '../../components/ActivitiesComponent/ActivitiesComponent';
import CardData from '../../components/CardData/DataCard';
import ChartCard from '../../components/ChartCard/ChartCard';
import NoDataMessage from '../../components/NoDataMessage/NoDataMessage';
import {
    API_BASE_URL_DASHBOARD,
    API_BASE_URL,
} from '../../utils/config';
import { SidebarContext } from '../../context/SidebarContext';
import analysisConfig from '../../utils/analysisConfig';
import Loader from '../../components/Loader/Loader';
import Modal from '@mui/material/Modal';
import Tutorial from '../../components/Tutorial/Tutorial';

const ITEM_TYPE_DATA_CARD = 'DATA_CARD';
const ITEM_TYPE_CHART_CARD = 'CHART_CARD';

const activitiesInProgress = ['HERBICIDAS', 'SIEMBRA'];

const DashboardIndicators = ({ isSidebarOpen }) => {
    const [filterOptions, setFilterOptions] = useState([]);
    /**
     * Guardamos todos los dataCards y chartCards en un solo array cada uno,
     * pero cada uno incluye su categoría. A la hora de reordenar, lo haremos
     * solo dentro de esa categoría.
     */
    const [dataCardsOrder, setDataCardsOrder] = useState([]);
    const [chartCardsOrder, setChartCardsOrder] = useState([]);
    const [loading, setLoading] = useState(true);

    // COSECHA_MECÁNICA
    const [promedioIndicadores, setPromedioIndicadores] = useState([]);
    const [porcentajes, setPorcentajes] = useState([]);
    const [metricasMaquina, setMetricasMaquina] = useState([]);
    const [porcentajesMaquina, setPorcentajesMaquina] = useState([]);
    const [metricasOperadorCM, setMetricasOperadorCM] = useState([]);
    const [porcentajesOperadorCM, setPorcentajesOperadorCM] = useState([]);
    const [metricasFinca, setMetricasFinca] = useState([]);
    const [porcentajesFinca, setPorcentajesFinca] = useState([]);

    // APLICACIONES_AEREAS
    const [tiempoTotal, setTiempoTotal] = useState([]);
    const [dosisTeoricaReal, setDosisTeoricaReal] = useState([]);
    const [totalesIndicadores, setTotalesIndicadores] = useState([]);
    const [totalesIndicadoresFinca, setTotalesIndicadoresFinca] = useState([]);
    const [totalesIndicadoresProducto, setTotalesIndicadoresProducto] = useState([]);
    const [metricasProducto, setMetricasProducto] = useState([]);

    // Operador y Zafra, Lote, Tercio (APLICACIONES_AEREAS)
    const [totalesIndicadoresOperador, setTotalesIndicadoresOperador] = useState([]);
    const [metricasOperador, setMetricasOperador] = useState([]);
    const [totalesIndicadoresZafra, setTotalesIndicadoresZafra] = useState([]);
    const [metricasZafra, setMetricasZafra] = useState([]);
    const [totalesIndicadoresLote, setTotalesIndicadoresLote] = useState([]);
    const [metricasLote, setMetricasLote] = useState([]);
    const [totalesIndicadoresTercio, setTotalesIndicadoresTercio] = useState([]);
    const [metricasTercio, setMetricasTercio] = useState([]);

    const [limites, setLimites] = useState({});
    const [isGeneratingReport, setIsGeneratingReport] = useState(false);

    const userData = JSON.parse(localStorage.getItem('userData'));
    const { selectedSidebarOption } = useContext(SidebarContext);

    const [selectedActivity, setSelectedActivity] = useState('COSECHA_MECANICA');
    const [shouldFetch, setShouldFetch] = useState(true);

    const [filtersCM, setFiltersCM] = useState({
        loteOptions: [],
        operadorOptions: [],
        fincasOptions: [],
        zafrasOptions: [],
        tercioOptions: [],
    });

    const [filtersAA, setFiltersAA] = useState({
        operadorOptionsAA: [],
        fincasOptionsAA: [],
        productosOptionsAA: [],
        zafraOptionsAA: [],
        loteOptionsAA: [],
        tercioOptionsAA: [],
    });

    const [filtersFR, setFiltersFR] = useState({
        operadorOptionsFR: [],
        fincaOptionsFR: [],
        productosOptionsFR: [],
        zafraOptionsFR: [],
        tercioOptionsFR: [],
    });

    const [filtersFetched, setFiltersFetched] = useState({
        COSECHA_MECANICA: false,
        APLICACIONES_AEREAS: false,
        FERTILIZACION: false,
    });

    const [selectedFilters, setSelectedFilters] = useState({});

    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
    });

    /**
     * Estilos para algunas tarjetas (ej: color, ícono).
     * Puedes agregar más si lo requieres.
     */
    const chartStyles = {
        'metricas-finca': {
            color: '#4caf50',
            icon: <AgricultureIcon />,
        },
        'porcentajes-finca': {
            colors: ['#81c784', '#4caf50', '#66bb6a'],
            icon: <AgricultureIcon />,
        },
        'metricas-operador': {
            color: '#ff9800',
            icon: <PersonIcon />,
        },
        'porcentajes-operador': {
            colors: ['#ffb74d', '#ffa726', '#fb8c00'],
            icon: <PersonIcon />,
        },
        'metricas-maquina': {
            color: '#2196f3',
            icon: <BuildIcon />,
        },
        'porcentajes-maquina': {
            colors: ['#64b5f6', '#42a5f5', '#1e88e5'],
            icon: <BuildIcon />,
        },
        'promedio-indicadores': {
            color: '#9c27b0',
            icon: <FlightIcon />,
        },
        'tiempo-total': {
            color: '#f44336',
            icon: <AccessTimeIcon />,
        },
        'teorica-real': {
            color: '#009688',
            icon: <ShowChartIcon />,
        },
        'totales-indicadores': {
            color: '#4a148c',
            icon: <FlightIcon />,
        },
        'totales-indicadores-finca': {
            color: '#00695c',
            icon: <AgricultureIcon />,
        },
        // ...podrías agregar más si lo requieres
    };

    /**
     * Títulos de filtros (p. ej., al generar reporte).
     */
    const filterTitles = {
        fechaInicio: 'Fecha Inicio',
        fechaFin: 'Fecha Fin',
        tipoAeronave: 'Tipo de Aeronave',
        operadorAA: 'Operador',
        fincaAA: 'Finca',
        productoAA: 'Producto',
        zafraAA: 'Zafra',
        loteAA: 'Lote',
        tercioAA: 'Tercio',
        zafraCM: 'Zafra',
        tercioCM: 'Tercio',
        fincaCM: 'Finca',
        operadorCM: 'Operador',
        loteCM: 'Lote',
    };

    const [tooltip, setTooltip] = useState({
        visible: false,
        content: '',
        position: { top: 0, left: 0 },
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const [isTutorialActive, setIsTutorialActive] = useState(false);

    // AÑADIMOS EL STATE PARA EL INDICADOR DE TOTALES (SIN MODIFICAR EL RESTO)
    const [totalAnalisisCount, setTotalAnalisisCount] = useState(0);

    /**
     * Cargar el orden actual (dataCards y chartCards) desde backend.
     */
    const fetchCardOrder = useCallback(async () => {
        try {
            const idUsuario = userData.userId;
            const response = await axios.get(
                `${API_BASE_URL_DASHBOARD}api/indicators/order/${idUsuario}/${selectedActivity}`
            );
            const { order } = response.data;
            const parsedOrder = JSON.parse(order);

            if (parsedOrder.dataCards) setDataCardsOrder(parsedOrder.dataCards);
            if (parsedOrder.chartCards) setChartCardsOrder(parsedOrder.chartCards);
        } catch (error) {
            console.error('Error fetching card order:', error);
        }
    }, [userData.userId, selectedActivity]);

    useEffect(() => {
        fetchCardOrder();
    }, [fetchCardOrder]);

    /********************************************************
     *                DRAG & DROP LOGIC                     *
     ********************************************************/
    /**
     * Solo reordenamos dentro de la categoría en la que se hace DnD.
     */
    const moveCardInGroup = (orderType, groupName, dragIndex, hoverIndex) => {
        const cardOrder = orderType === 'dataCards' ? dataCardsOrder : chartCardsOrder;
        const setCardOrder = orderType === 'dataCards' ? setDataCardsOrder : setChartCardsOrder;

        const subset = cardOrder.filter((c) => c._group === groupName);
        if (!subset[dragIndex]) return;
        const draggedCard = subset[dragIndex];

        const updatedSubset = [...subset];
        updatedSubset.splice(dragIndex, 1);
        updatedSubset.splice(hoverIndex, 0, draggedCard);

        const newOrder = [];
        let subsetIndex = 0;

        for (let i = 0; i < cardOrder.length; i++) {
            const card = cardOrder[i];
            if (card._group === groupName) {
                newOrder.push(updatedSubset[subsetIndex]);
                subsetIndex++;
            } else {
                newOrder.push(card);
            }
        }

        setCardOrder(newOrder);
        saveOrder(orderType, newOrder);
    };

    /**
     * Guardar el nuevo orden en backend.
     */
    const saveOrder = async (orderType, newOrder) => {
        try {
            const idUsuario = userData.userId;
            const preferencesOrder = {
                dataCards: orderType === 'dataCards' ? newOrder : dataCardsOrder,
                chartCards: orderType === 'chartCards' ? newOrder : chartCardsOrder,
            };

            await axios.post(`${API_BASE_URL_DASHBOARD}api/indicators/order`, {
                userId: idUsuario,
                analysis: selectedActivity,
                order: JSON.stringify(preferencesOrder),
            });

            if (orderType === 'dataCards') {
                setDataCardsOrder(newOrder);
            } else {
                setChartCardsOrder(newOrder);
            }
        } catch (error) {
            console.error('Error saving order:', error);
        }
    };

    /**
     * Contenedor DnD para cada tarjeta.
     */
    const CardComponent = ({
                               id,
                               groupName,
                               localIndex,
                               orderType,
                               children,
                           }) => {
        const ref = useRef(null);

        // Drop
        const [{ isOver }, drop] = useDrop({
            accept: orderType === 'dataCards' ? ITEM_TYPE_DATA_CARD : ITEM_TYPE_CHART_CARD,
            canDrop: (item) => item.groupName === groupName,
            hover: (item) => {
                if (!ref.current) return;
                const dragIndex = item.localIndex;
                const hoverIndex = localIndex;
                if (dragIndex === hoverIndex) return;
                if (item.groupName === groupName) {
                    moveCardInGroup(orderType, groupName, dragIndex, hoverIndex);
                    item.localIndex = hoverIndex;
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
            }),
        });

        // Drag
        const [{ isDragging }, drag] = useDrag({
            type: orderType === 'dataCards' ? ITEM_TYPE_DATA_CARD : ITEM_TYPE_CHART_CARD,
            item: { id, localIndex, groupName },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        drag(drop(ref));

        return (
            <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
                {children}
            </div>
        );
    };

    /********************************************************
     *                FILTROS / CARGA DE DATOS              *
     ********************************************************/

    const handleDateChange = useCallback((dates) => {
        setDateRange(dates);
    }, []);

    useEffect(() => {
        let filters = [];
        filters.push({ label: 'Fecha Inicio', value: 'fechaInicio' });
        filters.push({ label: 'Fecha Fin', value: 'fechaFin' });

        if (selectedActivity === 'APLICACIONES_AEREAS') {
            filters.push({
                label: 'Tipo de Aeronave',
                value: 'tipoAeronave',
                options: ['DRONE', 'AVIONETA', 'HELICOPTERO'],
            });
            filters.push({ label: 'Operador', value: 'operadorAA' });
            filters.push({ label: 'Finca', value: 'fincaAA' });
            filters.push({ label: 'Producto', value: 'productoAA' });
            filters.push({ label: 'Zafra', value: 'zafraAA' });
            filters.push({ label: 'Lote', value: 'loteAA' });
            filters.push({ label: 'Tercio', value: 'tercioAA' });
        } else if (selectedActivity === 'COSECHA_MECANICA') {
            filters.push({ label: 'Zafra', value: 'zafraCM' });
            filters.push({ label: 'Tercio', value: 'tercioCM' });
            filters.push({ label: 'Finca', value: 'fincaCM' });
            filters.push({ label: 'Operador', value: 'operadorCM' });
            filters.push({ label: 'Lote', value: 'loteCM' });
        } else if(selectedActivity === 'FERTILIZACION'){
            filters.push({ label: 'Operador', value: 'operadorAA' });
            filters.push({ label: 'Finca', value: 'fincaAA' });
            filters.push({ label: 'Producto', value: 'productoAA' });
            filters.push({ label: 'Zafra', value: 'zafraAA' });
            filters.push({ label: 'Tercio', value: 'tercioAA' });
        }

        setFilterOptions(filters);
    }, [selectedActivity]);

    const fetchCosechaMecanicaFilters = async () => {
        try {
            const response = await axios.get(
                `${API_BASE_URL_DASHBOARD}api/indicators/filters/cosecha-mecanica`
            );

            setFiltersCM({
                loteOptions: response.data.lote || [],
                operadorOptions: response.data.operadores || [],
                fincasOptions: response.data.fincas || [],
                zafrasOptions: response.data.zafras || [],
                tercioOptions: response.data.tercios || [],
            });
            setFiltersFetched((prev) => ({ ...prev, COSECHA_MECANICA: true }));
        } catch (error) {
            console.error('Error fetching Cosecha Mecanica filters:', error);
        }
    };

    const fetchAplicacionesAereasFilters = async () => {
        try {
            const response = await axios.get(
                `${API_BASE_URL_DASHBOARD}api/indicators/filters/aplicaciones-aereas`
            );

            setFiltersAA({
                operadorOptionsAA: response.data.operadores || [],
                fincasOptionsAA: response.data.fincas || [],
                productosOptionsAA: response.data.productos || [],
                zafraOptionsAA: response.data.zafras || [],
                loteOptionsAA: response.data.lotes || [],
                tercioOptionsAA: response.data.tercios || [],
            });
            setFiltersFetched((prev) => ({ ...prev, APLICACIONES_AEREAS: true }));
        } catch (error) {
            console.error('Error fetching Aplicaciones Aereas filters:', error);
        }
    };

    const fetchFertilizacionFilters = async () => {
        try {
            const response = await axios.get(
                `${API_BASE_URL_DASHBOARD}api/indicators/filters/fertilizacion`);

                setFiltersFR({
                    operadorOptionsFR: response.data.operadores || [],
                    fincasOptionsFR: response.data.fincas || [],
                    productosOptionsFR: response.data.productos || [],
                    zafraOptionsFR: response.data.zafras || [],
                    terciosOptionsFR: response.data.tercios || [],
                });

                setFiltersFetched((prev) => ({ ...prev, FERTILIZACION: true }));
        } catch(error){

        }
    };

    useEffect(() => {
        if (!filtersFetched[selectedActivity]) {
            if (selectedActivity === 'COSECHA_MECANICA') {
                fetchCosechaMecanicaFilters();
            } else if (selectedActivity === 'APLICACIONES_AEREAS') {
                fetchAplicacionesAereasFilters();
            } else if(selectedActivity === 'FERTILIZACION'){
                fetchFertilizacionFilters();
            }
        }
    }, [selectedActivity, filtersFetched]);

    useEffect(() => {
        setSelectedFilters({});
        setDateRange({
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month'),
        });
    }, [selectedActivity]);

    const handleActivitySelect = (activity) => {
        if (activity !== selectedActivity) {
            setSelectedActivity(activity);
            setSelectedFilters({});
            setDateRange({
                startDate: moment().startOf('month'),
                endDate: moment().endOf('month'),
            });
            setShouldFetch(true);
        }
    };

    const handleFilterClick = (filters) => {
        const { startDate, endDate } = dateRange;
        const diffInDays = endDate.diff(startDate, 'days');
        if (diffInDays > 31) {
            toast.warn('El rango de fechas no puede ser mayor a 31 días.');
        } else {
            setSelectedFilters(filters);
            setShouldFetch(true);
        }
    };

    // AÑADIMOS LA FUNCIÓN PARA OBTENER EL TOTAL DE ANÁLISIS
    const fetchTotalAnalisis = useCallback(async () => {
        try {
            const { startDate, endDate } = dateRange;
            if (!startDate || !endDate || !userData?.userId) return;

            const startDateFormatted = moment(startDate)
                .startOf('day')
                .format('DD/MM/YYYY');
            const endDateFormatted = moment(endDate)
                .endOf('day')
                .format('DD/MM/YYYY');

            const queryParams = new URLSearchParams({
                userId: userData.userId,
                FECHA_INICIO: startDateFormatted,
                FECHA_FINAL: endDateFormatted,
            });

            if (selectedActivity === 'COSECHA_MECANICA') {
                if (selectedFilters.fincaCM) {
                    queryParams.append('NOMBRE_FINCA', selectedFilters.fincaCM);
                }
                if (selectedFilters.zafraCM) {
                    queryParams.append('ZAFRA', selectedFilters.zafraCM);
                }
                if (selectedFilters.tercioCM) {
                    queryParams.append('TERCIO', selectedFilters.tercioCM);
                }
                if (selectedFilters.operadorCM) {
                    queryParams.append('OPERADOR', selectedFilters.operadorCM);
                }
                if (selectedFilters.loteCM) {
                    queryParams.append('LOTE', selectedFilters.loteCM);
                }

                const resp = await axios.get(
                    `${API_BASE_URL_DASHBOARD}api/indicators/cosecha-mecanica/total-analisis?${queryParams}`
                );
                setTotalAnalisisCount(resp.data.totalAnalisis || 0);
            } else if (selectedActivity === 'APLICACIONES_AEREAS') {
                if (selectedFilters.fincaAA) {
                    queryParams.append('NOMBRE_FINCA', selectedFilters.fincaAA);
                }
                if (selectedFilters.operadorAA) {
                    queryParams.append('NOMBRE_DE_OPERADOR', selectedFilters.operadorAA);
                }
                if (selectedFilters.productoAA) {
                    queryParams.append('PRODUCTO', selectedFilters.productoAA);
                }
                if (selectedFilters.zafraAA) {
                    queryParams.append('ZAFRA', selectedFilters.zafraAA);
                }
                if (selectedFilters.loteAA) {
                    queryParams.append('LOTE', selectedFilters.loteAA);
                }
                if (selectedFilters.tercioAA) {
                    queryParams.append('TERCIO', selectedFilters.tercioAA);
                }
                if (selectedFilters.tipoAeronave) {
                    queryParams.append('EQUIPO', selectedFilters.tipoAeronave);
                }

                const resp = await axios.get(
                    `${API_BASE_URL_DASHBOARD}api/indicators/aplicaciones-areas/total-analisis?${queryParams}`
                );
                setTotalAnalisisCount(resp.data.totalAnalisis || 0);
            } else {
                setTotalAnalisisCount(0);
            }
        } catch (error) {
            setTotalAnalisisCount(0);
            console.error('Error al obtener total de análisis:', error);
        }
    }, [selectedActivity, dateRange, selectedFilters, userData]);

    /**
     * Para evitar múltiples peticiones simultáneas.
     */
    const isFetchingRef = useRef(false);

    /**
     * Cargar datos de indicadores (promedios, totales, etc.).
     */
    const fetchIndicators = useCallback(async () => {
        if (isFetchingRef.current) {
            return;
        }

        const config = analysisConfig[selectedActivity];
        if (config?.fetchDataIndicators && dateRange.startDate && dateRange.endDate) {
            try {
                isFetchingRef.current = true;
                setLoading(true);

                const startDateFormatted = moment(dateRange.startDate)
                    .startOf('day')
                    .format('DD/MM/YYYY');
                const endDateFormatted = moment(dateRange.endDate)
                    .endOf('day')
                    .format('DD/MM/YYYY');

                const idUsuario = userData.userId;

                // Limites
                const limitesResponse = await axios.get(
                    `${API_BASE_URL}configuration/limites/${selectedActivity.toLowerCase()}`
                );
                const fetchedLimites = limitesResponse.data.limites.reduce(
                    (acc, limit) => {
                        acc[limit.indicador] = { max: limit.max, min: limit.min };
                        return acc;
                    },
                    {}
                );
                setLimites(fetchedLimites);

                const minMaxParams = {
                    min: JSON.stringify(fetchedLimites).includes('min') ? fetchedLimites : {},
                    max: JSON.stringify(fetchedLimites).includes('max') ? fetchedLimites : {},
                };

                // Llamadas fetch (según actividad)
                if (selectedActivity === 'COSECHA_MECANICA') {
                    const [
                        promedioResult,
                        porcentajesResult,
                        metricasMaquinaResult,
                        porcentajesMaquinaResult,
                        metricasOperadorResultCM,
                        porcentajesOperadorResultCM,
                        metricasFincaResult,
                        porcentajesFincaResult,
                    ] = await Promise.all([
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'promedio-indicadores',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'porcentajes',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'metricas-maquina',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'porcentajes-maquina',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'metricas-operador',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'porcentajes-operador',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'metricas-finca',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'porcentajes-finca',
                            idUsuario,
                            minMaxParams,
                            selectedFilters
                        ),
                    ]);

                    setPromedioIndicadores(promedioResult);
                    setPorcentajes(porcentajesResult);
                    setMetricasMaquina(metricasMaquinaResult);
                    setPorcentajesMaquina(porcentajesMaquinaResult);
                    setMetricasOperadorCM(metricasOperadorResultCM);
                    setPorcentajesOperadorCM(porcentajesOperadorResultCM);
                    setMetricasFinca(metricasFincaResult);
                    setPorcentajesFinca(porcentajesFincaResult);

                } else if (selectedActivity === 'APLICACIONES_AEREAS') {
                    const selectedAircraft = selectedFilters.tipoAeronave || '';

                    const [
                        promedioResult,
                        metricasFincaResult,
                        tiempoTotalResult,
                        dosisTeoricaRealResult,
                        totalesIndicadoresResult,
                        totalesIndicadoresFincaResult,
                        totalesIndicadoresProductoResult,
                        metricasProductoResult,
                        totalesIndicadoresOperadorResult,
                        metricasOperadorResult,
                        totalesIndicadoresZafraResult,
                        metricasZafraResult,
                        totalesIndicadoresLoteResult,
                        metricasLoteResult,
                        totalesIndicadoresTercioResult,
                        metricasTercioResult,
                    ] = await Promise.all([
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'promedio-indicadores',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'metricas-finca',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'tiempo-total',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'teorica-real',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'totales-indicadores',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'totales-indicadores-finca',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'totales-indicadores-producto',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'metricas-producto',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'totales-indicadores-operador',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'metricas-operador',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'totales-indicadores-zafra',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'metricas-zafra',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'totales-indicadores-lote',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'metricas-lote',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'totales-indicadores-tercio',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                        config.fetchDataIndicators(
                            startDateFormatted,
                            endDateFormatted,
                            'metricas-tercio',
                            idUsuario,
                            minMaxParams,
                            selectedAircraft,
                            selectedFilters
                        ),
                    ]);

                    setPromedioIndicadores(promedioResult || []);
                    setMetricasFinca(metricasFincaResult || []);
                    setTiempoTotal(tiempoTotalResult || []);
                    setDosisTeoricaReal(dosisTeoricaRealResult || []);
                    setTotalesIndicadores(totalesIndicadoresResult || []);

                    setTotalesIndicadoresFinca(
                        (totalesIndicadoresFincaResult || []).map((item) => ({
                            ...item,
                            NOMBRE_FINCA: item._id || null,
                            _id: undefined,
                        }))
                    );
                    setTotalesIndicadoresProducto(totalesIndicadoresProductoResult || []);
                    setMetricasProducto(metricasProductoResult || []);
                    setTotalesIndicadoresOperador(totalesIndicadoresOperadorResult || []);
                    setMetricasOperador(metricasOperadorResult || []);
                    setTotalesIndicadoresZafra(totalesIndicadoresZafraResult || []);
                    setMetricasZafra(metricasZafraResult || []);
                    setTotalesIndicadoresLote(totalesIndicadoresLoteResult || []);
                    setMetricasLote(metricasLoteResult || []);
                    setTotalesIndicadoresTercio(totalesIndicadoresTercioResult || []);
                    setMetricasTercio(metricasTercioResult || []);
                }

                setShouldFetch(false);
            } catch (error) {
                console.error('Error fetching indicators:', error);
            } finally {
                setLoading(false);
                isFetchingRef.current = false;
            }
        }
    }, [selectedActivity, dateRange, userData.userId, selectedFilters]);

    useEffect(() => {
        if (shouldFetch) {
            fetchIndicators();
            fetchTotalAnalisis();
        }
    }, [shouldFetch, fetchIndicators, fetchTotalAnalisis]);

    /**
     * Helpers de formateo
     */
    const formatValue = (value) =>
        typeof value === 'number' ? value.toFixed(3) : value;

    const getMinMaxFromLimits = (card) => {
        const limitName = card.limitName;
        const limit = limites[limitName] || { min: null, max: null };

        return {
            min: limit.min !== null ? limit.min : 'N/A',
            max: limit.max !== null ? limit.max : 'N/A',
        };
    };

    /********************************************************
     *      SEPARACIÓN / AGRUPACIÓN DE DATA CARDS           *
     ********************************************************/
    const getDataCardCategory = (cardId) => {
        const id = cardId.toLowerCase();
        if (id.includes('dosis')) return 'Dosis';
        if (id.includes('total')) return 'Totales';
        if (id.includes('promedio')) return 'Promedios';
        return 'Indicadores';
    };

    const groupDataCardsByCategory = (cardsArray) => {
        const groups = {
            Totales: [],
            Promedios: [],
            Dosis: [],
            Indicadores: [],
        };

        cardsArray.forEach((card) => {
            const category = getDataCardCategory(card.id);
            card._group = category;
            groups[category].push(card);
        });

        return groups;
    };

    /********************************************************
     *    SEPARACIÓN / AGRUPACIÓN DE CHART CARDS (gráficas) *
     ********************************************************/
    const getChartCardCategory = (cardId) => {
        let entity = '';

        if (cardId.includes('Operador')) {
            entity = 'Operador';
        } else if (cardId.includes('Finca')) {
            entity = 'Finca';
        } else if (cardId.includes('Zafra')) {
            entity = 'Zafra';
        } else if (cardId.includes('Producto')) {
            entity = 'Producto';
        } else {
            entity = 'General';
        }

        if (cardId.startsWith('promedio') || cardId.startsWith('diferencia')) {
            return `Promedios por ${entity}`;
        } else if (cardId.startsWith('total')) {
            return `Totales por ${entity}`;
        }
        return 'Indicadores';
    };

    const groupChartCardsByCategory = (cardsArray) => {
        const grouped = {};

        cardsArray.forEach((card) => {
            const category = getChartCardCategory(card.id);
            card._group = category;

            if (!grouped[category]) grouped[category] = [];
            grouped[category].push(card);
        });

        return grouped;
    };

    /********************************************************
     * MAPEOS DE DATOS PARA LAS GRÁFICAS
     ********************************************************/
    const mapChartDataForTotalsFinca = (data, cardId) => {
        return data
            .map((item) => {
                if (item[cardId] && item.NOMBRE_FINCA) {
                    return {
                        name: item.NOMBRE_FINCA,
                        value:
                            item[cardId].value !== undefined
                                ? item[cardId].value
                                : null,
                    };
                }
                return null;
            })
            .filter(Boolean);
    };

    const mapChartDataForTotalsGeneric = (data, cardId, nameKey) => {
        return data
            .map((item) => {
                if (item[cardId] && item[nameKey]) {
                    return {
                        name: item[nameKey],
                        value:
                            item[cardId].value !== undefined
                                ? item[cardId].value
                                : null,
                    };
                }
                return null;
            })
            .filter(Boolean);
    };

    const mapChartData = (metrics, percentages, cardId) => {
        const combinedData = [];

        metrics.forEach((metric) => {
            if (metric[cardId]) {
                combinedData.push({
                    name: metric._id,
                    value: metric[cardId]?.value ?? null,
                });
            }
        });

        percentages.forEach((percentage) => {
            if (percentage[cardId]) {
                combinedData.push({
                    name: percentage._id,
                    value: percentage[cardId]?.value ?? null,
                });
            }
        });

        return combinedData.filter(
            (item) => item.value !== null && item.value !== undefined
        );
    };

    const chartRefs = useRef({});

    // Convierte imagen a base64
    const convertImageToBase64 = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');

            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                const dataURL = canvas.toDataURL('image/png');
                resolve(dataURL);
            };

            img.onerror = (error) => {
                reject(error);
            };

            img.src = url;
        });
    };

    // Generar PDF
    const handleGenerateReport = async () => {
        try {
            setIsGeneratingReport(true);

            if (
                dataCardsOrder.length === 0 ||
                chartCardsOrder.length === 0 ||
                loading
            ) {
                toast.warn('No hay datos para generar el reporte.');
                return;
            }

            const now = moment();
            const formattedDateTime = now.format('dddd, D [de] MMMM [de] YYYY, h:mm A');
            const formattedFileDateTime = now.format('YYYYMMDD_HHmmss');

            let logoBase64 = '';
            try {
                logoBase64 = await convertImageToBase64('/logo.png');
            } catch (error) {
                console.error('Error al cargar el logo:', error);
                toast.error('Error al cargar el logo para el reporte.');
                return;
            }

            const reportData = {
                activity: selectedActivity,
                filters: {},
                dataCards: [],
                charts: [],
                dateTime: formattedDateTime,
                logo: logoBase64,
            };

            for (const [key, value] of Object.entries(selectedFilters)) {
                if (value !== null && value !== undefined && value !== '') {
                    const title = filterTitles[key] || key;
                    reportData.filters[title] = value;
                }
            }

            // Sección dataCards
            dataCardsOrder.forEach((card) => {
                const dataValue =
                    // COSECHA
                    promedioIndicadores[0]?.[card.id]?.value ||
                    porcentajes[0]?.[card.id]?.value ||
                    metricasMaquina[0]?.[card.id]?.value ||
                    porcentajesMaquina[0]?.[card.id]?.value ||
                    metricasOperadorCM[0]?.[card.id]?.value ||
                    porcentajesOperadorCM[0]?.[card.id]?.value ||
                    metricasFinca[0]?.[card.id]?.value ||
                    porcentajesFinca[0]?.[card.id]?.value ||
                    // APLICACIONES
                    tiempoTotal[0]?.[card.id]?.value ||
                    dosisTeoricaReal[0]?.[card.id]?.value ||
                    totalesIndicadores[0]?.[card.id]?.value ||
                    totalesIndicadoresFinca[0]?.[card.id]?.value ||
                    totalesIndicadoresProducto[0]?.[card.id]?.value ||
                    metricasProducto[0]?.[card.id]?.value ||
                    totalesIndicadoresOperador[0]?.[card.id]?.value ||
                    metricasOperador[0]?.[card.id]?.value ||
                    totalesIndicadoresZafra[0]?.[card.id]?.value ||
                    metricasZafra[0]?.[card.id]?.value ||
                    totalesIndicadoresLote[0]?.[card.id]?.value ||
                    metricasLote[0]?.[card.id]?.value ||
                    totalesIndicadoresTercio[0]?.[card.id]?.value ||
                    metricasTercio[0]?.[card.id]?.value;

                const hasData = dataValue !== undefined && dataValue !== null;

                reportData.dataCards.push({
                    id: card.id,
                    title: card.title,
                    value: hasData ? formatValue(dataValue) : 'Sin datos',
                    dimensional: card.dimensional || '',
                });
            });

            // Screenshots de las gráficas
            const chartImages = [];
            const chartRefEntries = Object.entries(chartRefs.current);
            for (const [cardId, chartRef] of chartRefEntries) {
                if (chartRef && chartRef.current) {
                    try {
                        const dataUrl = await domtoimage.toPng(chartRef.current);
                        chartImages.push({
                            id: cardId,
                            title:
                                chartCardsOrder.find((c) => c.id === cardId)?.title ||
                                cardId,
                            image: dataUrl,
                        });
                    } catch (error) {
                        console.error('Error al capturar la imagen de la gráfica:', error);
                    }
                }
            }

            reportData.charts = chartImages;

            // Generar PDF
            const response = await axios.post(
                `${API_BASE_URL}reporteria/dashboard-indicators`,
                reportData,
                {
                    responseType: 'blob',
                }
            );

            const blob = new Blob([response.data], { type: 'application/pdf' });
            saveAs(blob, `dashboard_report_${formattedFileDateTime}.pdf`);
        } catch (error) {
            console.error('Error al generar el reporte:', error);
            toast.error('Error al generar el reporte');
        } finally {
            setIsGeneratingReport(false);
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <DashboardContainer isSidebarOpen={isSidebarOpen}>
                {isGeneratingReport && <Loader />}

                {/* AÑADIMOS AQUÍ LA SECCIÓN DE TOTALES */}
                <TotalAnalisisTab>
                    Total análisis realizados: {totalAnalisisCount}
                </TotalAnalisisTab>

                <FilterToolbar
                    key={selectedActivity}
                    isSidebarOpen={isSidebarOpen}
                    isDashboardIndicators={true}
                    filterOptions={filterOptions}
                    onDateChange={handleDateChange}
                    onFilterClick={handleFilterClick}
                    loteOptions={filtersCM.loteOptions}
                    operadorOptions={filtersCM.operadorOptions}
                    fincasOptions={filtersCM.fincasOptions}
                    zafrasOptions={filtersCM.zafrasOptions}
                    tercioOptions={filtersCM.tercioOptions}
                    operadorOptionsAA={filtersAA.operadorOptionsAA}
                    fincasOptionsAA={filtersAA.fincasOptionsAA}
                    productosOptionsAA={filtersAA.productosOptionsAA}
                    zafraOptionsAA={filtersAA.zafraOptionsAA}
                    loteOptionsAA={filtersAA.loteOptionsAA}
                    tercioOptionsAA={filtersAA.tercioOptionsAA}
                    operadorOptionsFR={filtersFR.operadorOptionsFR}
                    fincasOptionsFR={filtersFR.fincaOptionsFR}
                    productosOptionsFR={filtersFR.productosOptionsFR}
                    zafraOptionsFR={filtersFR.zafraOptionsFR}
                    tercioOptionsFR={filtersFR.tercioOptionsFR}
                    dateRange={dateRange}
                    selectedActivity={selectedActivity}
                    onGenerateReport={handleGenerateReport}
                />
                <ActivitiesComponent
                    onSelectActivity={handleActivitySelect}
                    isSidebarOpen={isSidebarOpen}
                />
                <ContentContainer>
                    <RightPanel>
                        {activitiesInProgress.includes(selectedActivity) ? (
                            <InProgressMessage>
                                ¡Estamos mejorando esta sección! Pronto podrás acceder a
                                esta información.
                            </InProgressMessage>
                        ) : (
                            <CardsContainer>
                                {/* AGRUPAMOS LAS DATA CARDS */}
                                {(() => {
                                    const groupedDataCards = groupDataCardsByCategory(dataCardsOrder);
                                    const categoriesInOrder = [
                                        'Totales',
                                        'Promedios',
                                        'Dosis',
                                        'Indicadores',
                                    ];

                                    return categoriesInOrder.map((cat) => {
                                        const cards = groupedDataCards[cat] || [];
                                        if (cards.length === 0) return null;

                                        return (
                                            <SectionContainer key={cat}>
                                                <SectionTitle style={{ textAlign: 'center' }}>
                                                    {cat.toUpperCase()}
                                                </SectionTitle>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        width: '100%',
                                                    }}
                                                >
                                                    {cards.map((card, index) => {
                                                        const { min, max } = getMinMaxFromLimits(card);

                                                        const dataValue =
                                                            // COSECHA
                                                            promedioIndicadores[0]?.[card.id]?.value ||
                                                            porcentajes[0]?.[card.id]?.value ||
                                                            metricasMaquina[0]?.[card.id]?.value ||
                                                            porcentajesMaquina[0]?.[card.id]?.value ||
                                                            metricasOperadorCM[0]?.[card.id]?.value ||
                                                            porcentajesOperadorCM[0]?.[card.id]?.value ||
                                                            metricasFinca[0]?.[card.id]?.value ||
                                                            porcentajesFinca[0]?.[card.id]?.value ||
                                                            // APLICACIONES
                                                            tiempoTotal[0]?.[card.id]?.value ||
                                                            dosisTeoricaReal[0]?.[card.id]?.value ||
                                                            totalesIndicadores[0]?.[card.id]?.value ||
                                                            totalesIndicadoresFinca[0]?.[card.id]?.value ||
                                                            totalesIndicadoresProducto[0]?.[card.id]?.value ||
                                                            metricasProducto[0]?.[card.id]?.value ||
                                                            totalesIndicadoresOperador[0]?.[card.id]?.value ||
                                                            metricasOperador[0]?.[card.id]?.value ||
                                                            totalesIndicadoresZafra[0]?.[card.id]?.value ||
                                                            metricasZafra[0]?.[card.id]?.value ||
                                                            totalesIndicadoresLote[0]?.[card.id]?.value ||
                                                            metricasLote[0]?.[card.id]?.value ||
                                                            totalesIndicadoresTercio[0]?.[card.id]?.value ||
                                                            metricasTercio[0]?.[card.id]?.value;

                                                        const hasData = dataValue !== undefined && dataValue !== null;

                                                        const cardStyle = chartStyles[card.id] || {};
                                                        const color = cardStyle.color;
                                                        const icon = cardStyle.icon;

                                                        return (
                                                            <CardComponent
                                                                key={card.id}
                                                                id={card.id}
                                                                groupName={card._group}
                                                                localIndex={index}
                                                                orderType="dataCards"
                                                            >
                                                                <CardData
                                                                    title={card.title}
                                                                    icon={icon}
                                                                    color={color}
                                                                    min={min}
                                                                    max={max}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            height: '100%',
                                                                        }}
                                                                    >
                                                                        {hasData ? (
                                                                            formatValue(dataValue) + ' ' + (card.dimensional || '')
                                                                        ) : loading ? (
                                                                            <CircularProgress />
                                                                        ) : (
                                                                            <NoDataMessage />
                                                                        )}
                                                                    </div>
                                                                </CardData>
                                                            </CardComponent>
                                                        );
                                                    })}
                                                </div>
                                            </SectionContainer>
                                        );
                                    });
                                })()}

                                {/* AGRUPAMOS LAS CHART CARDS (GRÁFICAS) */}
                                <SectionContainer style={{ marginTop: '30px' }}>
                                    {(() => {
                                        const groupedChartCards = groupChartCardsByCategory(chartCardsOrder);

                                        /**
                                         * IMPORTANTE:
                                         * Para que la franja de "Totales" aparezca antes que la de "Promedios"
                                         * en las gráficas de Finca, Operador y Producto,
                                         * simplemente ordenamos las categorías de manera personalizada:
                                         */
                                        const chartCategories = Object.keys(groupedChartCards).sort((a, b) => {
                                            const entitiesToReorder = ['Finca', 'Operador', 'Producto'];
                                            // Extraemos la parte final: "por Finca", "por Operador", etc.
                                            const entityA = a.split(' por ')[1] || '';
                                            const entityB = b.split(' por ')[1] || '';

                                            // Solo reordenar si ambos pertenecen a las mismas categorías (mismo entity)
                                            // y el entity está en ['Finca','Operador','Producto'].
                                            // Queremos "Totales" antes de "Promedios".
                                            if (entityA === entityB && entitiesToReorder.includes(entityA)) {
                                                if (a.includes('Totales') && b.includes('Promedios')) return -1;
                                                if (a.includes('Promedios') && b.includes('Totales')) return 1;
                                            }
                                            return 0;
                                        });

                                        return chartCategories.map((cat) => {
                                            const chartCards = groupedChartCards[cat];
                                            if (!chartCards || chartCards.length === 0) return null;

                                            return (
                                                <div key={cat} style={{ marginBottom: '2rem' }}>
                                                    <SectionTitle style={{ textAlign: 'center' }}>
                                                        {cat}
                                                    </SectionTitle>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            justifyContent: 'flex-start',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {chartCards.map((card, index) => {
                                                            const { min, max } = getMinMaxFromLimits(card);

                                                            // 1) Se revisan métricas/porcentajes COSECHA
                                                            let chartData = mapChartData(
                                                                metricasMaquina,
                                                                porcentajesMaquina,
                                                                card.id
                                                            );
                                                            if (!chartData.length) {
                                                                chartData = mapChartData(
                                                                    metricasOperadorCM,
                                                                    porcentajesOperadorCM,
                                                                    card.id
                                                                );
                                                            }
                                                            if (!chartData.length) {
                                                                chartData = mapChartData(
                                                                    metricasFinca,
                                                                    porcentajesFinca,
                                                                    card.id
                                                                );
                                                            }

                                                            // 2) Métricas/porcentajes APLICACIONES
                                                            if (!chartData.length) {
                                                                chartData = mapChartData(
                                                                    metricasProducto,
                                                                    [],
                                                                    card.id
                                                                );
                                                            }
                                                            if (!chartData.length) {
                                                                chartData = mapChartData(
                                                                    metricasOperador,
                                                                    [],
                                                                    card.id
                                                                );
                                                            }
                                                            if (!chartData.length) {
                                                                chartData = mapChartData(
                                                                    metricasZafra,
                                                                    [],
                                                                    card.id
                                                                );
                                                            }
                                                            if (!chartData.length) {
                                                                chartData = mapChartData(
                                                                    metricasLote,
                                                                    [],
                                                                    card.id
                                                                );
                                                            }
                                                            if (!chartData.length) {
                                                                chartData = mapChartData(
                                                                    metricasTercio,
                                                                    [],
                                                                    card.id
                                                                );
                                                            }

                                                            // 3) Totales
                                                            if (!chartData.length && totalesIndicadoresFinca.length) {
                                                                chartData = mapChartDataForTotalsFinca(
                                                                    totalesIndicadoresFinca,
                                                                    card.id
                                                                );
                                                            }
                                                            if (!chartData.length && totalesIndicadoresProducto.length) {
                                                                chartData = mapChartDataForTotalsGeneric(
                                                                    totalesIndicadoresProducto,
                                                                    card.id,
                                                                    '_id'
                                                                );
                                                            }
                                                            if (!chartData.length && totalesIndicadoresOperador.length) {
                                                                chartData = mapChartDataForTotalsGeneric(
                                                                    totalesIndicadoresOperador,
                                                                    card.id,
                                                                    '_id'
                                                                );
                                                            }
                                                            if (!chartData.length && totalesIndicadoresZafra.length) {
                                                                chartData = mapChartDataForTotalsGeneric(
                                                                    totalesIndicadoresZafra,
                                                                    card.id,
                                                                    '_id'
                                                                );
                                                            }
                                                            if (!chartData.length && totalesIndicadoresLote.length) {
                                                                chartData = mapChartDataForTotalsGeneric(
                                                                    totalesIndicadoresLote,
                                                                    card.id,
                                                                    '_id'
                                                                );
                                                            }
                                                            if (!chartData.length && totalesIndicadoresTercio.length) {
                                                                chartData = mapChartDataForTotalsGeneric(
                                                                    totalesIndicadoresTercio,
                                                                    card.id,
                                                                    '_id'
                                                                );
                                                            }

                                                            const hasData = chartData.length > 0;
                                                            const chartStyle = chartStyles[card.id] || {};
                                                            const color = chartStyle.color;
                                                            const icon = chartStyle.icon;
                                                            const colors = chartStyle.colors;

                                                            const chartRef = React.createRef();
                                                            chartRefs.current[card.id] = chartRef;

                                                            const chartType =
                                                                card.id.includes('porcentaje') ||
                                                                card.type === 'pie'
                                                                    ? 'pie'
                                                                    : 'line';

                                                            return (
                                                                <ChartCardContainer
                                                                    key={card.id}
                                                                    ref={chartRef}
                                                                >
                                                                    <CardComponent
                                                                        id={card.id}
                                                                        groupName={card._group}
                                                                        localIndex={index}
                                                                        orderType="chartCards"
                                                                    >
                                                                        <ChartCard
                                                                            title={card.title}
                                                                            data={hasData ? chartData : []}
                                                                            type={chartType}
                                                                            color={color}
                                                                            colors={colors}
                                                                            icon={icon}
                                                                            min={min}
                                                                            max={max}
                                                                        >
                                                                            {loading && (
                                                                                <CircularProgress />
                                                                            )}
                                                                        </ChartCard>
                                                                    </CardComponent>
                                                                </ChartCardContainer>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            );
                                        });
                                    })()}
                                </SectionContainer>
                            </CardsContainer>
                        )}
                    </RightPanel>
                </ContentContainer>
            </DashboardContainer>

            {tooltip.visible && (
                <TooltipContainer
                    top={tooltip.position.top}
                    left={tooltip.position.left}
                >
                    {tooltip.content}
                </TooltipContainer>
            )}

            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <ModalContent>
                    <SettingsIcon
                        style={{
                            fontSize: '4rem',
                            color: '#f5a623',
                            animation: 'rotate 2s linear infinite',
                        }}
                    />
                    <h2 id="modal-title">Sección en construcción</h2>
                    <p id="modal-description">{modalMessage}</p>
                </ModalContent>
            </Modal>

            <Tutorial
                isActive={isTutorialActive}
                onClose={() => setIsTutorialActive(false)}
            />
        </DndProvider>
    );
};

export default DashboardIndicators;
