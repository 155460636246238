import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import Map, { Source, Layer, NavigationControl, Popup } from 'react-map-gl';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import HomeIcon from '@mui/icons-material/Home';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LayersIcon from '@mui/icons-material/Layers';
import { styled } from '@mui/system';
import axios from 'axios';
import * as turf from '@turf/turf'; // <<--- Asegúrate de tener este import

import BarIndicator from '../../components/BarIndicator/BarIndicator';
import MapDialog from '../../components/MapDialog/MapDialog';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    marginTop: theme.spacing(1),
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
        backgroundColor: 'lightgray',
    },
    width: 36,
    right: 50,
    height: 36,
}));

const CommonMap = (props) => {
    const {
        center = [14.634915, -90.506882],
        zoom = 8,
        polygons = [],
        lines = [],
        points = [],
        areasSuperpuestas = [],
        nonIntersectedAreas = [],
        bufferedLines = [],
        bufferedIntersections = [],
        onLineHover = () => {},
        onLineMouseOut = () => {},
        onLineClick = () => {},
        polygonProperties = [],
        showIntersections = true,
        mapRef,
        polygonsData = [],
        highlightedLote = null,
        activeLotes = [],
        onSelectLote = () => {},
        onHoverLote = () => {},
        onLeaveLote = () => {},
        imageUrl = '',
        northWestCoords,
        southEastCoords,
        filteredColors,
        selectedLine,
        getLineColor,
        lineasNoFiltradas = [],
        popupInfo,
        setPopupInfo,
        selectedFiles,
        isEditing,
        tramplingLines,
        polygonsHarvestPlanning,
        activeTool,
        isFilterDialogOpen,
        closeFilterDialog,
        setLabelsAndColors,
        setTipoMapeo,
        setNorthWestCoords,
        setSouthEastCoords,
        setCoordinateSystem
    } = props;

    const localMapRef = useRef(null);

    const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v12');
    const [selectedStyle, setSelectedStyle] = useState(() => {
        return localStorage.getItem('selectedMapStyle') || 'Satellite View (No Details)';
    });

    const [mapLoaded, setMapLoaded] = useState(false);
    const [selectedPopup, setSelectedPopup] = useState(null);
    const [hasWarnedInvalidCoords, setHasWarnedInvalidCoords] = useState(false);

    const [selectedPolygonId, setSelectedPolygonId] = useState(null);
    const [selectedHarvestGrouping, setSelectedHarvestGrouping] = useState(null);

    const safePolygonsHarvest = useMemo(() => {
        if (
            polygonsHarvestPlanning &&
            polygonsHarvestPlanning.features &&
            Array.isArray(polygonsHarvestPlanning.features)
        ) {
            return polygonsHarvestPlanning;
        }
        return { type: 'FeatureCollection', features: [] };
    }, [polygonsHarvestPlanning]);

    const harvestGroupings = useMemo(() => {
        const result = {};
        safePolygonsHarvest.features.forEach((feature) => {
            const props = feature.properties || {};
            Object.keys(props).forEach((propKey) => {
                if (propKey.startsWith('agrupacion_')) {
                    const valor = props[propKey];
                    if (valor) {
                        const colorKey = propKey.replace('agrupacion_', 'color_');
                        const colorValue = props[colorKey] || '#999999';
                        if (!result[propKey]) {
                            result[propKey] = {};
                        }
                        result[propKey][valor] = colorValue;
                    }
                }
            });
        });
        return result;
    }, [safePolygonsHarvest]);

    const harvestLegendLabels = useMemo(() => {
        if (!selectedHarvestGrouping || !harvestGroupings[selectedHarvestGrouping]) {
            return [];
        }
        const valuesMap = harvestGroupings[selectedHarvestGrouping];
        return Object.entries(valuesMap).map(([valor, color]) => ({
            color,
            text: valor,
        }));
    }, [selectedHarvestGrouping, harvestGroupings]);

    // --------------------------------------------------------------------------------
    //                  Manejo del estilo del mapa (menú de capas)
    // --------------------------------------------------------------------------------
    const mapStyles = {
        'Satellite View (No Details)': 'mapbox://styles/mapbox/satellite-v9',
        'Street Map': 'mapbox://styles/mapbox/streets-v12',
        'Terrain Map': 'mapbox://styles/mapbox/terrain-v2',
        'Outdoors Map': 'mapbox://styles/mapbox/outdoors-v12',
        'Satellite Streets': 'mapbox://styles/mapbox/satellite-streets-v12',
        'Light Map': 'mapbox://styles/mapbox/light-v10',
    };

    useEffect(() => {
        try {
            if (mapStyles[selectedStyle]) {
                setMapStyle(mapStyles[selectedStyle]);
                localStorage.setItem('selectedMapStyle', selectedStyle);
            } else {
                console.warn(`El estilo "${selectedStyle}" no está definido en mapStyles.`);
            }
        } catch (error) {
            console.error('Error al actualizar el estilo del mapa:', error);
        }
    }, [selectedStyle]);

    const handleMapLoad = useCallback(() => {
        try {
            setMapLoaded(true);
            if (mapRef && localMapRef.current) {
                mapRef.current = localMapRef.current.getMap();
            }
        } catch (error) {
            console.error('Error en handleMapLoad:', error);
        }
    }, [mapRef]);

    // --------------------------------------------------------------------------------
    //  Verificar si la URL de la imagen es de tipo "tiled" (contiene {z},{x},{y})
    // --------------------------------------------------------------------------------
    const isTiled = useMemo(() => {
        if (!imageUrl) return false;
        return imageUrl.includes('{z}') && imageUrl.includes('{x}') && imageUrl.includes('{y}');
    }, [imageUrl]);

    // --------------------------------------------------------------------------------
    //               Función para normalizar polígonos a Features
    // --------------------------------------------------------------------------------
    const getPolygonFeatures = useCallback((dataArray) => {
        try {
            if (!Array.isArray(dataArray)) {
                console.warn('getPolygonFeatures: dataArray no es un arreglo.');
                return [];
            }

            const features = dataArray
                .map((item) => {
                    try {
                        if (item && item.geometry && item.geometry.type && item.geometry.coordinates) {
                            let geometry = item.geometry;
                            if (geometry.type === 'Polygon') {
                                const coordinates = geometry.coordinates
                                    .map((ring) =>
                                        ring
                                            .map((coord) =>
                                                Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                                            )
                                            .filter((c) => c !== null)
                                    )
                                    .filter((ring) => ring.length > 0);

                                return {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Polygon',
                                        coordinates: coordinates,
                                    },
                                    properties: item.properties || {},
                                };
                            } else if (geometry.type === 'MultiPolygon') {
                                const coordinates = geometry.coordinates
                                    .map((polygon) =>
                                        polygon
                                            .map((ring) =>
                                                ring
                                                    .map((coord) =>
                                                        Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                                                    )
                                                    .filter((c) => c !== null)
                                            )
                                            .filter((ring) => ring.length > 0)
                                    )
                                    .filter((polygon) => polygon.length > 0);

                                return {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'MultiPolygon',
                                        coordinates: coordinates,
                                    },
                                    properties: item.properties || {},
                                };
                            } else {
                                console.warn(
                                    `getPolygonFeatures: Tipo de geometría "${geometry.type}" no soportado.`
                                );
                                return null;
                            }
                        } else if (Array.isArray(item)) {
                            // Caso de polígono como array de coords
                            const coordinates = item
                                .map((coord) =>
                                    Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                                )
                                .filter((c) => c !== null);
                            if (coordinates.length > 0) {
                                return {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Polygon',
                                        coordinates: [coordinates],
                                    },
                                    properties: {},
                                };
                            }
                            return null;
                        } else {
                            console.warn('getPolygonFeatures: Item inválido.', item);
                            return null;
                        }
                    } catch (innerErr) {
                        console.error('Error interno procesando un item en getPolygonFeatures:', innerErr, item);
                        return null;
                    }
                })
                .filter((feature) => feature !== null);

            return features;
        } catch (error) {
            console.error('Error en getPolygonFeatures:', error);
            return [];
        }
    }, []);

    // --------------------------------------------------------------------------------
    //                   Generar datos para la capa de "points"
    // --------------------------------------------------------------------------------
    const pointData = useMemo(() => {
        try {
            if (Array.isArray(points)) {
                const pointFeatures = points
                    .map((point, index) => {
                        try {
                            if (
                                point &&
                                point.geometry &&
                                Array.isArray(point.geometry.coordinates) &&
                                point.geometry.coordinates.length >= 2
                            ) {
                                const [lon, lat] = point.geometry.coordinates;
                                if (typeof lon !== 'number' || typeof lat !== 'number') {
                                    console.warn(`Coordenadas inválidas en punto ${index}.`, point);
                                    return null;
                                }
                                return {
                                    type: 'Feature',
                                    geometry: { type: 'Point', coordinates: [lon, lat] },
                                    properties: {
                                        color: point.color || '#FF0000',
                                    },
                                };
                            }
                            return null;
                        } catch (err) {
                            console.error('Error procesando un punto en pointData:', err, point);
                            return null;
                        }
                    })
                    .filter(Boolean);

                return { type: 'FeatureCollection', features: pointFeatures };
            } else {
                console.warn('pointData: "points" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }
        } catch (error) {
            console.error('Error en pointData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [points]);

    // --------------------------------------------------------------------------------
    //                   Generar datos para la capa de "lines"
    // --------------------------------------------------------------------------------
    const lineData = useMemo(() => {
        try {
            if (!Array.isArray(lines)) {
                console.warn('lineData: "lines" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }
            const lineFeatures = lines
                .map((line, index) => {
                    try {
                        if (line && Array.isArray(line.latlngs)) {
                            const coordinates = line.latlngs
                                .map((latlng) => {
                                    if (latlng && typeof latlng.lat === 'number' && typeof latlng.lng === 'number') {
                                        return [latlng.lng, latlng.lat];
                                    }
                                    console.warn(`lineData: Coordenada inválida en línea index ${index}.`, latlng);
                                    return null;
                                })
                                .filter((coord) => coord !== null);

                            if (coordinates.length > 0) {
                                const featureId = line.id !== undefined ? line.id : `line-${index}`;
                                return {
                                    type: 'Feature',
                                    id: featureId,
                                    geometry: { type: 'LineString', coordinates },
                                    properties: {
                                        ...line.properties,
                                        color: '#9b00a9',
                                    },
                                };
                            }
                        } else {
                            console.warn(`lineData: Estructura de línea inválida en index ${index}.`, line);
                        }
                        return null;
                    } catch (err) {
                        console.error('Error procesando un item en lineData:', err, line);
                        return null;
                    }
                })
                .filter(Boolean);

            return { type: 'FeatureCollection', features: lineFeatures };
        } catch (error) {
            console.error('Error en lineData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [lines]);

    // --------------------------------------------------------------------------------
    //                   Generar datos para "lineasNoFiltradas"
    // --------------------------------------------------------------------------------
    const lineasNoFiltradasData = useMemo(() => {
        try {
            if (!Array.isArray(lineasNoFiltradas)) {
                console.warn('lineasNoFiltradasData: "lineasNoFiltradas" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }

            const features = lineasNoFiltradas
                .map((line, index) => {
                    try {
                        if (line && Array.isArray(line.latlngs)) {
                            const coordinates = line.latlngs
                                .map((latlng) => {
                                    if (latlng && typeof latlng.lat === 'number' && typeof latlng.lng === 'number') {
                                        return [latlng.lng, latlng.lat];
                                    }
                                    console.warn(
                                        `lineasNoFiltradasData: Coordenada inválida en index ${index}`,
                                        latlng
                                    );
                                    return null;
                                })
                                .filter((c) => c !== null);

                            if (coordinates.length > 0) {
                                return {
                                    type: 'Feature',
                                    id: line.id !== undefined ? line.id : `no-filtradas-line-${index}`,
                                    geometry: { type: 'LineString', coordinates },
                                    properties: {
                                        ...line.properties,
                                        color: '#ffffff',
                                    },
                                };
                            }
                        } else if (line && line.geometry) {
                            // Si ya viene con geometry
                            if (['LineString', 'MultiLineString'].includes(line.geometry.type)) {
                                return {
                                    type: 'Feature',
                                    id: line.id !== undefined ? line.id : `no-filtradas-geom-${index}`,
                                    geometry: line.geometry,
                                    properties: {
                                        ...line.properties,
                                        color: '#ffffff',
                                    },
                                };
                            }
                            console.warn(
                                `lineasNoFiltradasData: Tipo de geometría no soportado en index ${index}.`,
                                line.geometry.type
                            );
                        } else {
                            console.warn(
                                `lineasNoFiltradasData: Línea inválida en index ${index}.`,
                                line
                            );
                        }
                        return null;
                    } catch (err) {
                        console.error('Error procesando un ítem en lineasNoFiltradasData:', err, line);
                        return null;
                    }
                })
                .filter(Boolean);

            return { type: 'FeatureCollection', features };
        } catch (error) {
            console.error('Error en lineasNoFiltradasData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [lineasNoFiltradas]);

    // --------------------------------------------------------------------------------
    //            Obtener ID de Lote para los polígonos (si aplica)
    // --------------------------------------------------------------------------------
    const getLoteId = (properties) => {
        try {
            if (!properties || typeof properties !== 'object') {
                console.warn('getLoteId: properties no es un objeto válido.', properties);
                return 'Sin ID';
            }

            const keys = Object.keys(properties).map((key) => key.toLowerCase());
            const idLoteIndex = keys.indexOf('id_lote');
            const idIndex = keys.indexOf('id');

            if (idLoteIndex !== -1) {
                return properties[Object.keys(properties)[idLoteIndex]] || 'Sin ID';
            } else if (idIndex !== -1) {
                return properties[Object.keys(properties)[idIndex]] || 'Sin ID';
            } else {
                return 'Sin ID';
            }
        } catch (error) {
            console.error('Error en getLoteId:', error);
            return 'Sin ID';
        }
    };

    // --------------------------------------------------------------------------------
    //   Datos de polígonos provenientes de polygonsData (ya en formato GeoJSON)
    // --------------------------------------------------------------------------------
    const polygonDataFeatures = useMemo(() => {
        try {
            if (!Array.isArray(polygonsData)) {
                console.warn('polygonDataFeatures: "polygonsData" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }

            const features = polygonsData
                .map((feature, index) => {
                    try {
                        if (!feature || typeof feature !== 'object') {
                            console.warn(`polygonDataFeatures: Item inválido en index ${index}`, feature);
                            return null;
                        }

                        if (!feature.geometry || typeof feature.geometry !== 'object') {
                            console.warn(
                                `polygonDataFeatures: geometry ausente o inválida en index ${index}`,
                                feature
                            );
                            return null;
                        }

                        const { type, coordinates } = feature.geometry;
                        if (!type || !Array.isArray(coordinates)) {
                            console.warn(
                                `polygonDataFeatures: geometry con formato inválido en index ${index}`,
                                feature
                            );
                            return null;
                        }

                        const cleanPolygon = (coordsArray) => {
                            return coordsArray
                                .map((coord) => {
                                    if (Array.isArray(coord) && coord.length >= 2) {
                                        const [lng, lat] = coord;
                                        if (typeof lng === 'number' && typeof lat === 'number') {
                                            return [lng, lat];
                                        }
                                    }
                                    return null;
                                })
                                .filter(Boolean);
                        };

                        if (type === 'Polygon') {
                            const validCoordinates = coordinates
                                .map((ring) => (Array.isArray(ring) ? cleanPolygon(ring) : []))
                                .filter((ring) => ring.length > 0);

                            if (validCoordinates.length === 0) {
                                console.warn(
                                    `polygonDataFeatures: Polygon vacío/invalid en index ${index}.`
                                );
                                return null;
                            }

                            return {
                                type: 'Feature',
                                id: feature.id !== undefined ? feature.id : `polygons-data-feature-${index}`,
                                geometry: {
                                    type: 'Polygon',
                                    coordinates: validCoordinates,
                                },
                                properties: {
                                    ...feature.properties,
                                },
                            };
                        } else if (type === 'MultiPolygon') {
                            const validCoordinates = coordinates
                                .map((polygon) => {
                                    if (!Array.isArray(polygon)) return null;
                                    return polygon
                                        .map((ring) => (Array.isArray(ring) ? cleanPolygon(ring) : []))
                                        .filter((r) => r.length > 0);
                                })
                                .filter((poly) => Array.isArray(poly) && poly.length > 0);

                            if (validCoordinates.length === 0) {
                                console.warn(
                                    `polygonDataFeatures: MultiPolygon vacío/invalid en index ${index}.`
                                );
                                return null;
                            }

                            return {
                                type: 'Feature',
                                id: feature.id !== undefined ? feature.id : `polygons-data-multi-feature-${index}`,
                                geometry: {
                                    type: 'MultiPolygon',
                                    coordinates: validCoordinates,
                                },
                                properties: {
                                    ...feature.properties,
                                },
                            };
                        } else {
                            console.warn(
                                `polygonDataFeatures: Tipo de geometría "${type}" no soportado en index ${index}.`
                            );
                            return null;
                        }
                    } catch (innerErr) {
                        console.error('Error interno procesando polygonDataFeatures item:', innerErr, feature);
                        return null;
                    }
                })
                .filter(Boolean);

            return { type: 'FeatureCollection', features };
        } catch (error) {
            console.error('Error en polygonDataFeatures useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [polygonsData]);

    const polygonDataMemo = useMemo(() => {
        try {
            if (!Array.isArray(polygons)) {
                console.warn('polygonDataMemo: "polygons" no es un arreglo.');
                return { type: "FeatureCollection", features: [] };
            }

            let foundInvalidCoords = false;

            let largestPolygonIndex = -1;
            let largestPolygonArea = 0;

            if (lines.length > 0 && polygons.length > 0) {
                polygons.forEach((polygonCoords, index) => {
                    try {
                        let coordsForTurf = null;
                        if (Array.isArray(polygonCoords)) {
                            if (polygonCoords.length > 2) {
                                coordsForTurf = polygonCoords
                                    .map((coord) =>
                                        Array.isArray(coord) && coord.length >= 2
                                            ? [coord[1], coord[0]]
                                            : null
                                    )
                                    .filter(Boolean);
                            }
                        } else if (
                            polygonCoords &&
                            typeof polygonCoords === "object" &&
                            polygonCoords.geometry &&
                            Array.isArray(polygonCoords.geometry.coordinates)
                        ) {
                            const { geometry } = polygonCoords;
                            if (geometry.type === "Polygon") {
                                let raw = geometry.coordinates;
                                if (!Array.isArray(raw[0])) {
                                    raw = [raw];
                                }
                                coordsForTurf = raw[0]
                                    .map((coord) =>
                                        Array.isArray(coord) && coord.length >= 2
                                            ? [coord[1], coord[0]]
                                            : null
                                    )
                                    .filter(Boolean);
                            } else if (geometry.type === "MultiPolygon") {
                                const raw = geometry.coordinates;
                                if (
                                    Array.isArray(raw) &&
                                    raw.length > 0 &&
                                    Array.isArray(raw[0]) &&
                                    Array.isArray(raw[0][0])
                                ) {
                                    coordsForTurf = raw[0][0]
                                        .map((coord) =>
                                            Array.isArray(coord) && coord.length >= 2
                                                ? [coord[1], coord[0]]
                                                : null
                                        )
                                        .filter(Boolean);
                                }
                            }
                        }
                        if (coordsForTurf && coordsForTurf.length > 2) {
                            const turfPolygon = turf.polygon([coordsForTurf]);
                            const area = turf.area(turfPolygon);
                            if (area > largestPolygonArea) {
                                largestPolygonArea = area;
                                largestPolygonIndex = index;
                            }
                        }
                    } catch (errLargest) {
                        console.error("Error calculando área de un polígono:", errLargest);
                    }
                });
            }

            const polygonFeatures = polygons
                .map((polygonCoords, index) => {
                    try {
                        let coordinates = null;
                        let properties = {};
                        let idFeature = `polygon-memo-${index}`;

                        if (Array.isArray(polygonCoords)) {
                            const loteId = getLoteId(polygonProperties[index] || {});
                            const isActive = activeLotes.includes(loteId);
                            const originalProps = polygonProperties[index] || {};
                            const finalProps = {
                                ...originalProps,
                                ...(originalProps.INTERSECCION === "YES" && { color: "red" }),
                            };
                            if (index === largestPolygonIndex && largestPolygonIndex !== -1) {
                                finalProps.color = "yellow";
                            }
                            coordinates = polygonCoords
                                .map((coord) =>
                                    Array.isArray(coord) && coord.length >= 2
                                        ? [coord[1], coord[0]]
                                        : null
                                )
                                .filter((c) => c !== null);

                            if (coordinates.length === 0) {
                                foundInvalidCoords = true;
                                return null;
                            }
                            properties = {
                                active: isActive,
                                loteId,
                                ...finalProps,
                            };
                        } else if (
                            polygonCoords &&
                            typeof polygonCoords === "object" &&
                            polygonCoords.geometry &&
                            Array.isArray(polygonCoords.geometry.coordinates)
                        ) {
                            const { geometry } = polygonCoords;
                            let rawCoords = geometry.coordinates;
                            if (geometry.type === "Polygon") {
                                if (!Array.isArray(rawCoords[0])) {
                                    rawCoords = [rawCoords];
                                }

                                const convertedRings = rawCoords.map((ring) =>
                                    ring
                                        .map((c) =>
                                            Array.isArray(c) && c.length >= 2 ? [c[1], c[0]] : null
                                        )
                                        .filter(Boolean)
                                );
                                if (convertedRings.length < 1 || convertedRings[0].length < 3) {
                                    foundInvalidCoords = true;
                                    return null;
                                }
                                const first = convertedRings[0][0];
                                const last =
                                    convertedRings[0][convertedRings[0].length - 1];
                                if (first[0] !== last[0] || first[1] !== last[1]) {
                                    convertedRings[0].push(first);
                                }
                                coordinates = convertedRings[0];
                                properties = polygonCoords.properties || {};
                                if (properties.INTERSECCION === "YES") {
                                    properties.color = "red";
                                }
                                idFeature = polygonCoords.id || `polygon-memo-${index}`;
                            } else if (geometry.type === "MultiPolygon") {

                                const raw = geometry.coordinates;
                                if (
                                    Array.isArray(raw) &&
                                    raw.length > 0 &&
                                    Array.isArray(raw[0]) &&
                                    Array.isArray(raw[0][0])
                                ) {
                                    const ring = raw[0][0].map((c) =>
                                        Array.isArray(c) && c.length >= 2
                                            ? [c[1], c[0]]
                                            : null
                                    );
                                    coordinates = ring.filter(Boolean);
                                    properties = polygonCoords.properties || {};
                                    if (properties.INTERSECCION === "YES") {
                                        properties.color = "red";
                                    }
                                    idFeature = polygonCoords.id || `polygon-memo-${index}`;
                                }
                            }
                        } else {
                            foundInvalidCoords = true;
                            return null;
                        }

                        if (coordinates.length < 4) {
                            foundInvalidCoords = true;
                            return null;
                        }
                        const first = coordinates[0];
                        const last = coordinates[coordinates.length - 1];
                        if (first[0] !== last[0] || first[1] !== last[1]) {
                            coordinates.push(first);
                        }

                        return {
                            type: "Feature",
                            id: idFeature,
                            geometry: {
                                type: "Polygon",
                                coordinates: [coordinates],
                            },
                            properties,
                        };
                    } catch (innerErr) {
                        console.error(
                            "Error interno procesando un polígono en polygonDataMemo:",
                            innerErr,
                            polygonCoords
                        );
                        return null;
                    }
                })
                .filter(Boolean);

            if (foundInvalidCoords && !hasWarnedInvalidCoords) {
                console.warn(
                    "polygonDataMemo: Se encontraron coordenadas inválidas en al menos un polígono."
                );
                setHasWarnedInvalidCoords(true);
            }

            const normalFeatures = [];
            const intersectionFeatures = [];
            polygonFeatures.forEach((feature) => {
                if (feature.properties.INTERSECCION === "YES") {
                    intersectionFeatures.push(feature);
                } else {
                    normalFeatures.push(feature);
                }
            });

            const finalFeatures = [...normalFeatures, ...intersectionFeatures];

            const filteredFeatures =
                activeLotes.length === 0
                    ? finalFeatures
                    : finalFeatures.filter((feat) =>
                        activeLotes.includes(feat.properties.loteId)
                    );

            return { type: "FeatureCollection", features: filteredFeatures };
        } catch (error) {
            console.error("Error en polygonDataMemo useMemo:", error);
            return { type: "FeatureCollection", features: [] };
        }
    }, [
        polygons,
        activeLotes,
        polygonProperties,
        hasWarnedInvalidCoords,
        lines,
    ]);


    // --------------------------------------------------------------------------------
    //   Datos de "bufferedLines" (polígonos buffer de las líneas)
    // --------------------------------------------------------------------------------
    const bufferedLinesData = useMemo(() => {
        try {
            if (!Array.isArray(bufferedLines)) {
                console.warn('bufferedLinesData: "bufferedLines" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }
            const features = getPolygonFeatures(bufferedLines);
            return { type: 'FeatureCollection', features };
        } catch (error) {
            console.error('Error en bufferedLinesData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [bufferedLines, getPolygonFeatures]);

    // --------------------------------------------------------------------------------
    //    Datos de "bufferedIntersections" (intersecciones bufferizadas)
    // --------------------------------------------------------------------------------
    const bufferedIntersectionsData = useMemo(() => {
        try {
            if (!Array.isArray(bufferedIntersections)) {
                console.warn('bufferedIntersectionsData: "bufferedIntersections" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }
            const features = getPolygonFeatures(bufferedIntersections);
            return { type: 'FeatureCollection', features: features };
        } catch (error) {
            console.error('Error en bufferedIntersectionsData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [bufferedIntersections, getPolygonFeatures]);

    // --------------------------------------------------------------------------------
    //               Datos de áreas superpuestas (areasSuperpuestas)
    // --------------------------------------------------------------------------------
    const areasSuperpuestasData = useMemo(() => {
        try {
            if (!Array.isArray(areasSuperpuestas)) {
                console.warn('areasSuperpuestasData: "areasSuperpuestas" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }

            const processedAreas = areasSuperpuestas
                .map((item) => {
                    try {
                        if (item && item.geometry && item.geometry.coordinates) {
                            let geometry = item.geometry;
                            if (geometry.type === 'Polygon') {
                                geometry = {
                                    ...geometry,
                                    coordinates: geometry.coordinates
                                        .map((ring) =>
                                            ring
                                                .map((coord) =>
                                                    Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                                                )
                                                .filter((c) => c !== null)
                                        )
                                        .filter((ring) => ring.length > 0),
                                };
                            } else if (geometry.type === 'MultiPolygon') {
                                geometry = {
                                    ...geometry,
                                    coordinates: geometry.coordinates
                                        .map((polygon) =>
                                            polygon
                                                .map((ring) =>
                                                    ring
                                                        .map((coord) =>
                                                            Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                                                        )
                                                        .filter((c) => c !== null)
                                                )
                                                .filter((ring) => ring.length > 0)
                                        )
                                        .filter((polygon) => polygon.length > 0),
                                };
                            }
                            return { ...item, geometry };
                        } else if (Array.isArray(item)) {
                            const coordinates = item
                                .map((coord) =>
                                    Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                                )
                                .filter((c) => c !== null);
                            if (coordinates.length > 0) {
                                return {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Polygon',
                                        coordinates: [coordinates],
                                    },
                                    properties: {},
                                };
                            }
                        }
                        console.warn('areasSuperpuestasData: Item inválido.', item);
                        return null;
                    } catch (innerErr) {
                        console.error('Error interno procesando un item en areasSuperpuestasData:', innerErr, item);
                        return null;
                    }
                })
                .filter(Boolean);

            const features = getPolygonFeatures(processedAreas);
            return { type: 'FeatureCollection', features };
        } catch (error) {
            console.error('Error en areasSuperpuestasData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [areasSuperpuestas, getPolygonFeatures]);

    // --------------------------------------------------------------------------------
    //           Datos de áreas NO intersectadas (nonIntersectedAreas)
    // --------------------------------------------------------------------------------
    const nonIntersectedAreasData = useMemo(() => {
        try {
            if (!Array.isArray(nonIntersectedAreas)) {
                console.warn('nonIntersectedAreasData: "nonIntersectedAreas" no es un arreglo.');
                return { type: 'FeatureCollection', features: [] };
            }

            const processedAreas = nonIntersectedAreas
                .map((item) => {
                    try {
                        if (item && item.geometry && item.geometry.coordinates) {
                            let geometry = item.geometry;
                            if (geometry.type === 'Polygon') {
                                geometry = {
                                    ...geometry,
                                    coordinates: geometry.coordinates
                                        .map((ring) =>
                                            ring
                                                .map((coord) =>
                                                    Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                                                )
                                                .filter((c) => c !== null)
                                        )
                                        .filter((ring) => ring.length > 0),
                                };
                            } else if (geometry.type === 'MultiPolygon') {
                                geometry = {
                                    ...geometry,
                                    coordinates: geometry.coordinates
                                        .map((polygon) =>
                                            polygon
                                                .map((ring) =>
                                                    ring
                                                        .map((coord) =>
                                                            Array.isArray(coord) && coord.length >= 2
                                                                ? [coord[0], coord[1]]
                                                                : null
                                                        )
                                                        .filter((c) => c !== null)
                                                )
                                                .filter((ring) => ring.length > 0)
                                        )
                                        .filter((polygon) => polygon.length > 0),
                                };
                            }
                            return { ...item, geometry };
                        } else if (Array.isArray(item)) {
                            const coordinates = item
                                .map((coord) =>
                                    Array.isArray(coord) && coord.length >= 2 ? [coord[0], coord[1]] : null
                                )
                                .filter((c) => c !== null);
                            if (coordinates.length > 0) {
                                return {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Polygon',
                                        coordinates: [coordinates],
                                    },
                                    properties: {},
                                };
                            }
                        }
                        console.warn('nonIntersectedAreasData: Item inválido.', item);
                        return null;
                    } catch (innerErr) {
                        console.error(
                            'Error interno procesando un item en nonIntersectedAreasData:',
                            innerErr,
                            item
                        );
                        return null;
                    }
                })
                .filter(Boolean);

            const features = getPolygonFeatures(processedAreas);
            return { type: 'FeatureCollection', features };
        } catch (error) {
            console.error('Error en nonIntersectedAreasData useMemo:', error);
            return { type: 'FeatureCollection', features: [] };
        }
    }, [nonIntersectedAreas, getPolygonFeatures]);

    // --------------------------------------------------------------------------------
    //    Ajuste automático de bounds según datos que existan (una sola vez)
    // --------------------------------------------------------------------------------
    const hasFitBoundsRef = useRef({
        points: false,
        lines: false,
        polygons: false,
        polygonsData: false,
        lineasNoFiltradas: false,
        image: false,
    });

    const fitBoundsToData = useCallback((dataFeatures) => {
        try {
            if (localMapRef.current && localMapRef.current.getMap()) {
                const map = localMapRef.current.getMap();
                const bounds = new mapboxgl.LngLatBounds();

                dataFeatures.forEach((feature) => {
                    try {
                        const geometry = feature.geometry;
                        if (!geometry) {
                            console.warn('fitBoundsToData: Feature sin geometría.', feature);
                            return;
                        }
                        const { type, coordinates } = geometry;

                        switch (type) {
                            case 'Point':
                                bounds.extend(coordinates);
                                break;
                            case 'LineString':
                                coordinates.forEach((coord) => bounds.extend(coord));
                                break;
                            case 'Polygon':
                                coordinates.forEach((ring) => {
                                    ring.forEach((coord) => bounds.extend(coord));
                                });
                                break;
                            case 'MultiPolygon':
                                coordinates.forEach((polygon) => {
                                    polygon.forEach((ring) => {
                                        ring.forEach((coord) => bounds.extend(coord));
                                    });
                                });
                                break;
                            default:
                                console.warn(`fitBoundsToData: Tipo de geometría "${type}" no soportado.`);
                        }
                    } catch (geomErr) {
                        console.error('Error procesando feature en fitBoundsToData:', geomErr, feature);
                    }
                });

                if (!bounds.isEmpty()) {
                    map.fitBounds(bounds, { padding: 20 });
                }
            }
        } catch (error) {
            console.error('Error en fitBoundsToData:', error);
        }
    }, []);

    useEffect(() => {
        try {
            if (mapLoaded && localMapRef.current && localMapRef.current.getMap()) {
                const map = localMapRef.current.getMap();
                const bounds = new mapboxgl.LngLatBounds();
                let shouldFitBounds = false;

                // 1) Imagen overlay
                if (imageUrl && northWestCoords && southEastCoords && !hasFitBoundsRef.current.image) {
                    try {
                        bounds.extend([northWestCoords[0], northWestCoords[1]]);
                        bounds.extend([southEastCoords[0], northWestCoords[1]]);
                        bounds.extend([southEastCoords[0], southEastCoords[1]]);
                        bounds.extend([northWestCoords[0], southEastCoords[1]]);
                        hasFitBoundsRef.current.image = true;
                        shouldFitBounds = true;
                    } catch (errImg) {
                        console.error('Error al ajustar bounds para image overlay:', errImg);
                    }
                }

                // 2) Puntos
                if (pointData.features.length > 0 && !hasFitBoundsRef.current.points) {
                    try {
                        pointData.features.forEach((feature) => {
                            if (feature.geometry && feature.geometry.coordinates) {
                                bounds.extend(feature.geometry.coordinates);
                            }
                        });
                        hasFitBoundsRef.current.points = true;
                        shouldFitBounds = true;
                    } catch (errPoints) {
                        console.error('Error al ajustar bounds para puntos:', errPoints);
                    }
                }

                // 3) Líneas
                if (lineData.features.length > 0 && !hasFitBoundsRef.current.lines) {
                    try {
                        lineData.features.forEach((feature) => {
                            if (feature.geometry && feature.geometry.type === 'LineString') {
                                feature.geometry.coordinates.forEach((coord) => bounds.extend(coord));
                            }
                        });
                        hasFitBoundsRef.current.lines = true;
                        shouldFitBounds = true;
                    } catch (errLines) {
                        console.error('Error al ajustar bounds para líneas:', errLines);
                    }
                }

                // 4) Líneas no filtradas
                if (
                    lineasNoFiltradasData.features.length > 0 &&
                    !hasFitBoundsRef.current.lineasNoFiltradas
                ) {
                    try {
                        lineasNoFiltradasData.features.forEach((feature) => {
                            if (feature.geometry.type === 'LineString') {
                                feature.geometry.coordinates.forEach((coord) => bounds.extend(coord));
                            } else if (feature.geometry.type === 'MultiLineString') {
                                feature.geometry.coordinates.forEach((line) => {
                                    line.forEach((coord) => bounds.extend(coord));
                                });
                            }
                        });
                        hasFitBoundsRef.current.lineasNoFiltradas = true;
                        shouldFitBounds = true;
                    } catch (errNoFiltradas) {
                        console.error('Error al ajustar bounds para líneas no filtradas:', errNoFiltradas);
                    }
                }

                // 5) Polígonos "polygonDataMemo"
                if (polygonDataMemo.features.length > 0 && !hasFitBoundsRef.current.polygons) {
                    try {
                        polygonDataMemo.features.forEach((feature) => {
                            if (feature.geometry && feature.geometry.type === 'Polygon') {
                                feature.geometry.coordinates.forEach((ring) => {
                                    ring.forEach((coord) => bounds.extend(coord));
                                });
                            } else if (feature.geometry && feature.geometry.type === 'MultiPolygon') {
                                feature.geometry.coordinates.forEach((polygon) => {
                                    polygon.forEach((ring) => {
                                        ring.forEach((coord) => bounds.extend(coord));
                                    });
                                });
                            }
                        });
                        hasFitBoundsRef.current.polygons = true;
                        shouldFitBounds = true;
                    } catch (errPolygons) {
                        console.error('Error al ajustar bounds para polígonos:', errPolygons);
                    }
                }

                // 6) Polígonos "polygonDataFeatures"
                if (
                    polygonDataFeatures.features.length > 0 &&
                    !hasFitBoundsRef.current.polygonsData
                ) {
                    try {
                        polygonDataFeatures.features.forEach((feature) => {
                            if (feature.geometry && feature.geometry.type === 'Polygon') {
                                feature.geometry.coordinates.forEach((ring) => {
                                    ring.forEach((coord) => bounds.extend(coord));
                                });
                            } else if (
                                feature.geometry &&
                                feature.geometry.type === 'MultiPolygon'
                            ) {
                                feature.geometry.coordinates.forEach((polygon) => {
                                    polygon.forEach((ring) => {
                                        ring.forEach((coord) => bounds.extend(coord));
                                    });
                                });
                            }
                        });
                        hasFitBoundsRef.current.polygonsData = true;
                        shouldFitBounds = true;
                    } catch (errPolygonsData) {
                        console.error('Error al ajustar bounds para polígonos de datos:', errPolygonsData);
                    }
                }

                if (shouldFitBounds && !bounds.isEmpty()) {
                    try {
                        map.fitBounds(bounds, { padding: 20 });
                    } catch (errFit) {
                        console.error('Error al llamar a fitBounds:', errFit);
                    }
                }
            }
        } catch (error) {
            console.error('Error en el useEffect de fitBounds:', error);
        }
    }, [
        mapLoaded,
        pointData.features.length,
        lineData.features.length,
        polygonDataMemo.features.length,
        polygonDataFeatures.features.length,
        lineasNoFiltradasData.features.length,
        imageUrl,
        northWestCoords,
        southEastCoords,
        fitBoundsToData,
    ]);

    // --------------------------------------------------------------------------------
    //  Botones para centrar el mapa en "polygonsData" o en el "analysis" (líneas/puntos)
    // --------------------------------------------------------------------------------
    const handleCenterToPolygonsData = useCallback(() => {
        try {
            if (polygonDataFeatures.features.length > 0 && localMapRef.current) {
                fitBoundsToData(polygonDataFeatures.features);
            } else {
                console.warn('handleCenterToPolygonsData: No hay características de polígonos para centrar.');
            }
        } catch (error) {
            console.error('Error en handleCenterToPolygonsData:', error);
        }
    }, [polygonDataFeatures.features, fitBoundsToData]);

    const handleCenterToAnalysis = useCallback(() => {
        try {
            if (localMapRef.current) {
                const map = localMapRef.current.getMap();
                if (pointData.features.length > 0) {
                    fitBoundsToData(pointData.features);
                } else if (lineData.features.length > 0) {
                    fitBoundsToData(lineData.features);
                } else if (polygonDataMemo.features.length > 0) {
                    fitBoundsToData(polygonDataMemo.features);
                } else if (imageUrl && northWestCoords && southEastCoords) {
                    const bounds = new mapboxgl.LngLatBounds();
                    bounds.extend([northWestCoords[0], northWestCoords[1]]);
                    bounds.extend([southEastCoords[0], northWestCoords[1]]);
                    bounds.extend([southEastCoords[0], southEastCoords[1]]);
                    bounds.extend([northWestCoords[0], southEastCoords[1]]);
                    map.fitBounds(bounds, { padding: 20 });
                } else {
                    console.warn('handleCenterToAnalysis: No hay datos disponibles para centrar.');
                }
            }
        } catch (error) {
            console.error('Error en handleCenterToAnalysis:', error);
        }
    }, [
        localMapRef,
        pointData.features,
        lineData.features,
        polygonDataMemo.features,
        imageUrl,
        northWestCoords,
        southEastCoords,
        fitBoundsToData,
    ]);

    // --------------------------------------------------------------------------------
    //  Manejo de eventos (click, hover, mouseout) en las "lines" (lines-layer)
    // --------------------------------------------------------------------------------
    const onLineClickRef = useRef(onLineClick);
    const onLineHoverRef = useRef(onLineHover);
    const onLineMouseOutRef = useRef(onLineMouseOut);

    useEffect(() => {
        onLineClickRef.current = onLineClick;
    }, [onLineClick]);

    useEffect(() => {
        onLineHoverRef.current = onLineHover;
    }, [onLineHover]);

    useEffect(() => {
        onLineMouseOutRef.current = onLineMouseOut;
    }, [onLineMouseOut]);

    useEffect(() => {
        try {
            if (!mapLoaded || !localMapRef.current || !localMapRef.current.getMap()) return;

            const map = localMapRef.current.getMap();

            const handleClick = (e) => {
                try {
                    const features = e.features;
                    if (features && features.length > 0) {
                        onLineClickRef.current(e);
                    }
                } catch (error) {
                    console.error('handleClick en líneas:', error);
                }
            };

            const handleMouseMove = (e) => {
                try {
                    if (e.features && e.features.length > 0) {
                        const feature = e.features[0];
                        if (feature.layer && feature.layer.id === 'lines-layer') {
                            map.getCanvas().style.cursor = 'pointer';
                            map.setFeatureState({ source: 'lines', id: feature.id }, { hover: true });
                            onLineHoverRef.current(feature);
                        }
                    }
                } catch (error) {
                    console.error('handleMouseMove en líneas:', error);
                }
            };

            const handleMouseLeave = () => {
                try {
                    map.getCanvas().style.cursor = '';
                    const features = map.querySourceFeatures('lines');
                    features.forEach((feature) => {
                        map.setFeatureState({ source: 'lines', id: feature.id }, { hover: false });
                    });
                    onLineMouseOutRef.current();
                } catch (error) {
                    console.error('handleMouseLeave en líneas:', error);
                }
            };

            map.on('click', 'lines-layer', handleClick);
            map.on('mousemove', 'lines-layer', handleMouseMove);
            map.on('mouseleave', 'lines-layer', handleMouseLeave);

            return () => {
                try {
                    map.off('click', 'lines-layer', handleClick);
                    map.off('mousemove', 'lines-layer', handleMouseMove);
                    map.off('mouseleave', 'lines-layer', handleMouseLeave);
                } catch (error) {
                    console.error('Error al remover eventos de líneas:', error);
                }
            };
        } catch (error) {
            console.error('Error en useEffect de eventos de líneas:', error);
        }
    }, [mapLoaded]);

    // --------------------------------------------------------------------------------
    //             Manejo de selectedFiles (cargas de capas GeoJSON/imagen)
    // --------------------------------------------------------------------------------
    const [selectedFilesData, setSelectedFilesData] = useState([]);

    const getAnalysisTypeFromFileName = (fileName) => {
        try {
            if (!fileName || typeof fileName !== 'string') return 'Desconocido';
            if (fileName.includes('aplicaciones_aereas')) return 'Aplicaciones aéreas';
            if (fileName.includes('cosecha_mecanica')) return 'Cosecha mecánica';
            if (fileName.includes('conteo_de_palmas')) return 'Conteo de palmas';
            return 'Desconocido';
        } catch (error) {
            console.error('Error en getAnalysisTypeFromFileName:', error);
            return 'Desconocido';
        }
    };

    const isImageFile = (fileName) => {
        try {
            if (!fileName || typeof fileName !== 'string') return false;
            const ext = fileName.split('.').pop().toLowerCase();
            return ['jpg', 'jpeg', 'tif', 'tiff', 'png'].includes(ext);
        } catch (error) {
            console.error('Error en isImageFile:', error);
            return false;
        }
    };

    useEffect(() => {
        const loadSelectedFiles = async () => {
            try {
                if (!Array.isArray(selectedFiles)) {
                    console.warn('loadSelectedFiles: "selectedFiles" no es un arreglo.');
                    setSelectedFilesData([]);
                    return;
                }

                const results = [];

                for (let i = 0; i < selectedFiles.length; i++) {
                    try {
                        const file = selectedFiles[i];
                        const analysisType = getAnalysisTypeFromFileName(file.file_name || '');
                        const date = file.updated || 'Fecha desconocida';
                        const isFiltradas = file.file_name.includes('_filtradas');
                        const isNoFiltradas = file.file_name.includes('_no_filtradas');

                        if (isImageFile(file.file_name)) {
                            results.push({
                                type: 'image',
                                id: `selectedfile-image-${i}`,
                                imageUrl: file.signed_url,
                                coordinates: null,
                                analysisType,
                                date,
                            });
                        } else {
                            // Asumir que es un GeoJSON
                            try {
                                const resp = await axios.get(file.signed_url);
                                let data = resp.data;

                                if (data.type === 'Feature') {
                                    data = { type: 'FeatureCollection', features: [data] };
                                } else if (data.type !== 'FeatureCollection') {
                                    data = { type: 'FeatureCollection', features: [] };
                                }

                                data.features = data.features.map((f) => ({
                                    ...f,
                                    properties: {
                                        ...f.properties,
                                        analysisType,
                                        date,
                                        filtradoEstado: isFiltradas
                                            ? 'filtradas'
                                            : isNoFiltradas
                                                ? 'no_filtradas'
                                                : 'filtradas',
                                    },
                                }));

                                results.push({
                                    type: 'geojson',
                                    id: `selectedfile-geojson-${i}`,
                                    data,
                                    analysisType,
                                    date,
                                });
                            } catch (fileErr) {
                                console.error('Error al cargar el archivo geojson:', fileErr);
                                results.push({
                                    type: 'geojson',
                                    id: `selectedfile-geojson-${i}`,
                                    data: { type: 'FeatureCollection', features: [] },
                                    analysisType,
                                    date,
                                });
                            }
                        }
                    } catch (loopErr) {
                        console.error(`Error en iteración de selectedFiles index ${i}:`, loopErr);
                    }
                }

                setSelectedFilesData(results);
            } catch (error) {
                console.error('Error en loadSelectedFiles:', error);
                setSelectedFilesData([]);
            }
        };
        loadSelectedFiles();
    }, [selectedFiles]);

    // --------------------------------------------------------------------------------
    //  Manejo de popup para los selectedFiles
    // --------------------------------------------------------------------------------
    const [selectedFilesPopup, setSelectedFilesPopup] = useState(null);

    const interactiveLayers = useMemo(() => {
        try {
            const layers = [];
            selectedFilesData.forEach((fileData, index) => {
                if (fileData.type === 'geojson') {
                    layers.push(`selectedfile-polygon-layer-normal-${index}`);
                    layers.push(`selectedfile-polygon-layer-overlap-${index}`);
                    layers.push(`selectedfile-line-layer-${index}`);
                    layers.push(`selectedfile-point-layer-${index}`);
                } else if (fileData.type === 'image' && fileData.coordinates) {
                    layers.push(`${fileData.id}-layer`);
                }
            });
            return layers;
        } catch (error) {
            console.error('Error en interactiveLayers useMemo:', error);
            return [];
        }
    }, [selectedFilesData]);

    useEffect(() => {
        try {
            if (!mapLoaded || !localMapRef.current || !localMapRef.current.getMap()) return;

            const map = localMapRef.current.getMap();

            const handleClick = (e) => {
                try {
                    if (e.features && e.features.length > 0) {
                        const feat = e.features[0];
                        if (feat.layer && feat.layer.id && feat.properties && feat.properties.analysisType) {
                            const lngLat = e.lngLat;
                            const content = (
                                <div>
                                    <strong>Análisis:</strong> {feat.properties.analysisType}
                                    <br />
                                    <strong>Fecha:</strong> {feat.properties.date}
                                </div>
                            );
                            setSelectedFilesPopup({ lngLat, content });
                        }
                    }
                } catch (error) {
                    console.error('handleClick en selectedFiles:', error);
                }
            };

            map.on('click', handleClick);

            return () => {
                try {
                    map.off('click', handleClick);
                } catch (error) {
                    console.error('Error al remover handleClick en selectedFiles:', error);
                }
            };
        } catch (error) {
            console.error('Error en useEffect de handleClick en selectedFiles:', error);
        }
    }, [mapLoaded, selectedFilesData]);

    const polygonLayerSelectedNormal = (id) => ({
        id: `selectedfile-polygon-layer-normal-${id}`,
        type: 'fill',
        paint: {
            'fill-color': '#198C19',
            'fill-opacity': 0.5,
            'fill-outline-color': '#198C19',
        },
        filter: ['all', ['==', '$type', 'Polygon'], ['!=', ['get', 'type'], 'overlap']],
    });

    const polygonLayerSelectedOverlap = (id) => ({
        id: `selectedfile-polygon-layer-overlap-${id}`,
        type: 'fill',
        paint: {
            'fill-color': '#FF0000',
            'fill-opacity': 0.7,
            'fill-outline-color': '#FF0000',
        },
        filter: ['all', ['==', '$type', 'Polygon'], ['==', ['get', 'type'], 'overlap']],
    });

    const lineLayerSelected = (id) => ({
        id: `selectedfile-line-layer-${id}`,
        type: 'line',
        paint: {
            'line-color': [
                'match',
                ['get', 'filtradoEstado'],
                'filtradas',
                '#ff0000',
                'no_filtradas',
                '#8C8C8C',
                '#8C8C8C',
            ],
            'line-width': 2,
        },
        filter: ['==', '$type', 'LineString'],
    });

    const pointLayerSelected = (id) => ({
        id: `selectedfile-point-layer-${id}`,
        type: 'circle',
        paint: {
            'circle-radius': 5,
            'circle-color': '#198C19',
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff',
        },
        filter: ['==', '$type', 'Point'],
    });

    // --------------------------------------------------------------------------------
    //              Controles de capas (LayersIcon) - menú de estilos
    // --------------------------------------------------------------------------------
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleLayersClick = (event) => {
        try {
            setAnchorEl(event.currentTarget);
        } catch (error) {
            console.error('Error en handleLayersClick:', error);
        }
    };

    const handleLayersClose = () => {
        try {
            setAnchorEl(null);
        } catch (error) {
            console.error('Error en handleLayersClose:', error);
        }
    };

    const handleStyleSelect = (styleName) => {
        try {
            setSelectedStyle(styleName);
            setMapStyle(mapStyles[styleName]);
            handleLayersClose();
        } catch (error) {
            console.error('Error en handleStyleSelect:', error);
        }
    };

    // --------------------------------------------------------------------------------
    //      Manejo de selección y popup en polígonos (polygons-data-layer)
    // --------------------------------------------------------------------------------
    const handlePolygonsDataClick = useCallback(
        (event) => {
            if (isEditing) return;
            try {
                const feature = event.features && event.features[0];
                if (feature) {
                    const map = localMapRef.current.getMap();
                    const featureId = feature.id;

                    if (selectedPolygonId === featureId) {
                        // Deseleccionar el polígono
                        map.setFeatureState({ source: 'polygons-data', id: featureId }, { selected: false });
                        setSelectedPolygonId(null);
                        setSelectedPopup(null);
                    } else {
                        // Deseleccionar el anterior
                        if (selectedPolygonId !== null) {
                            map.setFeatureState(
                                { source: 'polygons-data', id: selectedPolygonId },
                                { selected: false }
                            );
                        }

                        // Seleccionar el nuevo
                        map.setFeatureState({ source: 'polygons-data', id: featureId }, { selected: true });
                        setSelectedPolygonId(featureId);

                        // Generar contenido del popup
                        const properties = feature.properties || {};
                        const content = Object.entries(properties).map(([key, value]) => (
                            <div key={key}>
                                <strong>{key.replace(/_/g, ' ')}:</strong> {value}
                            </div>
                        ));

                        setSelectedPopup({
                            lngLat: event.lngLat,
                            content: <div>{content}</div>,
                        });

                        onSelectLote(properties.loteId || properties.ID || 'Sin ID');
                    }
                }
            } catch (error) {
                console.error('Error en handlePolygonsDataClick:', error);
            }
        },
        [selectedPolygonId, onSelectLote, isEditing]
    );

    useEffect(() => {
        try {
            if (!mapLoaded || !localMapRef.current || !localMapRef.current.getMap()) return;
            const map = localMapRef.current.getMap();

            map.on('click', 'polygons-data-layer', handlePolygonsDataClick);

            map.on('mouseenter', 'polygons-data-layer', () => {
                try {
                    map.getCanvas().style.cursor = 'pointer';
                } catch (errEnter) {
                    console.error('Error en mouseenter de polygons-data-layer:', errEnter);
                }
            });

            map.on('mouseleave', 'polygons-data-layer', () => {
                try {
                    map.getCanvas().style.cursor = '';
                } catch (errLeave) {
                    console.error('Error en mouseleave de polygons-data-layer:', errLeave);
                }
            });

            return () => {
                try {
                    map.off('click', 'polygons-data-layer', handlePolygonsDataClick);
                    map.off('mouseenter', 'polygons-data-layer');
                    map.off('mouseleave', 'polygons-data-layer');
                } catch (error) {
                    console.error('Error al remover eventos de polygons-data-layer:', error);
                }
            };
        } catch (error) {
            console.error('Error en useEffect de polygons-data-layer:', error);
        }
    }, [mapLoaded, handlePolygonsDataClick]);

    // --------------------------------------------------------------------------------
    //   Determinar si hay trampling lines (para ocultar/mostrar puntos)
    // --------------------------------------------------------------------------------
    const hasTramplingLines = useMemo(() => {
        if (!tramplingLines) return false;
        if (Array.isArray(tramplingLines) && tramplingLines.length > 0) {
            return true;
        }
        if (tramplingLines.url) {
            return true;
        }
        return false;
    }, [tramplingLines]);

    // --------------------------------------------------------------------------------
    //   useEffect para mostrar/ocultar capa de trampling lines (y fetch si es URL)
    // --------------------------------------------------------------------------------
    useEffect(() => {
        try {
            if (!mapLoaded || !localMapRef.current) return;

            const map = localMapRef.current.getMap();

            // Eliminar capa y fuente previa si existían
            if (map.getSource('trampling-lines')) {
                if (map.getLayer('trampling-lines-layer')) {
                    map.removeLayer('trampling-lines-layer');
                }
                map.removeSource('trampling-lines');
            }

            if (!tramplingLines) {
                return;
            }
            if (Array.isArray(tramplingLines) && tramplingLines.length === 0) {
                return;
            }

            // Caso 1: tramplingLines es un array con features
            if (Array.isArray(tramplingLines) && tramplingLines.length > 0) {
                const geojsonData = {
                    type: 'FeatureCollection',
                    features: tramplingLines.map((line, idx) => ({
                        type: 'Feature',
                        id: line.id || `trampling-line-${idx}`,
                        geometry: line.geometry,
                        properties: { ...line.properties },
                    })),
                };

                map.addSource('trampling-lines', {
                    type: 'geojson',
                    data: geojsonData,
                });

                map.addLayer({
                    id: 'trampling-lines-layer',
                    type: 'line',
                    source: 'trampling-lines',
                    paint: {
                        'line-color': '#00FFFF',
                        'line-width': 3,
                    },
                });

                // Ajustar bounds
                const bounds = new mapboxgl.LngLatBounds();
                geojsonData.features.forEach((feature) => {
                    const { type, coordinates } = feature.geometry;
                    if (type === 'LineString') {
                        coordinates.forEach((coord) => bounds.extend(coord));
                    } else if (type === 'MultiLineString') {
                        coordinates.forEach((singleLine) => {
                            singleLine.forEach((coord) => bounds.extend(coord));
                        });
                    }
                });

                if (!bounds.isEmpty()) {
                    map.fitBounds(bounds, { padding: 20 });
                }

                return;
            }

            // Caso 2: tramplingLines es un objeto con url
            if (tramplingLines.url) {
                axios
                    .get(tramplingLines.url)
                    .then((response) => {
                        const data = response.data;
                        if (
                            data &&
                            data.type === 'FeatureCollection' &&
                            Array.isArray(data.features) &&
                            data.features.length > 0
                        ) {
                            map.addSource('trampling-lines', {
                                type: 'geojson',
                                data: data,
                            });

                            map.addLayer({
                                id: 'trampling-lines-layer',
                                type: 'line',
                                source: 'trampling-lines',
                                paint: {
                                    'line-color': '#ff0000',
                                    'line-width': 3,
                                },
                            });

                            // Ajustar bounds
                            const bounds = new mapboxgl.LngLatBounds();
                            data.features.forEach((feature) => {
                                const { type, coordinates } = feature.geometry;
                                if (type === 'LineString') {
                                    coordinates.forEach((coord) => bounds.extend(coord));
                                } else if (type === 'MultiLineString') {
                                    coordinates.forEach((singleLine) => {
                                        singleLine.forEach((coord) => bounds.extend(coord));
                                    });
                                }
                            });
                            if (!bounds.isEmpty()) {
                                map.fitBounds(bounds, { padding: 20 });
                            }
                        }
                    })
                    .catch((err) => {
                        console.error('Error al obtener trampling lines desde la URL:', err);
                    });
            }
        } catch (error) {
            console.error('Error en useEffect para tramplingLines:', error);
        }
    }, [mapLoaded, tramplingLines]);

    // --------------------------------------------------------------------------------
    //                Cerrar popup de polígono seleccionado
    // --------------------------------------------------------------------------------
    const handleClosePopup = () => {
        try {
            if (selectedPolygonId !== null && localMapRef.current) {
                const map = localMapRef.current.getMap();
                map.setFeatureState({ source: 'polygons-data', id: selectedPolygonId }, { selected: false });
                setSelectedPolygonId(null);
            }
            setSelectedPopup(null);
        } catch (error) {
            console.error('Error en handleClosePopup:', error);
        }
    };

    // --------------------------------------------------------------------------------
    //                             NUEVA LÓGICA DE MEDICIÓN
    // --------------------------------------------------------------------------------
    const [measurementPoints, setMeasurementPoints] = useState([]);
    const [measurementDistance, setMeasurementDistance] = useState(0);
    const [measurementHoverCoord, setMeasurementHoverCoord] = useState(null);
    const [showMeasurementPopup, setShowMeasurementPopup] = useState(false);

    useEffect(() => {
        if (activeTool !== 'measurement') {
            setMeasurementPoints([]);
            setMeasurementDistance(0);
            setMeasurementHoverCoord(null);
            setShowMeasurementPopup(false);
        }
    }, [activeTool]);

    useEffect(() => {
        if (!mapLoaded || !localMapRef.current || activeTool !== 'measurement') return;
        const map = localMapRef.current.getMap();

        const handleMapClick = (e) => {
            if (measurementPoints.length >= 2) {
                setMeasurementPoints([e.lngLat]);
                setMeasurementDistance(0);
            } else {
                setMeasurementPoints([...measurementPoints, e.lngLat]);
            }
        };

        map.on('click', handleMapClick);

        return () => {
            map.off('click', handleMapClick);
        };
    }, [mapLoaded, activeTool, measurementPoints]);

    useEffect(() => {
        if (!mapLoaded || !localMapRef.current || activeTool !== 'measurement') return;
        const map = localMapRef.current.getMap();

        if (measurementPoints.length === 1) {
            const handleMouseMove = (e) => {
                setMeasurementHoverCoord(e.lngLat);
                if (measurementPoints[0]) {
                    const from = turf.point([measurementPoints[0].lng, measurementPoints[0].lat]);
                    const to = turf.point([e.lngLat.lng, e.lngLat.lat]);
                    const distanceInMeters = turf.distance(from, to, { units: 'meters' });
                    setMeasurementDistance(distanceInMeters);
                    setShowMeasurementPopup(true);
                }
            };

            map.on('mousemove', handleMouseMove);

            return () => {
                map.off('mousemove', handleMouseMove);
            };
        }
    }, [mapLoaded, activeTool, measurementPoints]);

    const measurementLineData = useMemo(() => {
        if (measurementPoints.length === 0) return null;
        let coords = measurementPoints.map((p) => [p.lng, p.lat]);
        if (measurementPoints.length === 1 && measurementHoverCoord) {
            coords.push([measurementHoverCoord.lng, measurementHoverCoord.lat]);
        }
        if (coords.length < 2) return null;

        return {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: coords,
                    },
                    properties: {},
                },
            ],
        };
    }, [measurementPoints, measurementHoverCoord]);

    const distanceString = useMemo(() => {
        if (measurementDistance < 1000) {
            return `${measurementDistance.toFixed(2)} m`;
        }
        const km = measurementDistance / 1000;
        return `${km.toFixed(2)} km`;
    }, [measurementDistance]);

    // --------------------------------------------------------------------------------
    //  Determinamos cuál set de polígonos se dibuja en la capa principal
    // --------------------------------------------------------------------------------
    const finalPolygonsData = useMemo(() => {
        // Prioridad: polygonsHarvest -> polygonDataFeatures -> polygonDataMemo
        if (safePolygonsHarvest && safePolygonsHarvest.features.length > 0) {
            return safePolygonsHarvest;
        } else if (polygonDataFeatures && polygonDataFeatures.features.length > 0) {
            return polygonDataFeatures;
        } else if (polygonDataMemo && polygonDataMemo.features.length > 0) {
            return polygonDataMemo;
        }
        return { type: 'FeatureCollection', features: [] };
    }, [safePolygonsHarvest, polygonDataFeatures, polygonDataMemo]);

    const getHarvestTitle = (groupKey) => {
        if (!groupKey) return '';
        const sinPrefijo = groupKey.replace(/^agrupacion_/, '');
        const palabras = sinPrefijo.split('_');
        const capitalizadas = palabras.map(
            (p) => p.charAt(0).toUpperCase() + p.slice(1)
        );
        return capitalizadas.join(' ');
    };

    const harvestTitle = useMemo(
        () => getHarvestTitle(selectedHarvestGrouping),
        [selectedHarvestGrouping]
    );

    const handleLabelClick = useCallback(
        (text, color) => {
            if (typeof setLabelsAndColors === 'function') {
                setLabelsAndColors({ text, color });
            }
        },
        [setLabelsAndColors]
    );


    useEffect(() => {
        if (mapLoaded && localMapRef.current) {
            const map = localMapRef.current.getMap();
            const currentProjection = "EPSG:4326";

            if (!northWestCoords || !southEastCoords) {
                const bounds = map.getBounds();
                const nw = bounds.getNorthWest();
                const se = bounds.getSouthEast();
                const newNW = [nw.lng, nw.lat];
                const newSE = [se.lng, se.lat];
                setNorthWestCoords(newNW);
                setSouthEastCoords(newSE);
                setCoordinateSystem(currentProjection);
            }

            const updateBounds = () => {
                // Solo actualizamos si no se han seteado aún
                if (northWestCoords && southEastCoords) return;
                const bounds = map.getBounds();
                const nw = bounds.getNorthWest();
                const se = bounds.getSouthEast();
                const newNW = [nw.lng, nw.lat];
                const newSE = [se.lng, se.lat];
                setNorthWestCoords(newNW);
                setSouthEastCoords(newSE);
                setCoordinateSystem(currentProjection);
            };

            map.on("moveend", updateBounds);

            return () => {
                map.off("moveend", updateBounds);
            };
        }
    }, [mapLoaded, northWestCoords, southEastCoords]);



    return (
        <Box position="relative">
            {selectedHarvestGrouping && (
                <BarIndicator
                    filterType="harvestGrouping"
                    onLabelClick={handleLabelClick}
                    setLabelsAndColors={setLabelsAndColors}
                    harvestLabels={harvestLegendLabels}
                    harvestTitle={harvestTitle}
                />
            )}

            <MapDialog
                isOpen={!!isFilterDialogOpen}
                onClose={() => {
                    if (typeof closeFilterDialog === 'function') closeFilterDialog();
                }}
                harvestGroupings={harvestGroupings}
                selectedHarvestGrouping={selectedHarvestGrouping}
                onSelectHarvestGrouping={setSelectedHarvestGrouping}
                setTipoMapeo={setTipoMapeo}
            />

            <Map
                initialViewState={{
                    longitude: center[1],
                    latitude: center[0],
                    zoom: zoom,
                }}
                mapStyle={mapStyle}
                mapboxAccessToken="pk.eyJ1IjoiamRtYW4iLCJhIjoiY20xNGpkN2d0MHlsejJ4cTA1Z3lwdGNhMCJ9.O5UY8q02UPPoCCMrobQ0sA"
                style={{ height: '65vh', width: '100%' }}
                ref={localMapRef}
                projection="globe"
                preserveDrawingBuffer={true}
                onLoad={handleMapLoad}
                interactiveLayerIds={['polygons-data-layer']}
            >
                <NavigationControl position="top-right" />

                {isTiled && (
                    <Source id="raster-tiles-overlay" type="raster" tiles={[imageUrl]} tileSize={256}>
                        <Layer
                            id="raster-tiles-layer"
                            type="raster"
                            paint={{
                                'raster-opacity': 1,
                                'raster-resampling': 'linear',
                            }}
                        />
                    </Source>
                )}

                {!isTiled && imageUrl && northWestCoords && southEastCoords && (
                    <Source
                        id="image-overlay"
                        type="image"
                        url={imageUrl}
                        coordinates={[
                            [northWestCoords[0], northWestCoords[1]],
                            [southEastCoords[0], northWestCoords[1]],
                            [southEastCoords[0], southEastCoords[1]],
                            [northWestCoords[0], southEastCoords[1]],
                        ]}
                    >
                        <Layer
                            id="image-overlay-layer"
                            type="raster"
                            paint={{
                                'raster-opacity': 1,
                                'raster-resampling': 'linear',
                            }}
                        />
                    </Source>
                )}

                {!hasTramplingLines && pointData && pointData.features.length > 0 && (
                    <Source id="points" type="geojson" data={pointData}>
                        <Layer
                            id="points-layer"
                            type="circle"
                            paint={{
                                'circle-radius': 2,
                                'circle-color': ['get', 'color'],
                            }}
                        />
                    </Source>
                )}

                {lineasNoFiltradasData && lineasNoFiltradasData.features.length > 0 && (
                    <Source id="lineas-no-filtradas" type="geojson" data={lineasNoFiltradasData}>
                        <Layer
                            id="lineas-no-filtradas-layer"
                            type="line"
                            beforeId="lines-layer"
                            paint={{
                                'line-color': ['get', 'color'],
                                'line-width': 2,
                            }}
                        />
                    </Source>
                )}

                {lineData && lineData.features.length > 0 && (
                    <Source id="lines" type="geojson" data={lineData}>
                        <Layer
                            id="lines-layer"
                            type="line"
                            paint={{
                                'line-color': [
                                    'case',
                                    ['==', ['feature-state', 'hover'], true],
                                    '#E0FFFF',
                                    ['get', 'color'],
                                ],
                                'line-width': 2,
                            }}
                        />
                    </Source>
                )}

                {finalPolygonsData.features.length > 0 && (
                    <Source id="polygons-data" type="geojson" data={finalPolygonsData}>
                        <Layer
                            id="polygons-data-layer"
                            type="fill"
                            paint={{
                                'fill-color': selectedHarvestGrouping
                                    ? ['get', selectedHarvestGrouping.replace('agrupacion_', 'color_')]
                                    : 'yellow',
                                'fill-opacity': 0.8,
                                'fill-outline-color': '#000',
                            }}
                        />
                    </Source>
                )}

                {polygonDataMemo && polygonDataMemo.features.length > 0 && (
                    <Source id="polygons" type="geojson" data={polygonDataMemo}>
                        <Layer
                            id="polygons-layer"
                            type="fill"
                            paint={{
                                'fill-color': [
                                    'case',
                                    ['boolean', ['get', 'active'], false],
                                    'red',
                                    ['has', 'color'],
                                    ['get', 'color'],
                                    '#198C19',
                                ],
                                'fill-opacity': 1,
                                'fill-outline-color': '#000',
                            }}
                        />
                    </Source>
                )}

                {bufferedLinesData && bufferedLinesData.features.length > 0 && (
                    <Source id="buffered-lines" type="geojson" data={bufferedLinesData}>
                        <Layer
                            id="buffered-lines-layer"
                            type="fill"
                            paint={{
                                'fill-color': 'green',
                                'fill-opacity': 0.8,
                            }}
                        />
                    </Source>
                )}

                {bufferedIntersectionsData && bufferedIntersectionsData.features.length > 0 && (
                    <Source id="buffered-intersections" type="geojson" data={bufferedIntersectionsData}>
                        <Layer
                            id="buffered-intersections-layer"
                            type="fill"
                            paint={{
                                'fill-color': 'red',
                                'fill-opacity': 1,
                            }}
                        />
                    </Source>
                )}

                {areasSuperpuestasData && areasSuperpuestasData.features.length > 0 && (
                    <Source id="areas-superpuestas" type="geojson" data={areasSuperpuestasData}>
                        <Layer
                            id="areas-superpuestas-layer"
                            type="fill"
                            paint={{
                                'fill-color': 'red',
                                'fill-opacity': 1,
                                'fill-outline-color': 'red',
                            }}
                        />
                    </Source>
                )}

                {nonIntersectedAreasData && nonIntersectedAreasData.features.length > 0 && (
                    <Source id="non-intersected-areas" type="geojson" data={nonIntersectedAreasData}>
                        <Layer
                            id="non-intersected-areas-layer"
                            type="fill"
                            paint={{
                                'fill-color': 'yellow',
                                'fill-opacity': 1,
                            }}
                        />
                    </Source>
                )}

                {selectedFilesData &&
                    selectedFilesData.map((fileData, index) => {
                        try {
                            if (fileData.type === 'geojson' && fileData.data) {
                                return (
                                    <Source key={fileData.id} id={fileData.id} type="geojson" data={fileData.data}>
                                        <Layer {...polygonLayerSelectedNormal(index)} />
                                        <Layer {...polygonLayerSelectedOverlap(index)} />
                                        <Layer {...lineLayerSelected(index)} />
                                        <Layer {...pointLayerSelected(index)} />
                                    </Source>
                                );
                            } else if (fileData.type === 'image' && fileData.coordinates) {
                                return (
                                    <Source
                                        key={fileData.id}
                                        id={fileData.id}
                                        type="image"
                                        url={fileData.imageUrl}
                                        coordinates={fileData.coordinates}
                                    >
                                        <Layer
                                            id={`${fileData.id}-layer`}
                                            type="raster"
                                            paint={{ 'raster-opacity': 1 }}
                                        />
                                    </Source>
                                );
                            }
                            return null;
                        } catch (renderErr) {
                            console.error(
                                `Error al renderizar selectedFilesData en index ${index}:`,
                                renderErr
                            );
                            return null;
                        }
                    })}

                {measurementLineData && (
                    <Source id="measurement-line" type="geojson" data={measurementLineData}>
                        <Layer
                            id="measurement-line-layer"
                            type="line"
                            paint={{
                                'line-color': '#FF0000',
                                'line-width': 2,
                                'line-dasharray': [2, 1],
                            }}
                        />
                    </Source>
                )}

                {selectedPopup && selectedPopup.lngLat && selectedPopup.content && (
                    <Popup
                        longitude={selectedPopup.lngLat.lng}
                        latitude={selectedPopup.lngLat.lat}
                        onClose={handleClosePopup}
                        closeOnClick={false}
                    >
                        {selectedPopup.content}
                    </Popup>
                )}

                {popupInfo && popupInfo.lngLat && popupInfo.content && (
                    <Popup
                        longitude={popupInfo.lngLat.lng}
                        latitude={popupInfo.lngLat.lat}
                        onClose={() => setPopupInfo(null)}
                        closeOnClick={false}
                    >
                        {popupInfo.content}
                    </Popup>
                )}

                {selectedFilesPopup && selectedFilesPopup.lngLat && selectedFilesPopup.content && (
                    <Popup
                        longitude={selectedFilesPopup.lngLat.lng}
                        latitude={selectedFilesPopup.lngLat.lat}
                        onClose={() => setSelectedFilesPopup(null)}
                        closeOnClick={false}
                    >
                        {selectedFilesPopup.content}
                    </Popup>
                )}

                {showMeasurementPopup && measurementHoverCoord && (
                    <Popup
                        longitude={measurementHoverCoord.lng}
                        latitude={measurementHoverCoord.lat}
                        closeButton={false}
                        closeOnClick={false}
                    >
                        <div style={{ fontSize: '0.8rem' }}>
                            <strong>Distancia:</strong> {distanceString}
                        </div>
                    </Popup>
                )}
            </Map>

            <Box position="absolute" top={10} left={10} zIndex={1000}>
                <IconButton
                    sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        '&:hover': { backgroundColor: 'lightgray' },
                    }}
                    onClick={handleLayersClick}
                >
                    <LayersIcon />
                </IconButton>

                <Menu
                    id="layer-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleLayersClose}
                    PaperProps={{
                        style: {
                            maxHeight: 200,
                            width: 'auto',
                            padding: 0,
                        },
                    }}
                >
                    {Object.keys(mapStyles).map((styleName) => (
                        <MenuItem
                            key={styleName}
                            onClick={() => handleStyleSelect(styleName)}
                            sx={{ fontSize: '0.85rem' }}
                        >
                            {styleName}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>

            <Box position="absolute" top={10} right={10} zIndex={1000}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <StyledIconButton onClick={handleCenterToPolygonsData}>
                        <HomeIcon fontSize="small" />
                    </StyledIconButton>
                    <StyledIconButton onClick={handleCenterToAnalysis}>
                        <MyLocationIcon fontSize="small" />
                    </StyledIconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default CommonMap;
