export const validateFertilizationData = (datosAnalisis) => ({
    responsable:
        datosAnalisis.responsable?.valor &&
        datosAnalisis.responsable.valor.trim() !== '',
    nombreFinca:
        datosAnalisis.nombreFinca?.valor &&
        datosAnalisis.nombreFinca.valor.trim() !== '',
    codigoFinca:
        datosAnalisis.codigoFinca?.valor &&
        datosAnalisis.codigoFinca.valor.trim() !== '',
    lote:
        datosAnalisis.lote?.valor &&
        datosAnalisis.lote.valor.trim() !== '',
    nombreOperador:
        datosAnalisis.nombreOperador?.valor &&
        datosAnalisis.nombreOperador.valor.trim() !== '',
    zafra:
        datosAnalisis.zafra?.valor &&
        datosAnalisis.zafra.valor.trim() !== '',
    tercio:
        datosAnalisis.tercio?.valor &&
        datosAnalisis.tercio.valor.trim() !== '',
    fechaInicio:
        datosAnalisis.fechaInicio?.valor &&
        datosAnalisis.fechaInicio.valor.trim() !== '',
    fechaFinal:
        datosAnalisis.fechaFinal?.valor &&
        datosAnalisis.fechaFinal.valor.trim() !== '',
    horaInicio:
        datosAnalisis.horaInicio?.valor &&
        datosAnalisis.horaInicio.valor.trim() !== '',
    horaFin:
        datosAnalisis.horaFin?.valor &&
        datosAnalisis.horaFin.valor.trim() !== '',
    codigoDeMaquina:
        datosAnalisis.codigoDeMaquina?.valor &&
        datosAnalisis.codigoDeMaquina.valor.trim() !== '',
    eficiencia:
        datosAnalisis.eficiencia?.valor !== undefined &&
        datosAnalisis.eficiencia.valor !== 0,
    dosisTeorica:
        datosAnalisis.dosisTeorica?.valor !== undefined &&
        datosAnalisis.dosisTeorica.valor !== 0,
    anchoDeFaja:
        datosAnalisis.anchoDeFaja?.valor !== undefined &&
        datosAnalisis.anchoDeFaja.valor !== 0,
    tchEstimado:
        datosAnalisis.tchEstimado?.valor !== undefined &&
        datosAnalisis.tchEstimado.valor !== 0,
    equipo:
        datosAnalisis.equipo?.valor &&
        datosAnalisis.equipo.valor.trim() !== '',
    producto:
        datosAnalisis.producto?.valor &&
        datosAnalisis.producto.valor.trim() !== '',
    edadDias:
        datosAnalisis.edadDias?.valor !== undefined &&
        datosAnalisis.edadDias.valor !== 0,
    tiempoTotal:
        datosAnalisis.tiempoTotal?.valor &&
        datosAnalisis.tiempoTotal.valor.trim() !== '',
    altura:
        datosAnalisis.altura?.valor !== undefined &&
        datosAnalisis.altura.valor !== 0,
    velocidad:
        datosAnalisis.velocidad?.valor !== undefined &&
        datosAnalisis.velocidad.valor !== 0,
    dosisReal:
        datosAnalisis.dosisReal?.valor !== undefined &&
        datosAnalisis.dosisReal?.valor !== 0,
    diferenciaDosis:
         datosAnalisis.diferenciaDosis?.valor !== undefined &&
        datosAnalisis.diferenciaDosis?.valor !== 0,

});
