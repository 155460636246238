// src/analysis/planificacionCosechaProcess/planificacionCosechaProcess.jsx

import axios from 'axios';
import pako from 'pako';
import { toast } from 'react-toastify';
import { API_BASE_PYTHON_SERVICE } from '../../utils/config';

export const ejecutarPlanificacionCosecha = async ({
                                                       selectedFile,
                                                       idMax,
                                                       idUsuario,
                                                       setProcessingFinished,
                                                       socket,
                                                       socketSessionID,
                                                       setShowProgressBar,
                                                       setProgress,
                                                       setPolygonsHarvestPlanning
                                                   }) => {
    setProgress(0);
    setShowProgressBar(true);

    try {
        // Convertir el archivo a ArrayBuffer
        const fileBuffer = await selectedFile.arrayBuffer();
        // Comprimir los datos con pako (gzip)
        const compressedData = pako.gzip(new Uint8Array(fileBuffer), { level: 9 });
        const compressedFile = new Blob([compressedData], { type: 'application/gzip' });

        // Preparar el formData para la petición
        const formData = new FormData();
        formData.append('file', compressedFile, `${selectedFile.name}.gz`);
        formData.append('session_id', socketSessionID);
        formData.append('idMax', idMax);
        formData.append('idUsuario', idUsuario);

        // Realizar la petición POST al servicio
        const response = await axios.post(
            `${API_BASE_PYTHON_SERVICE}mapping/planificacion_cosecha`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            }
        );

        if (response.data.status === "error") {
            throw new Error(response.data.message);
        }

        const geojsonUrl = response.data.url_geojson;
        const geojsonResponse = await axios.get(geojsonUrl);

        setPolygonsHarvestPlanning(geojsonResponse.data);
        setProgress(80);

        setProgress(100);
        setProcessingFinished(true);
        setShowProgressBar(false);
        toast.success('Planificación de cosecha procesada exitosamente.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
        });
    } catch (error) {
        console.error("Error durante el proceso de Planificación de Cosecha:", error);
        const errorMessage = error.message || 'Ocurrió un error, consulta con tu administrador.';
        toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
        });
        setShowProgressBar(false);
        setProcessingFinished(true);
    }
};

export const shouldEnableExecBashPlanificacionCosecha = (selectedZipFile) => {
    return selectedZipFile !== null;
};
