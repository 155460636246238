// src/analysis/fertilizacion/fertilizacionProcess.jsx

import axios from 'axios';
import pako from 'pako';
import { toast } from 'react-toastify';
import { API_BASE_PYTHON_SERVICE } from "../../utils/config";

export const ejecutarProcesoFertilizacion = async ({
                                                       selectedFile,
                                                       selectedZipFile,
                                                       idMax,
                                                       userData,
                                                       setLoadingProgress,
                                                       setProcessingFinished,
                                                       socket,
                                                       socketSessionID,
                                                       setShowProgressBar,
                                                       setProgress,
                                                       setTitleLoader,
                                                       anchoFaja
                                                   }) => {
    setProgress(0);
    setTitleLoader("Iniciando mapeo de aplicaciones aéreas...");
    setShowProgressBar(true);

    try {
        const fileReader = new FileReader();

        fileReader.onload = async (e) => {
            try {
                const content = e.target.result;
                const compressedContent = pako.gzip(content);
                const blob = new Blob([compressedContent], { type: 'application/gzip' });

                const formData = new FormData();
                formData.append('file', blob, `${selectedFile.name}.gz`);
                formData.append('collection_name', 'fertilizacion');
                formData.append('session_id', socketSessionID);

                const insertResponse = await axios.post(`${API_BASE_PYTHON_SERVICE}mongo/insert_csv`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                if (insertResponse.data.status === "error") {
                    throw new Error(insertResponse.data.message);
                }

                setProgress(20);
                setTitleLoader("Datos cargados, generando líneas...");

                const formDataFertilizacion = new FormData();
                formDataFertilizacion.append('shape_file_path', selectedZipFile);
                formDataFertilizacion.append('session_id', socketSessionID);
                formDataFertilizacion.append('id_analisis', idMax);
                formDataFertilizacion.append('ancho_faja', anchoFaja ? anchoFaja : 0);

                const fertilizacionResponse = await axios.post(`${API_BASE_PYTHON_SERVICE}mapping/fertilizacion`, formDataFertilizacion, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                if (fertilizacionResponse.data.status === "error") {
                    throw new Error(fertilizacionResponse.data.message);
                }

                setProgress(80);
                setTitleLoader("Líneas generadas, ejecutando proceso...");

                setProcessingFinished(true);
                toast.success('Datos cargados exitosamente.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setProgress(100);
                setTitleLoader("Proceso finalizado.");
                setShowProgressBar(false);
            } catch (error) {
                console.error("Error al procesar el lote de Fertilización:", error);
                const errorMessage = error.message || 'Ocurrió un error, consulta con tu administrador.';
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setShowProgressBar(false);
            }
        };

        fileReader.onerror = (error) => {
            console.error("Error al leer el archivo:", error);
            toast.error('Ocurrió un error al leer el archivo, consulta con tu administrador.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setShowProgressBar(false);
        };

        fileReader.readAsText(selectedFile);
    } catch (error) {
        console.error("Error al iniciar el proceso de Fertilización:", error);
        const errorMessage = error.message || 'Ocurrió un error, consulta con tu administrador.';
        toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setShowProgressBar(false);
    }
};

export const ejecutarProcesoSinArchivoFertilizacion = async ({
                                                                 idMax,
                                                                 userData,
                                                                 setProcessingFinished,
                                                                 API_BASE_URL
                                                             }) => {
    const formData = new FormData();
    formData.append('esPrimeraIteracion', 'true');

    try {
        await axios.post(`${API_BASE_URL}dashboard/execBash/${userData.userId}/4/${idMax}/0/ok/0`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        setProcessingFinished(true);
    } catch (error) {
        console.error("Error al procesar el lote de Fertilización sin archivo:", error);
    }
};
