import React, { useEffect, useState, useMemo } from 'react';
import {
    BarIndicatorContainer,
    BarIndicatorWrapper,
    Tab,
    BarTitle,
    LabelContainer,
    LabelItem,
} from './BarIndicatorStyle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip';

const BarIndicator = React.memo(
    ({
         filterType,
         onLabelClick = () => {},
         setLabelsAndColors = () => {},
         harvestLabels = null,
         harvestTitle = '',
         isHistory = false,
     }) => {
        const [isOpen, setIsOpen] = useState(true);

        const getTitleAndLabels = (type) => {
            switch (type) {
                case 'speed':
                    return {
                        title: 'Velocidad',
                        labels: [
                            { color: '#4caf50', text: 'Bajo', textColor: '#ffffff' },
                            { color: '#ffeb3b', text: 'Medio', textColor: '#ffffff' },
                            { color: '#f44336', text: 'Alto', textColor: '#ffffff' },
                        ],
                    };
                case 'gpsQuality':
                    return {
                        title: 'Calidad GPS',
                        labels: [
                            { color: '#4caf50', text: 'Bajo', textColor: '#ffffff' },
                            { color: '#ffeb3b', text: 'Medio', textColor: '#ffffff' },
                            { color: '#f44336', text: 'Alto', textColor: '#ffffff' },
                        ],
                    };
                case 'fuel':
                    return {
                        title: 'Combustible',
                        labels: [
                            { color: '#4caf50', text: 'Bajo', textColor: '#ffffff' },
                            { color: '#ffeb3b', text: 'Medio', textColor: '#ffffff' },
                            { color: '#f44336', text: 'Alto', textColor: '#ffffff' },
                        ],
                    };
                case 'autoPilot':
                    return {
                        title: 'Piloto Automático',
                        labels: [
                            { color: 'green', text: 'Enganchado', textColor: '#ffffff' },
                            { color: 'red', text: 'Desenganchado', textColor: '#ffffff' },
                        ],
                    };
                case 'cutterBase':
                    return {
                        title: 'Presión Cortador Base',
                        labels: [
                            { color: '#4caf50', text: 'Bajo', textColor: '#ffffff' },
                            { color: '#ffeb3b', text: 'Medio', textColor: '#ffffff' },
                            { color: '#f44336', text: 'Alto', textColor: '#ffffff' },
                        ],
                    };
                case 'rpm':
                    return {
                        title: 'RPM',
                        labels: [
                            { color: '#4caf50', text: 'Bajo', textColor: '#ffffff' },
                            { color: '#ffeb3b', text: 'Medio', textColor: '#ffffff' },
                            { color: '#f44336', text: 'Alto', textColor: '#ffffff' },
                        ],
                    };
                case 'modeCutterBase':
                    return {
                        title: 'Modo de corte base',
                        labels: [
                            { color: 'green', text: 'Automático', textColor: '#ffffff' },
                            { color: 'red', text: 'Manual', textColor: '#ffffff' },
                        ],
                    };
                case 'autoTracket':
                    return {
                        title: 'Auto Tracket',
                        labels: [
                            { color: 'green', text: 'Enganchado', textColor: '#ffffff' },
                            { color: 'red', text: 'Desenganchado', textColor: '#ffffff' },
                        ],
                    };
                case 'aplicacionesAreas':
                    return {
                        title: 'Aplicaciones Áreas',
                        labels: [
                            { color: 'green', text: 'Área aplicada', textColor: '#ffffff' },
                            { color: 'red', text: 'Área sobre aplicada', textColor: '#ffffff' },
                        ],
                    };
                case 'dosisReal':
                    return {
                        title: 'Dosis Real',
                        labels: [
                            { color: '#4caf50', text: 'Bajo', textColor: '#ffffff' },
                            { color: '#ffeb3b', text: 'Medio', textColor: '#ffffff' },
                            { color: '#f44336', text: 'Alto', textColor: '#ffffff' },
                        ],
                    };
                case 'aerialApplicationsTraslape':
                    return {
                        title: 'Leyenda',
                        labels: [
                            { color: 'green', text: 'Aplicado', textColor: '#ffffff' },
                            { color: 'red', text: 'Sobre aplicado', textColor: '#ffffff' },
                            { color: '#f1d700', text: 'No aplicado', textColor: '#ffffff' }
                        ],
                    };
                case 'aerialApplicationsTraslapeDrones':
                    return {
                        title: 'Leyenda',
                        labels: [
                            { color: 'green', text: 'Aplicado', textColor: '#ffffff' },
                            { color: 'red', text: 'Sobre Aplicado', textColor: '#ffffff' },
                            { color: 'purple', text: 'Línea de aplicación', textColor: '#fff' },
                            { color: '#fff', text: 'Ruta de vuelo', textColor: '#000' },
                            { color: '#f1d700', text: 'No aplicado', textColor: '#ffffff' }
                        ],
                    };
                case 'altura':
                    return {
                        title: 'Altura',
                        labels: [
                            { color: '#4caf50', text: 'Bajo', textColor: '#ffffff' },
                            { color: '#ffeb3b', text: 'Medio', textColor: '#ffffff' },
                            { color: '#f44336', text: 'Alto', textColor: '#ffffff' },
                        ],
                    };
                case 'trampling':
                    return {
                        title: 'Pisoteo',
                        labels: [
                            { color: '#ffffff', text: '0%', textColor: '#ffffff' },
                            { color: '#ffe5e5', text: '25%', textColor: '#ffffff' },
                            { color: '#ffcccc', text: '50%', textColor: '#ffffff' },
                            { color: '#ff9999', text: '75%', textColor: '#ffffff' },
                            { color: '#ff0000', text: '100%', textColor: '#ffffff' },
                        ],
                    };
                case 'harvestGrouping':
                    if (harvestLabels && harvestLabels.length > 0) {
                        return {
                            title: harvestTitle || 'Planificación cosecha',
                            labels: harvestLabels.map(label => ({ ...label, textColor: '#ffffff' })),
                        };
                    } else {
                        return {
                            title: 'Planificación cosecha',
                            labels: [
                                { color: '#999999', text: 'Valor 1', textColor: '#ffffff' },
                                { color: '#bbbbbb', text: 'Valor 2', textColor: '#ffffff' },
                            ],
                        };
                    }
                case 'hdop':
                    return {
                        title: 'HDOP',
                        labels: [
                            { color: '#4caf50', text: 'Bajo', textColor: '#ffffff' },
                            { color: '#ffeb3b', text: 'Medio', textColor: '#ffffff' },
                            { color: '#f44336', text: 'Alto', textColor: '#ffffff' },
                        ],
                    };
                case 'p1dr':
                    return {
                        title: 'P1_DR',
                        labels: [
                            { color: '#4caf50', text: 'Bajo', textColor: '#ffffff' },
                            { color: '#ffeb3b', text: 'Medio', textColor: '#ffffff' },
                            { color: '#f44336', text: 'Alto', textColor: '#ffffff' },
                        ],
                    };
                case 'p1dt':
                    return {
                        title: 'P1_DT',
                        labels: [
                            { color: '#4caf50', text: 'Bajo', textColor: '#ffffff' },
                            { color: '#ffeb3b', text: 'Medio', textColor: '#ffffff' },
                            { color: '#f44336', text: 'Alto', textColor: '#ffffff' },
                        ],
                    };
                case 'traslape':
                    return {
                        title: 'Leyenda',
                        labels: [
                            { color: 'green', text: 'Aplicado', textColor: '#ffffff' },
                            { color: 'red', text: 'Sobre aplicado', textColor: '#ffffff' },
                            { color: '#f1d700', text: 'No aplicado', textColor: '#ffffff' }
                        ]
                    };
                default:
                    return {
                        title: 'Indicador',
                        labels: [
                            { color: '#4caf50', text: 'Bajo', textColor: '#ffffff' },
                            { color: '#ffeb3b', text: 'Medio', textColor: '#ffffff' },
                            { color: '#f44336', text: 'Alto', textColor: '#ffffff' },
                        ],
                    };
            }
        };

        // Obtenemos la configuración de la leyenda según el tipo de filtro actual
        const { title, labels } = useMemo(
            () => getTitleAndLabels(filterType),
            [filterType, harvestLabels, harvestTitle]
        );

        // Cada vez que cambie el título o los labels, los seteamos (para que puedan usarse en otros lados)
        useEffect(() => {
            if (setLabelsAndColors && (title !== '' || labels.length > 0)) {
                setLabelsAndColors((prevState) => {
                    if (
                        prevState.title === title &&
                        JSON.stringify(prevState.labels) === JSON.stringify(labels)
                    ) {
                        return prevState;
                    }
                    return { title, labels };
                });
            }
        }, [title, labels, setLabelsAndColors]);

        return (
            <BarIndicatorContainer>
                <BarIndicatorWrapper isOpen={isOpen}>
                    <BarTitle>{title}</BarTitle>
                    <LabelContainer>
                        {labels.map((label, index) => (
                            <LabelItem
                                key={index}
                                style={{ backgroundColor: label.color, color: label.textColor }}
                                onClick={() => onLabelClick(label.text, label.color)}
                            >
                                {label.text}
                            </LabelItem>
                        ))}
                    </LabelContainer>
                </BarIndicatorWrapper>
                <Tooltip title={isOpen ? 'Ocultar leyenda' : 'Mostrar leyenda'}>
                    <Tab onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
                        {isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </Tab>
                </Tooltip>
            </BarIndicatorContainer>
        );
    }
);

export default BarIndicator;
