// Dashboard.jsx

import React, { useEffect, useState, useRef, useContext } from 'react';
import { useSocket } from '../../context/SocketContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_BASE_URL, API_BASE_PYTHON_SERVICE } from '../../utils/config';
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import MapSection from './MapSection/MapSection';
import DataSection from './DataSection/DataSection';
import Tutorial from '../../components/Tutorial/Tutorial';
import ToolbarComponent from "../../components/ToolbarComponent/ToolbarComponent";
import FilterToolbar from "../../components/FilterToolbar/FilterToolbar";
import { Box, CircularProgress } from '@mui/material';
import { sendDashboardData } from '../../utils/DashboardUtils';
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import {
    DashboardContainer,
    MainContent,
    DashboardControls,
    MapSectionContainer,
    AnalysisSection,
} from './DashboardStyle';
import { SidebarContext } from "../../context/SidebarContext";
import analysisConfig from "../../utils/analysisConfig";
import sidebarOptionsConfig from "../../utils/sidebarOptionsConfig";
import { manejarSubidaArchivo } from "../../utils/fileHandler";
import { obtenerLoteMasReciente } from "../../utils/mapUtils";
import {
    ejecutarProcesoCosechaMecanica,
    ejecutarProcesoSinArchivoCosechaMecanica
} from "../../analysis/cosechaMecanica/cosechaMecanicaProcess";
import { ejecutarProcesoAps, ejecutarProcesoSinArchivoAps } from "../../analysis/aps/apsProcess";
import {
    ejecutarProcesoHerbicidas,
    ejecutarProcesoSinArchivoHerbicidas
} from "../../analysis/herbicidas/herbicidasProcess";
import {
    ejecutarProcesoFertilizacion,
    ejecutarProcesoSinArchivoFertilizacion
} from "../../analysis/fertilizacion/fertilizacionProcess";
import { ejecutarProcesoConteoPalmas } from "../../analysis/conteoPalmas/conteoPalmasProcess";
import { ejecutarProcesoConteoAgaves } from "../../analysis/conteoAgave/conteoAgaveProcess";
import { ejecutarPlanificacionCosecha } from "../../analysis/planificacionCosechaProcess/planificacionCosechaProcess";
import {
    APLICACIONES_AEREAS,
    COSECHA_MECANICA,
    HERBICIDAS,
    FERTILIZACION,
    CONTEO_PALMA,
    CONTEO_AGAVE,
    PLANIFICACION_COSECHA
} from "../../utils/Constants";
import { CompanyContext } from "../../context/CompanyContext";
import ErrorListDialog from "../../components/ErrorListDialog/ErrorListDialog";
import moment from 'moment';

const Dashboard = ({ isSidebarOpen, selectedFiles, selectedFilters, setSelectedFilters }) => {
    const { logo } = useContext(CompanyContext);
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    const [runTutorial, setRunTutorial] = useState(false);
    const [tutorialKey, setTutorialKey] = useState(0);
    const [progress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [idMax, setIdMax] = useState(null);
    const [processingFinished, setProcessingFinished] = useState(false);
    const [titleLoader, setTitleLoader] = useState("");
    const [idAnalisisAps, setIdAnalisisAps] = useState(null);
    const [idAnalisisCosechaMecanica, setIdAnalisisCosechaMecanica] = useState(null);
    const [idAnalisisFertilizacion, setIdAnalisisFertilizacion] = useState(null);
    const [idAnalisisHerbicidas, setIdAnalisisHerbicidas] = useState(null);
    const [idAnalisisBash, setIdAnalisisBash] = useState(null);
    const [refreshAnalisis, setRefreshAnalisis] = useState(null);
    const [refreshPeticion, setRefreshPeticion] = useState(null);
    const [idAnalisisUltimoAnalisis, setIdAnalisisUltimoAnalisis] = useState(null);
    const selectedAnalysisTypeRef = useRef();
    const [progressMessage, setProgressMessage] = useState("");
    const [selectedZipFile, setSelectedZipFile] = useState(null);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [datosMapeo, setDatosMapeo] = useState([]);
    const [areaAplicada, setAreaAplicada] = useState(0);
    const [areaTotalLote, setAreaTotalLote] = useState(0);
    const [porcentajeVariacion, setPorcentajeVariacion] = useState(0);
    const [areaNoAplicada, setAreaNoAplicada] = useState(0);
    const [promedioVelocidad, setPromedioVelocidad] = useState(0);
    const [promedioAltura, setPromedioAltura] = useState(0);
    const [areaNetaCm, setAreaNetaCm] = useState(null);
    const [areaBrutaCm, setAreaBrutaCm] = useState(null);
    const [diferenciaDeAreaCm, setDiferenciaDeAreaCm] = useState(null);
    const [eficienciaCm, setEficienciaCm] = useState(null);
    const [promedioVelocidadCm, setPromedioVelocidadCm] = useState(null);
    const [porcentajeAreaPilotoCm, setPorcentajeAreaPilotoCm] = useState(null);
    const [areaSobreAplicada, setAreaSobreAplicada] = useState(0);
    const [areaSobreCalles, setAreaSobreCalles] = useState(0);
    const [areaFueraDeLote, setAreaFueraDeLote] = useState(0);
    const [dosisTotalRealAplicada, setDosisTotalRealAplicada] = useState(0);
    const [porcentajeAreaAutoTrackerCm, setPorcentajeAreaAutoTrackerCm] = useState(null);
    const [porcentajeModoCortadorBaseCm, setPorcentajeModoCortadorBaseCm] = useState(null);

    const [datosAnalisis, setDatosAnalisis] = useState({});
    const [isKMLFile, setIsKMLFile] = useState(false);
    const [activarEdicionInteractiva, setActivarEdicionInteractiva] = useState(true);
    const [selectedAnalysisType, setSelectedAnalysisType] = useState('');
    const dashboardRef = useRef();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [uploadedCsvFileName, setUploadedCsvFileName] = useState('');
    const [uploadedZipFileName, setUploadedZipFileName] = useState('');
    const [execBashEnabled, setExecBashEnabled] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [polygonsData, setPolygonsData] = useState([]);
    const [activeLotes, setActiveLotes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loadingFilters, setLoadingFilters] = useState(true);
    const [loadingPolygons, setLoadingPolygons] = useState(true);
    const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
    const socketContext = useSocket();
    const { socket, socketSessionID } = socketContext || {};
    const [indicadores, setIndicadores] = useState('');
    const [imgLaflet, setImgLaflet] = useState(null);
    const { selectedSidebarOption } = useContext(SidebarContext) || {};
    const [filterOptions, setFilterOptions] = useState([
        { value: 'operadorCM', label: 'Operador' },
        { value: 'zafraCM',    label: 'Zafra' },
        { value: 'loteCM',     label: 'Lote' },
        { value: 'fincaCM',    label: 'Finca' },
        { value: 'tercioCM',   label: 'Tercio' },
        { value: 'productoAA', label: 'Producto' },
    ]);
    const [enabledFilters, setEnabledFilters] = useState([]);
    const [analysisOptions, setAnalysisOptions] = useState([]);
    const [ultimoAnalisis, setUltimoAnalisis] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [diferenciaDeAreaApsKml, setDiferenciaDeAreaApsKml] = useState(null);
    const [northWestCoords, setNorthWestCoords] = useState(null);
    const [southEastCoords, setSouthEastCoords] = useState(null);
    const [coordinateSystem, setCoordinateSystem] = useState("EPSG:4326");

    const [conteoObjetos, setConteoObjetos] = useState(0);
    const [highlightedLote, setHighlightedLote] = useState(null);
    const [isAnalysisPerformed, setIsAnalysisPerformed] = useState(false);
    const [isGeneratingReport, setIsGeneratingReport] = useState(false);
    const [labelsAndColors, setLabelsAndColors] = useState({});
    const mapRef = useRef(null);
    const polygonsLayerRef = useRef(null);
    const dataSectionRef = useRef(null);
    const [onAreasCalculatedLoader, setOnAreasCalculatedLoader] = useState(true);
    const [tipoMapeo, setTipoMapeo] = useState('');
    const [errors, setErrors] = useState([]);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);

    const [operadorOptionsCM, setOperadorOptionsCM] = useState([]);
    const [zafraOptionsCM, setZafraOptionsCM] = useState([]);
    const [loteOptionsCM, setLoteOptionsCM] = useState([]);
    const [fincasOptionsCM, setFincasOptionsCM] = useState([]);
    const [tercioOptionsCM, setTercioOptionsCM] = useState([]);
    const [productoOptionsAA, setProductoOptionsAA] = useState([]);
    const [tramplingLines, setTramplingLines] = useState([]);
    const [activeTool, setActiveTool] = useState(null);
    const [isBufferActive, setIsBufferActive] = useState(false);
    const [bufferValue, setBufferValue] = useState(0);
    const [showUnfilteredLines, setShowUnfilteredLines] = useState(true);
    const [mostrarLineas, setMostrarLineas] = useState(false);
    const [calidad, setCalidad] = useState('baja');
    const [precioPlantacion, setPrecioPlantacion] = useState(0);
    const [costosPlantacion, setCostosPlantacion] = useState(0);
    const [utilidadNeta, setUtilidadNeta] = useState(0);
    const [previousTramplingLines, setPreviousTramplingLines] = useState([]);
    const [harvestLinesBuffer, setHarvestLinesBuffer] = useState(0);
    const [disableAnalysisSelection, setDisableAnalysisSelection] = useState(false);
    const [forceEnableUploadTemplate, setForceEnableUploadTemplate] = useState(false);
    const [polygonsHarvestPlanning, setPolygonsHarvestPlanning] = useState([]);

    const handleDrawLine = () => {
        setActiveTool((prevTool) => (prevTool === 'draw' ? null : 'draw'));
    };

    const handleCutLine = () => {
        setActiveTool((prevTool) => (prevTool === 'cut' ? null : 'cut'));
    };

    const handleDeleteLine = () => {
        setActiveTool((prevTool) => (prevTool === 'delete' ? null : 'delete'));
    };

    const handleToggleBuffer = () => {
        setIsBufferActive(prev => !prev);
    };

    const handleStretchLine = () => {
        setActiveTool((prevTool) => (prevTool === 'stretch' ? null : 'stretch'));
    };

    const handleMeasurement = () => {
        setActiveTool(prevTool => (prevTool === 'measurement' ? null : 'measurement'));
    };

    const handleUndo = () => {
        // Sin implementación, la maneja el modulo de AerialApplications.
    };

    const toggleUnfilteredLines = () => {
        setShowUnfilteredLines(prev => !prev);
    };

    const procesarOpciones = (opciones) => {
        if (!Array.isArray(opciones)) return [];
        return opciones.flat(Infinity).map(item => String(item));
    };

    const filterOptionsMap = {
        'APLICACIONES_AEREAS': ['operadorCM', 'zafraCM', 'loteCM'],
        'COSECHA_MECANICA':    ['operadorCM', 'fincaCM', 'tercioCM'],
        'HERBICIDAS':          ['operadorCM', 'fincaCM'],
        'FERTILIZACION':       ['operadorCM', 'fincaCM'],
        'CONTEO_PALMA':        ['loteCM', 'productoAA'],
    };

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const userDataLocal = JSON.parse(localStorage.getItem("userData")) || {};
                const idUsuario = userDataLocal.userId;
                if (!idUsuario) {
                    console.error("No se encontró userId en localStorage");
                    return;
                }

                const response = await axios.get(`${API_BASE_URL}dashboard/filtros/${idUsuario}`);
                const data = response.data;

                setOperadorOptionsCM(procesarOpciones(data.operador));
                setZafraOptionsCM(procesarOpciones(data.zafra));
                setLoteOptionsCM(procesarOpciones(data.lote));
                setFincasOptionsCM(procesarOpciones(data.finca));
                setTercioOptionsCM(procesarOpciones(data.tercio));
                setProductoOptionsAA(procesarOpciones(data.producto));

            } catch (error) {
                console.error("Error al obtener los filtros de mapeo:", error);

            } finally {
                setLoadingFilters(false);
            }
        };

        fetchFilters();
    }, []);

    useEffect(() => {
        if (selectedAnalysisType && filterOptionsMap[selectedAnalysisType]) {
            setEnabledFilters(filterOptionsMap[selectedAnalysisType]);
        } else {
            setEnabledFilters([]);
        }
    }, [selectedAnalysisType]);

    const handleSendDashboardDataFilters = (selectedFiltersParam) => {
        setSelectedFilters({
            zafra:    selectedFiltersParam.zafraCM    || null,
            tercio:   selectedFiltersParam.tercioCM   || null,
            finca:    selectedFiltersParam.fincaCM    || null,
            operador: selectedFiltersParam.operadorCM || null,
            lote:     selectedFiltersParam.loteCM     || null,
            producto: selectedFiltersParam.productoAA || null,
        });
    };

    const handleSendDashboardData = async (imgData, comment) => {
        try {
            if (imgData) {
                setIsGeneratingReport(true);

                let chartsImages = [];
                if (dataSectionRef.current && typeof dataSectionRef.current.exportAllChartsAsImages === "function") {
                    chartsImages = await dataSectionRef.current.exportAllChartsAsImages();
                } else {
                    console.error("dataSectionRef.current.exportAllChartsAsImages NO está definido.");
                }

                await sendDashboardData(
                    imgData,
                    indicadores,
                    userData.userId,
                    logo,
                    labelsAndColors,
                    comment,
                    tipoMapeo,
                    chartsImages,
                    northWestCoords,
                    southEastCoords,
                    coordinateSystem
                );
            } else {
                console.error("No se pudo capturar la imagen del mapa.");
            }
        } catch (error) {
            console.error("Error en handleSendDashboardData:", error);
        } finally {
            setIsGeneratingReport(false);
        }
    };

    useEffect(() => {
        try {
            obtenerLoteMasReciente(setLoadingPolygons, setPolygonsData, userData.userId);
        } catch (error) {
            console.error("Error en obtenerLoteMasReciente:", error);
        }
    }, [userData.userId]);

    useEffect(() => {
        return () => {
            setDatosMapeo([]);
            setSelectedFile(null);
        };
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on(`${socketSessionID}:progressUpdate`, (data) => {
                try {
                    const progressNumber = Number(data.progress);
                    const message = data.message;
                    setProgress(progressNumber);
                    setProgressMessage(message);
                    setShowProgressBar(progressNumber < 100);
                    if (progressNumber === 80) {
                        socket.emit(`${socketSessionID}:progressUpdate`, { progress: 100, message: "Finalizado" });
                        setShowProgressBar(false);
                    }
                } catch (error) {
                    console.error("Error en progressUpdate:", error);
                }
            });

            return () => {
                socket.off(`${socketSessionID}:progressUpdate`);
            };
        }
    }, [socket, socketSessionID]);

    const handleDatosInsertados = async () => {
        try {
            const config = analysisConfig[selectedAnalysisTypeRef.current];
            if (config && config.cargaDatos) {
                const cargarDatos = await config.cargaDatos(userData, selectedAnalysisTypeRef);
                if (cargarDatos && cargarDatos.data) {
                    setUltimoAnalisis(cargarDatos);
                    setIdAnalisisUltimoAnalisis(cargarDatos.data.ID_ANALISIS);
                } else {
                    console.warn("cargarDatos es null o no contiene data.");
                }
                setRefreshAnalisis(new Date());
            } else {
                toast.warn('Debes seleccionar un tipo de análisis.', {
                    position: "top-right",
                    autoClose: 5000,
                });
            }
        } catch (error) {
            console.error("Error en handleDatosInsertados:", error);
        }
    };

    useEffect(() => {
        if (socket && socket.on) {
            socket.on(`${socketSessionID}:dataInsertion`, handleDatosInsertados);

            return () => {
                socket.off(`${socketSessionID}:dataInsertion`, handleDatosInsertados);
            };
        }
    }, [socket, socketSessionID]);

    useEffect(() => {
        const config = analysisConfig[selectedAnalysisType];
        if (config && config.fetchData) {
            try {
                if (selectedAnalysisType === 'CONTEO_PALMA') {
                    config.fetchData(conteoObjetos, setDatosAnalisis);
                } else if(selectedAnalysisType === 'CONTEO_AGAVE'){
                    config.fetchData(conteoObjetos, setDatosAnalisis, precioPlantacion, costosPlantacion, utilidadNeta);
                } else {
                    config.fetchData(idAnalisisUltimoAnalisis, setDatosAnalisis);
                }
            } catch (error) {
                console.error("Error en config.fetchData:", error);
            }
        }
    }, [
        idAnalisisCosechaMecanica,
        idAnalisisAps,
        idAnalisisHerbicidas,
        idAnalisisFertilizacion,
        conteoObjetos,
        refreshPeticion,
        selectedAnalysisType,
        idAnalisisUltimoAnalisis
    ]);

    useEffect(() => {
        let effectiveAnalysisType = selectedAnalysisType;
        if (!effectiveAnalysisType || effectiveAnalysisType.trim() === "") {
            if (selectedSidebarOption) {
                effectiveAnalysisType = selectedSidebarOption
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(/\s+/g, "_")
                    .toUpperCase();
            } else {
                console.warn("selectedAnalysisType está vacío y selectedSidebarOption no está definido.");
            }
        }

        const config = analysisConfig[effectiveAnalysisType];
        try {
            if (config && config.shouldEnableExecBash) {
                setExecBashEnabled(config.shouldEnableExecBash(selectedFile || selectedZipFile));
            } else {
                setExecBashEnabled(false);
            }
        } catch (error) {
            console.error("Error en config.shouldEnableExecBash:", error);
            setExecBashEnabled(false);
        }
    }, [selectedAnalysisType, selectedSidebarOption, selectedFile, selectedZipFile]);

    useEffect(() => {
        selectedAnalysisTypeRef.current = selectedAnalysisType;
        const config = analysisConfig[selectedAnalysisType];
        const id = config ? config.id : null;
        setIdAnalisisBash(id);
    }, [selectedAnalysisType, userData.userId]);

    useEffect(() => {
        const config = sidebarOptionsConfig[selectedSidebarOption];
        try {
            if (config) {
                setAnalysisOptions(config.analysisOptions || []);
                setDisableAnalysisSelection(config.disableAnalysisSelection || false);
                setForceEnableUploadTemplate(config.enableUploadTemplate || false);
            } else {
                setAnalysisOptions([]);
                setDisableAnalysisSelection(false);
                setForceEnableUploadTemplate(false);
            }
        } catch (error) {
            console.error("Error en sidebarOptionsConfig:", error);
            setAnalysisOptions([]);
            setDisableAnalysisSelection(false);
            setForceEnableUploadTemplate(false);
        }
    }, [selectedSidebarOption]);

    useEffect(() => {
        try {
            if (idAnalisisUltimoAnalisis) {
                setIdAnalisisAps(idAnalisisUltimoAnalisis);
                setIdAnalisisCosechaMecanica(idAnalisisUltimoAnalisis);
                setIdAnalisisFertilizacion(idAnalisisUltimoAnalisis);
                setIdAnalisisHerbicidas(idAnalisisUltimoAnalisis);
                setRefreshPeticion(new Date());
            }
        } catch (error) {
            console.error("Error en setIdAnalisis:", error);
        }
    }, [idAnalisisUltimoAnalisis, refreshAnalisis]);

    useEffect(() => {
        if (selectedAnalysisType === COSECHA_MECANICA && !mostrarLineas) {
            ejecutarProcesoCosechaMecanica({
                selectedFile,
                selectedZipFile,
                idMax,
                idUsuario: userData.userId,
                setProcessingFinished,
                socket,
                socketSessionID,
                setShowProgressBar,
                setProgress,
                setTitleLoader,
                setLoadingProgress,
                generar_lineas: false
            });
        }
    }, [selectedAnalysisType, mostrarLineas]);

    async function clearAnalysis() {
        const map = mapRef.current;
        if (!map) {
            console.error("Map reference is invalid.");
            return;
        }
        try {
            map.eachLayer(function(layer) {
                if (layer !== polygonsLayerRef.current) {
                    map.removeLayer(layer);
                }
            });
            setDatosAnalisis({});
        } catch (error) {
            console.error("Error clearing analysis:", error);
        }
    }

    function nombreAnalisis(idAnalisis) {
        try {
            const entry = Object.entries(analysisConfig).find(([, config]) => config.id === idAnalisis);
            return entry ? entry[0] : "";
        } catch (error) {
            console.error("Error en nombreAnalisis:", error);
            return "";
        }
    }

    const handleDateChange = (dates) => {
        // Manejar fechas si aplica.
    };

    const handleFileUpload = async (event) => {
        try {
            let currentIdAnalisisBash = idAnalisisBash;
            if (!selectedAnalysisTypeRef.current) {
                if (selectedSidebarOption) {
                    selectedAnalysisTypeRef.current = selectedSidebarOption
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .replace(/\s+/g, "_")
                        .toUpperCase();

                    const analysisTypeConfig = analysisConfig[selectedAnalysisTypeRef.current];
                    if (analysisTypeConfig && analysisTypeConfig.id) {
                        currentIdAnalisisBash = analysisTypeConfig.id;
                        setIdAnalisisBash(analysisTypeConfig.id);
                    } else {
                        console.warn(`No se encontró una configuración en analysisConfig para ${selectedAnalysisTypeRef.current}`);
                    }
                } else {
                    console.warn("No se pudo asignar un valor a selectedAnalysisTypeRef.current porque selectedSidebarOption es null o undefined.");
                }
            }

            await manejarSubidaArchivo(
                event,
                setTitleLoader,
                setOpenSnackbar,
                setUploadedCsvFileName,
                selectedAnalysisTypeRef,
                currentIdAnalisisBash || idAnalisisBash,
                nombreAnalisis,
                userData.userId,
                setIdMax,
                setSelectedFile,
                setDatosMapeo,
                setProgress,
                setShowProgressBar,
                setErrors,
                setOpenErrorDialog
            );
        } catch (error) {
            console.error("Error en handleFileUpload:", error);
        }
    };

    const handleCloseErrorDialog = () => {
        setOpenErrorDialog(false);
        setErrors([]);
    };

    const execBash = async (generar_lineas = false) => {
        try {
            await clearAnalysis();
            setIsAnalysisPerformed(true);
            setShowProgressBar(true);
            setTitleLoader("Cargando Análisis");
            const idUsuario = userData.userId;

            if (!idAnalisisBash) {
                toast.error('Debe seleccionar un análisis antes de continuar', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                });
                return;
            }

            if (!selectedFile) {
                await ejecutarProcesoSinArchivo();
                return;
            }

            if (idAnalisisBash === APLICACIONES_AEREAS) {
                await ejecutarProcesoAps({
                    selectedFile, selectedZipFile, idMax, idUsuario, setProcessingFinished, socket, socketSessionID, activarEdicionInteractiva, setShowProgressBar, setProgress, setTitleLoader
                });
            } else if (idAnalisisBash === COSECHA_MECANICA) {
                await ejecutarProcesoCosechaMecanica({
                    selectedFile, selectedZipFile, idMax, idUsuario, setProcessingFinished, socket, socketSessionID, setShowProgressBar, setProgress, setTitleLoader, setLoadingProgress, generar_lineas, setTramplingLines, harvestLinesBuffer
                });
            } else if (idAnalisisBash === HERBICIDAS) {
                await ejecutarProcesoHerbicidas({
                    selectedFile, selectedZipFile, idMax, idUsuario, setProcessingFinished, socket, socketSessionID
                });
            }

            else if (idAnalisisBash === FERTILIZACION) {
                let hasPoints = false;
                if (selectedZipFile) {
                    const formDataZip = new FormData();
                    formDataZip.append("archivo_zip", selectedZipFile);
                    const validarResponse = await axios.post(
                        `${API_BASE_PYTHON_SERVICE}mapping/fertilizacion/validar_zip_shp`,
                        formDataZip,
                        { headers: { 'Content-Type': 'multipart/form-data' } }
                    );
                    hasPoints = !!(validarResponse?.data?.hasPoints);
                }

                if (hasPoints) {
                    const anchoFajaInput = window.prompt("Ingrese el ancho de faja (en metros):");
                    const anchoFaja = parseFloat(anchoFajaInput);
                    if (isNaN(anchoFaja) || anchoFaja <= 0) {
                        toast.error('El ancho de faja ingresado no es válido.', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 5000,
                        });
                        setShowProgressBar(false);
                        return;
                    }
                    await ejecutarProcesoFertilizacion({
                        selectedFile,
                        selectedZipFile,
                        idMax,
                        userData,
                        setProcessingFinished,
                        socket,
                        socketSessionID,
                        setProgress,
                        setTitleLoader,
                        setShowProgressBar,
                        anchoFaja
                    });
                } else {
                    // Si NO hay puntos, no pedimos nada y corremos fertilización normal
                    await ejecutarProcesoFertilizacion({
                        selectedFile,
                        selectedZipFile,
                        idMax,
                        userData,
                        setProcessingFinished,
                        socket,
                        socketSessionID,
                        setProgress,
                        setTitleLoader,
                        setShowProgressBar,
                        anchoFaja: null
                    });
                }
            }
                // ---------------------------------
                // FIN BLOQUE FERTILIZACIÓN
            // ---------------------------------
            else if (selectedSidebarOption === "Planificación de cosecha") {
                if (!selectedFile) {
                    toast.error('Debe seleccionar un archivo para realizar el análisis de Planificación de cosecha.', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                    });
                    setShowProgressBar(false);
                    return;
                }
                await ejecutarPlanificacionCosecha({
                    selectedFile,
                    idMax,
                    idUsuario,
                    setProcessingFinished,
                    socket,
                    socketSessionID,
                    setShowProgressBar,
                    setProgress,
                    setPolygonsHarvestPlanning
                });
                return;
            }
        } catch (error) {
            console.error("Error en execBash:", error);
        }
    };

    const ejecutarProcesoSinArchivo = async () => {
        try {
            const idUsuario = userData.userId;

            if (idAnalisisBash === APLICACIONES_AEREAS) {
                await ejecutarProcesoSinArchivoAps({
                    idMax, idUsuario, setProcessingFinished
                });
            } else if (idAnalisisBash === COSECHA_MECANICA) {
                await ejecutarProcesoSinArchivoCosechaMecanica({
                    idMax, idUsuario, setProcessingFinished
                });
            } else if (idAnalisisBash === HERBICIDAS) {
                await ejecutarProcesoSinArchivoHerbicidas({
                    idMax, idUsuario, setProcessingFinished
                });
            } else if (idAnalisisBash === FERTILIZACION) {
                await ejecutarProcesoSinArchivoFertilizacion({
                    idMax, userData, setProcessingFinished
                });
            } else if (idAnalisisBash === CONTEO_PALMA) {
                const nombre = nombreAnalisis(idAnalisisBash);
                const userId = userData.userId;
                await ejecutarProcesoConteoPalmas({
                    selectedZipFile,
                    setProcessingFinished,
                    setImageUrl,
                    setNorthWestCoords,
                    setSouthEastCoords,
                    setConteoObjetos,
                    socket,
                    socketSessionID,
                    setProgress,
                    setTitleLoader,
                    setShowProgressBar,
                    selectedAnalysisTypeRef,
                    idAnalisisBash,
                    nombre,
                    userId,
                    calidad
                });
            } else if (idAnalisisBash === CONTEO_AGAVE) {
                const nombre = nombreAnalisis(idAnalisisBash);
                const userId = userData.userId;
                await ejecutarProcesoConteoAgaves({
                    selectedZipFile,
                    setProcessingFinished,
                    setImageUrl,
                    setNorthWestCoords,
                    setSouthEastCoords,
                    setConteoObjetos,
                    socket,
                    socketSessionID,
                    setProgress,
                    setTitleLoader,
                    setShowProgressBar,
                    selectedAnalysisTypeRef,
                    idAnalisisBash,
                    nombre,
                    userId,
                    calidad,
                    setCostosPlantacion,
                    setPrecioPlantacion,
                    setUtilidadNeta
                });
            }
        } catch (error) {
            console.error("Error en ejecutarProcesoSinArchivo:", error);
        }
    };

    const handleAnalysisTypeChange = (event) => {
        try {
            setSelectedAnalysisType(event.target.value);
        } catch (error) {
            console.error("Error en handleAnalysisTypeChange:", error);
        }
    };

    const esValorValido = (valor) => {
        return valor !== '' && valor !== 0 && valor !== null && valor !== undefined;
    };

    const toggleEdicionInteractiva = () => {
        try {
            setActivarEdicionInteractiva(prev => !prev);
        } catch (error) {
            console.error("Error en toggleEdicionInteractiva:", error);
        }
    };

    const onHoverLote = (loteId) => {
        try {
            setHighlightedLote(loteId);
        } catch (error) {
            console.error("Error en onHoverLote:", error);
        }
    };

    const onLeaveLote = () => {
        try {
            setHighlightedLote(null);
        } catch (error) {
            console.error("Error en onLeaveLote:", error);
        }
    };

    const onSelectLote = (loteId) => {
        try {
            setActiveLotes((prevActiveLotes) =>
                prevActiveLotes.includes(loteId)
                    ? []
                    : [loteId]
            );
        } catch (error) {
            console.error("Error en onSelectLote:", error);
        }
    };

    const clearAllLotes = () => {
        try {
            setActiveLotes([]);
        } catch (error) {
            console.error("Error en clearAllLotes:", error);
        }
    };

    useEffect(() => {
        if (polygonsData.length > 0) {
            setLoadingPolygons(false);
        }
    }, [polygonsData]);

    const closeFilterDialog = () => {
        setIsFilterDialogOpen(false);
    };

    useEffect(() => {
        if (tramplingLines.url) {
            setPreviousTramplingLines(tramplingLines);
        }
    }, [tramplingLines]);

    const handleToggleMostrarLineas = () => {
        setMostrarLineas(prevState => {
            const newState = !prevState;
            if (newState) {
                if (previousTramplingLines && previousTramplingLines.url) {
                    setTramplingLines(previousTramplingLines);
                } else {
                    execBash(true);
                }
            } else {
                setTramplingLines([]);
            }
            return newState;
        });
    };

    return (
        <DashboardContainer>
            <Tutorial key={tutorialKey} isActive={runTutorial} onClose={() => setRunTutorial(false)} />
            <ProgressBar
                progress={progress}
                message={progressMessage}
                show={showProgressBar}
                title={titleLoader}
            />
            <ErrorListDialog
                open={openErrorDialog}
                onClose={handleCloseErrorDialog}
                errors={errors}
                title="Errores encontrados en el archivo"
                description="Se han detectado los siguientes errores durante el procesamiento del archivo. Por favor, corrígelos e inténtalo de nuevo."
            />
            <MainContent isSidebarOpen={isSidebarOpen}>
                <div className="dashboard-main">
                    <DashboardControls>
                        <LoadingBar
                            progress={loadingProgress}
                            show={!processingFinished}
                        />
                        <ToolbarComponent
                            selectedAnalysisType={selectedAnalysisType}
                            handleAnalysisTypeChange={handleAnalysisTypeChange}
                            manejarSubidaArchivo={handleFileUpload}
                            uploadedCsvFileName={uploadedCsvFileName}
                            uploadedZipFileName={uploadedZipFileName}
                            execBash={execBash}
                            activarEdicionInteractiva={activarEdicionInteractiva}
                            setActivarEdicionInteractiva={setActivarEdicionInteractiva}
                            isKMLFile={isKMLFile}
                            execBashEnabled={execBashEnabled}
                            isSidebarOpen={isSidebarOpen}
                            polygonsData={polygonsData}
                            highlightedLote={highlightedLote}
                            activeLotes={activeLotes}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            onHoverLote={onHoverLote}
                            onLeaveLote={onLeaveLote}
                            onSelectLote={onSelectLote}
                            clearAllLotes={clearAllLotes}
                            openFilterDialog={() => setIsFilterDialogOpen(true)}
                            processingFinished={processingFinished}
                            handleSendDashboardData={handleSendDashboardData}
                            analysisOptions={analysisOptions}
                            setSelectedZipFile={setSelectedZipFile}
                            setUploadedZipFileName={setUploadedZipFileName}
                            setOpenSnackbar={setOpenSnackbar}
                            setIsKMLFile={setIsKMLFile}
                            mapRef={mapRef}
                            setImgLaflet={setImgLaflet}
                            isGeneratingReport={isGeneratingReport}
                            setIsGeneratingReport={setIsGeneratingReport}
                            mostrarLineas={mostrarLineas}
                            handleToggleMostrarLineas={handleToggleMostrarLineas}
                            setCalidad={setCalidad}
                            calidad={calidad}
                            disableAnalysisSelection={disableAnalysisSelection}
                            forceEnableUploadTemplate={forceEnableUploadTemplate}
                            selectedSidebarOption={selectedSidebarOption}
                        />

                        <FilterToolbar
                            isSidebarOpen={isSidebarOpen}
                            isDashboardIndicators={false}
                            filterOptions={filterOptions}
                            enabledFilters={enabledFilters}
                            operadorOptions={operadorOptionsCM}
                            zafrasOptions={zafraOptionsCM}
                            loteOptions={loteOptionsCM}
                            fincasOptions={fincasOptionsCM}
                            tercioOptions={tercioOptionsCM}
                            productosOptionsAA={productoOptionsAA}
                            handleDateChange={handleDateChange}
                            onFilterClick={handleSendDashboardDataFilters}
                            handleCutLine={handleCutLine}
                            handleDrawLine={handleDrawLine}
                            handleDeleteLine={handleDeleteLine}
                            handleToggleBuffer={handleToggleBuffer}
                            handleUndo={handleUndo}
                            activeTool={activeTool}
                            isBufferActive={isBufferActive}
                            bufferValue={bufferValue}
                            setBufferValue={setBufferValue}
                            showUnfilteredLines={showUnfilteredLines}
                            toggleUnfilteredLines={toggleUnfilteredLines}
                            handleStretchLine={handleStretchLine}
                            selectedAnalysisType={selectedAnalysisType}
                        />
                    </DashboardControls>
                    <MapSectionContainer>
                        {loadingPolygons ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <MapSection
                                selectedFiles={selectedFiles}
                                selectedFile={selectedFile}
                                selectedAnalysisType={selectedAnalysisType}
                                datosMapeo={datosMapeo}
                                selectedZipFile={selectedZipFile}
                                processingFinished={processingFinished}
                                ultimoAnalisis={ultimoAnalisis}
                                nombreAnalisis={nombreAnalisis}
                                idAnalisisBash={idAnalisisBash}
                                activarEdicionInteractiva={activarEdicionInteractiva}
                                setAreaNetaCm={setAreaNetaCm}
                                setAreaBrutaCm={setAreaBrutaCm}
                                setDiferenciaDeAreaCm={setDiferenciaDeAreaCm}
                                setDiferenciaDeAreaApsKml={setDiferenciaDeAreaApsKml}
                                setPorcentajeAreaPilotoCm={setPorcentajeAreaPilotoCm}
                                setPorcentajeAreaAutoTrackerCm={setPorcentajeAreaAutoTrackerCm}
                                setPorcentajeModoCortadorBaseCm={setPorcentajeModoCortadorBaseCm}
                                setEficienciaCm={setEficienciaCm}
                                setAreaSobreAplicada={setAreaSobreAplicada}
                                setAreaTotalLote={setAreaTotalLote}
                                setAreaSobreCalles={setAreaSobreCalles}
                                setAreaFueraDeLote={setAreaFueraDeLote}
                                setDosisTotalRealAplicada={setDosisTotalRealAplicada}
                                setAreaAplicada={setAreaAplicada}
                                setPorcentajeVariacion={setPorcentajeVariacion}
                                setAreaNoAplicada={setAreaNoAplicada}
                                setPromedioVelocidad={setPromedioVelocidad}
                                setPromedioAltura={setPromedioAltura}
                                polygonsData={polygonsData}
                                highlightedLote={highlightedLote}
                                activeLotes={activeLotes}
                                onHoverLote={onHoverLote}
                                onLeaveLote={onLeaveLote}
                                onSelectLote={onSelectLote}
                                closeFilterDialog={closeFilterDialog}
                                isFilterDialogOpen={isFilterDialogOpen}
                                setImgLaflet={setImgLaflet}
                                imageUrl={imageUrl}
                                northWestCoords={northWestCoords}
                                southEastCoords={southEastCoords}
                                setNorthWestCoords={setNorthWestCoords}
                                setSouthEastCoords={setSouthEastCoords}
                                setCoordinateSystem={setCoordinateSystem}
                                isAnalysisPerformed={isAnalysisPerformed}
                                mapRef={mapRef}
                                polygonsLayerRef={polygonsLayerRef}
                                setLabelsAndColors={setLabelsAndColors}
                                setOnAreasCalculatedLoader={setOnAreasCalculatedLoader}
                                setTipoMapeo={setTipoMapeo}
                                activeTool={activeTool}
                                isBufferActive={isBufferActive}
                                bufferValue={bufferValue}
                                showUnfilteredLines={showUnfilteredLines}
                                setActiveTool={setActiveTool}
                                handleToggleMostrarLineas={handleToggleMostrarLineas}
                                mostrarLineas={mostrarLineas}
                                tramplingLines={tramplingLines}
                                setHarvestLinesBuffer={setHarvestLinesBuffer}
                                polygonsHarvestPlanning={polygonsHarvestPlanning}
                            />
                        )}
                    </MapSectionContainer>
                    <AnalysisSection ref={dashboardRef}>
                        <DataSection
                            ref={dataSectionRef}
                            selectedAnalysisType={selectedAnalysisType}
                            promedioAltura={promedioAltura}
                            areaSobreAplicada={areaSobreAplicada}
                            areaTotalLote={areaTotalLote}
                            areaAplicada={areaAplicada}
                            areaTotal
                            areaNoAplicada={areaNoAplicada}
                            diferenciaDeAreaApsKml={diferenciaDeAreaApsKml}
                            areaSobreCalles={areaSobreCalles}
                            areaFueraDeLote={areaFueraDeLote}
                            porcentajeVariacion={porcentajeVariacion}
                            promedioVelocidad={promedioVelocidad}
                            areaBrutaCm={areaBrutaCm}
                            eficienciaCm={eficienciaCm}
                            promedioVelocidadCm={promedioVelocidadCm}
                            porcentajeAreaPilotoCm={porcentajeAreaPilotoCm}
                            porcentajeAreaAutoTrackerCm={porcentajeAreaAutoTrackerCm}
                            porcentajeModoCortadorBaseCm={porcentajeModoCortadorBaseCm}
                            esValorValido={esValorValido}
                            setIndicadores={setIndicadores}
                            datosAnalisis={datosAnalisis}
                            conteoObjetos={conteoObjetos}
                            onAreasCalculatedLoader={onAreasCalculatedLoader}
                            dosisTotalRealAplicada={dosisTotalRealAplicada}
                            selectedSidebarOption={selectedSidebarOption}
                            polygonsHarvestPlanning={polygonsHarvestPlanning}
                        />
                    </AnalysisSection>
                </div>
            </MainContent>
        </DashboardContainer>
    );
};

export default Dashboard;
