// ./views/Advertising/Advertising.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Card, CardActionArea, CardContent } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import GrassIcon from '@mui/icons-material/Grass';
import LayersIcon from '@mui/icons-material/Layers';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { motion } from 'framer-motion';
import { useAuth } from '../../context/AuthContext';

const Advertising = () => {
    const navigate = useNavigate();
    const [clickedCard, setClickedCard] = useState(null);

    // Para poder cerrar sesión
    const { logout } = useAuth();

    // Opciones equivalentes a las del sidebar
    const menuOptions = [
        {
            label: 'Dashboard',
            icon: <BarChartIcon fontSize="large" />,
            route: '/dashboard',
        },
        {
            label: 'Mapeo Maquinaria',
            icon: <AgricultureIcon fontSize="large" />,
            route: '/mapeo?tipo=mapeo_maquinaria',
        },
        {
            label: 'Conteo Planta',
            icon: <GrassIcon fontSize="large" />,
            route: '/mapeo?tipo=conteo_plantas',
        },
        {
            label: 'SIG Agrícola',
            icon: <LayersIcon fontSize="large" />,
            route: '/mapeo?open=SIGAgricola',
        },
        {
            label: 'Historial',
            icon: <HistoryIcon fontSize="large" />,
            route: '/mapeo?open=historial',
        },
        {
            label: 'Configuración',
            icon: <SettingsIcon fontSize="large" />,
            route: '/configuracion',
        },
        {
            label: 'Cerrar Sesión',
            icon: <ExitToAppIcon fontSize="large" />,
            route: '/logout',
        },
    ];

    const handleOptionClick = (option) => {
        setClickedCard(option.label);
        // Pausa de 600 ms para la animación
        setTimeout(() => {
            if (option.label === 'Cerrar Sesión') {
                // Cerrar sesión
                logout();
                navigate('/login');
                return;
            }
            navigate(option.route);
        }, 600);
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100vh', // Ocupa toda la altura del viewport
                margin: 0,
                padding: 0,
                position: 'relative',
                overflow: 'hidden',
                backgroundImage: 'url("/background-menu.jpeg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
        >
            {/* Overlay semi-transparente */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    zIndex: 1,
                }}
            />

            {/* Logo centrado, posicionado más arriba */}
            <Box
                sx={{
                    position: 'fixed',
                    top: { xs: '5vh', md: '8vh' }, // Se mueve más arriba
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 3, // Mayor z-index para que se muestre por encima del menú
                    textAlign: 'center',
                }}
            >
                <img src="/logo-negro.png" alt="Logo" style={{ maxWidth: '300px', width: '100%' }} />
            </Box>

            {/* Contenedor principal (grid debajo del logo) */}
            <Box
                sx={{
                    position: 'absolute',
                    top: { xs: 'calc(15vh + 150px)', md: 'calc(20vh + 150px)' },
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    zIndex: 2,
                }}
            >
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ maxWidth: 900, px: 2 }}
                >
                    {menuOptions.map((option, idx) => (
                        <Grid item xs={6} sm={4} md={3} key={option.label}>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: idx * 0.1 }}
                            >
                                <Card
                                    sx={{
                                        borderRadius: 4,
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        backdropFilter: 'blur(4px)',
                                        cursor: 'pointer',
                                        boxShadow: clickedCard === option.label
                                            ? '0 0 20px rgba(0,0,0,0.3)'
                                            : '0 2px 10px rgba(0,0,0,0.2)',
                                        transition: 'background-color 0.3s ease',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        },
                                    }}
                                >
                                    <CardActionArea onClick={() => handleOptionClick(option)}>
                                        <CardContent
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                p: 2,
                                            }}
                                        >
                                            {option.icon}
                                            <Box
                                                component="span"
                                                sx={{ mt: 1 }}
                                            >
                                                {option.label}
                                            </Box>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default Advertising;
