// BarIndicatorStyle.js
import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const BarIndicatorContainer = styled(Box)({
    position: 'absolute',
    top: '50%',
    right: '0',
    transform: 'translateY(-50%)',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
});

export const BarIndicatorWrapper = styled(Box)(({ isOpen }) => ({
    width: isOpen ? '220px' : '0',
    background: '#ffffff',
    padding: isOpen ? '20px' : '0',
    borderRadius: '12px 0 0 12px',
    boxShadow: isOpen ? '0 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    transition:
        'width 0.3s cubic-bezier(0.4, 0, 0.2, 1), padding 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
}));

export const Tab = styled(Box)(({ isOpen }) => ({
    width: '40px',
    height: '80px',
    background: '#ffffff',
    borderRadius: '0 8px 8px 0',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    zIndex: 1001,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
}));

export const BarTitle = styled(Box)({
    width: '100%',
    fontSize: '16px',
    marginBottom: '20px',
    color: '#333',
    fontWeight: 600,
    textAlign: 'center',
});

export const LabelContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: '300px', // Altura definida para evitar desbordamiento
    overflowY: 'auto',   // Habilita scroll vertical si hay muchos labels
    paddingRight: '8px', // Espacio para la barra de scroll
});

export const LabelItem = styled(Box)({
    fontSize: '14px',
    color: '#fff',
    fontWeight: 500,
    padding: '10px',
    borderRadius: '8px',
    marginBottom: '10px',
    textAlign: 'center',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    cursor: 'pointer',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
    '&:last-child': {
        marginBottom: 0,
    },
    '&:hover': {
        opacity: 0.9,
        transform: 'scale(1.02)',
    },
});
